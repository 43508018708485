import React from "react"
import { Typography } from "@material-ui/core"
import { useRecordContext } from "react-admin"


export const DateFieldIntl = ({ source, ...props }) => {

    const record = useRecordContext(props)
    const formatter = new Intl.DateTimeFormat(
        'pt-BR',
        {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Sao_Paulo',
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        }
    )
    return <Typography> {
        record[source] ? formatter.format(new Date(record[source])) : ''
    } </Typography>
}