import {
    Timeline
} from '@material-ui/lab'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import { ProcessActivityPanel } from './process-activity-panel'


export const ProcessActivities = ({ process, isStudent, ...props }) => {

    const activities = process.activities.sort((a, b) => moment(b.deadline) >= moment(a.deadline) ? 1 : -1)

    return (
        <Timeline align='left' style={{ flexWrap: 'nowrap' }}>
            {
                activities.map(
                    (activity) => <ProcessActivityPanel key={activity.id} process={process} activity={activity} isStudent={isStudent} />
                )
            }
        </Timeline>
    )
}