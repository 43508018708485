
import {
    Grid
} from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { useContext, useEffect, useState } from 'react'
import { Loading, Title } from 'react-admin'
import dataProvider from '../../providers/data-provider'
import { storageProvider } from '../../providers/storage-provider'
import { SchoolActivityAttachments } from './school-activity-attachments'
import { SchoolActivityInfo } from './school-activity-info'
import { SchoolActivityParties } from './school-activity-parties'
import { SchoolActivityTasks } from './school-activity-tasks'
import { SchoolActivityTeams } from './school-activity-teams'
import { SchoolActivityStepper } from './school-activity-stepper'
import { AppContext } from '../../providers/app-context-provider/app.context'
import { SchoolTeamAttachments } from './school-activity-team-attachments'

moment.locale('pt-br');

const SchoolActivityDashboard = (props) => {

    const id = props.match.params.id;

    const user = storageProvider.getCurrentUser()
    const profiles = user?.profiles
    const isStudent = profiles[0]?.name === 'Aluno'
    const context = useContext(AppContext)

    const [school_activity, setSchoolActivity] = useState(null)
    const [refresh, setRefresh] = useState(true)
    const [attachments, setAttachments] = useState(null)
    const [team, setTeam] = useState(null)

    const toggleRefresh = () => setRefresh(!refresh)

    useEffect(() => {
        (async () => {
            await dataProvider.get('school-activities', isStudent ? `${id}/dashboard/${context.currentUser.id}` : `${id}/dashboard`)
                .then(res => {
                    setSchoolActivity(res.data)
                    setAttachments(res.data.attachments)
                    if (isStudent) setTeam(res.data.teams[0])
                })
        })()
    }, [refresh, id, isStudent, context.currentUser])


    return school_activity === null ? <Loading /> : (
        <Grid container>
            <Title title={'Atividade prática'} />
            {!isStudent && (
                <Grid container item key={'controls'} direction="row">
                    <Grid container item spacing={1} xs={12} direction='row' justifyContent='flex-start' key={'left-column'}>
                        <SchoolActivityStepper school_activity={school_activity} setSchoolActivity={setSchoolActivity} refresh={refresh} toggleRefresh={toggleRefresh} />
                    </Grid>
                    <Grid container item spacing={1} xs={12} direction='row' justifyContent='flex-start' key={'left-column'} style={{ marginTop: '16px' }}>
                        <SchoolActivityTeams school_activity={school_activity} teams={school_activity.teams} />
                    </Grid>
                </Grid>
            )}

            <Grid container item spacing={1} direction="row" key={'external'}>
                <Grid container item spacing={1} xs={5} lg={5} direction='column' key={'left-column'}>
                    <Grid item key={'school-activity-info'} style={{ marginTop: '28px' }}>
                        <SchoolActivityInfo school_activity={school_activity} isStudent={isStudent} student={user} team={team} />
                    </Grid>
                    <Grid item key={'school-activity-attachments'}>
                        <SchoolActivityAttachments id={school_activity.id} attachments={attachments} setAttachments={setAttachments} isStudent={isStudent} />
                    </Grid>
                    <Grid item key={'school-activity-parties'}>
                        {!isStudent && <SchoolActivityParties school_activity={school_activity} refresh={toggleRefresh} isStudent={isStudent} />}
                    </Grid>
                    <Grid>
                        {isStudent && team && <SchoolTeamAttachments team={team} />}
                    </Grid>
                </Grid>
                <Grid container item spacing={1} xs={7} lg={7} direction='column' key={'right-column'}>
                    <Grid item key={'school-activity-tasks'}>
                        <SchoolActivityTasks school_activity={school_activity} isStudent={isStudent} team={team} 
                            refresh={refresh} setSchoolActivity={setSchoolActivity} toggleRefresh={toggleRefresh} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SchoolActivityDashboard;
