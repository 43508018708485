import { IconButton, Tooltip } from "@material-ui/core";
import { Videocam } from "@material-ui/icons";
import React from "react";

const LinkToVideoConference = ({ roomId, roomPassword, ...props }) => {
    return (
        <Tooltip title={'Videoconferência'}>
            <IconButton onClick={() => { window.open(`/#/video/${roomId}`, '_blank') }}>
                <Videocam />
            </IconButton>
        </Tooltip>
    )
}

export default LinkToVideoConference;