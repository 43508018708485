import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";
import { storageProvider } from '../../providers/storage-provider';

const AcademicPeriodList = (props) => {

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin

    return (
        <List exporter={false} bulkActionButtons={false}
            sort={{ field: 'start_at', order: 'DESC' }}
            title={'Períodos letivos'}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label={'Nome'} />
                {superAdmin && <TextField source="institution.name" label={'Instituição'} />}
                <DateField source="start_at" label={'Data de início'} />
                <DateField source="end_at" label={'Data de término'} />
            </Datagrid>
        </List>
    )
}

export default AcademicPeriodList;