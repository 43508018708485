import { AssignmentInd } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import SchoolActivityCreate from "./school-activities-create";
import SchoolActivityEdit from "./school-activities-edit";
import SchoolActivityList from "./school-activities-list";

export const schoolActivitiesResource = {
    name: ApplicationResource.SCHOOL_ACTIVITIES,
    icon: AssignmentInd,
    options: { label: "Atividades práticas" },
    tableName: 'school-activities',
    list: SchoolActivityList,
    edit: SchoolActivityEdit,
    create: SchoolActivityCreate,
    link: '/school-activities',
    products: ['school']
};