import { Attachment } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import AttachmentCreate from "./attachment-create";

export const attachmentResource = {
    name: ApplicationResource.ATTACHMENTS,
    icon: Attachment,
    options: { label: "Anexos" },
    tableName: 'attachment',
    create: AttachmentCreate,
    products:['school', 'office']
};