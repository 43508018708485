import { Button, Divider, Grid, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { PDFViewer } from "@react-pdf/renderer"
import React, { useContext, useState } from "react"
import { Title } from "react-admin"
import dataProvider from "../../providers/data-provider"
import { PDFReport } from "./pdf-report"
import { useStyles } from "./report-page.styles"
import { AppContext } from "../../providers/app-context-provider/app.context"

export const Reports = () => {

    const context = useContext(AppContext)

    const product = context.product;

    const styles = useStyles()

    const [processes, setProcesses] = useState([])
    const [students, setStudents] = useState([])
    const [classes, setClasses] = useState([])
    const [academic_periods, setAcademicPeriods] = useState([])
    const [loading, setLoading] = useState(false)
    const [renderReport, setRenderReport] = useState(false)
    const [reportType, setReportType] = useState('')
    const [selected, setSelected] = useState(undefined)
    const [data, setData] = useState(null)

    const fetchProcesses = () => {
        setLoading(true)
        dataProvider.getList('processes', { sort: { field: 'alias', order: 'DESC' }, pagination: { page: 1, perPage: 5000 } })
            .then((res) => {
                setProcesses(res.data)
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
            })
    }

    const fetchStudents = () => {
        setLoading(true)
        dataProvider.getStudentsWithActiveClass()
            .then(res => {
                setStudents(res.data)
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
            })
    }

    const fetchClasses = () => {
        setLoading(true)
        dataProvider.getClasses()
            .then(res => {
                setClasses(res.data.data)
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
            })
    }

    const fetchAcademicPeriods = () => {
        setLoading(true)
        dataProvider.get('academic-periods')
            .then(res => {
                setAcademicPeriods(res.data.data)
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
            })
    }

    const handleSelectorChange = (event, value) => { setSelected(value); setRenderReport(false) }
    const handleFieldChange = (event) => { setSelected(event.target.value); setRenderReport(false) }


    const ProcessSelector = () =>
        <Autocomplete
            id='combobox-select'
            options={loading ? [] : processes}
            getOptionLabel={process => process.alias || 'Selecione...'}
            noOptionsText={'Sem resultados'}
            value={selected}
            size={'small'}
            onChange={handleSelectorChange}
            style={{ width: 350 }}
            renderInput={(params) => <TextField {...params} label={'Atendimento'} variant='filled' />}
        />

    const StudentSelector = () =>
        <Autocomplete
            id='combobox-select'
            options={loading ? [] : students}
            getOptionLabel={student => student.name || 'Selecione...'}
            noOptionsText={'Sem resultados'}
            value={selected}
            size={'small'}
            onChange={handleSelectorChange}
            style={{ width: 350 }}
            renderInput={(params) => <TextField {...params} label={'Aluno'} variant='filled' />}
        />

    const ClassSelector = () =>
        <Autocomplete
            id='combobox-select'
            options={loading ? [] : classes}
            getOptionLabel={c => c ? `${c?.name} (${c?.academic_period?.name})` : 'Selecione...'}
            noOptionsText={'Sem resultados'}
            value={selected}
            size={'small'}
            onChange={handleSelectorChange}
            style={{ width: 350 }}
            renderInput={(params) => <TextField {...params} label={'Turma'} variant='filled' />}
        />

    const AcademicPeriodSelector = () =>
        <Autocomplete
            id='combobox-select'
            options={loading ? [] : academic_periods}
            getOptionLabel={c => c.name || 'Selecione...'}
            noOptionsText={'Sem resultados'}
            value={selected}
            size={'small'}
            onChange={handleSelectorChange}
            style={{ width: 350 }}
            renderInput={(params) => <TextField {...params} label={'Período letivo'} variant='filled' />}
        />

    const DateSelector = () => (
        <TextField
            type="date"
            defaultValue={new Date().toDateString('dd/MM/yyyy')}
            value={selected}
            label={'Data de Referência'}
            size="small"
            id="date-selector"
            onChange={handleFieldChange}
            variant="filled"
            InputProps={{ onKeyDown: (e) => e.preventDefault() }}
            InputLabelProps={{ shrink: true }}
        />
    )

    const officeOptions = [
        {
            id: 'analytic-academic-report',
            label: 'Acadêmico',
            params: true,
            type: 'Analíticos',
            dataFn: fetchProcesses
        },
        {
            id: 'analytic-manager-report',
            label: 'Gerencial',
            params: true,
            type: 'Analíticos',
            dataFn: fetchProcesses
        },
        {
            id: 'process-without-manager',
            label: 'Atendimentos sem gestor',
            params: false,
            type: 'Auditoria'
        },
        {
            id: 'process-without-teacher',
            label: 'Atendimentos sem professor',
            params: false,
            type: 'Auditoria'
        },
        {
            id: 'activities-without-grade',
            label: 'Movimentações não corrigidas',
            params: false,
            type: 'Auditoria'
        },
        {
            id: 'individual-grades-report',
            label: 'Notas (individual)',
            params: true,
            type: 'Notas',
            dataFn: fetchStudents
        },
        {
            id: 'class-grades-report',
            label: 'Notas por turma',
            params: true,
            type: 'Notas',
            dataFn: fetchClasses
        },
        {
            id: 'scheduling-report',
            label: 'Agendamentos por data',
            params: true,
            type: 'Agendamentos',
        },
        {
            id: 'scheduling-class-report',
            label: 'Agendamentos por turma',
            params: true,
            type: 'Agendamentos',
            dataFn: fetchClasses
        },
        {
            id: 'scheduling-audit-report',
            label: 'Auditoria de agendamentos',
            params: true,
            type: 'Agendamentos',
            dataFn: fetchAcademicPeriods
        }
    ]

    const schoolOptions = [
        {
            id: 'school-class-grades-report',
            label: 'Notas por turma',
            params: true,
            type: 'Notas',
            dataFn: fetchClasses
        },
    ]

    const handleReportChange = (event, value) => {
        setReportType(value);
        setRenderReport(false);
        setSelected(undefined)

        if (value?.dataFn) { value.dataFn() }
    }
    const renderParamSelector = (id) => {
        if (id === 'analytic-academic-report' || id === 'analytic-manager-report') {
            return <ProcessSelector />
        } else if (id === 'individual-grades-report') {
            return <StudentSelector />
        } else if (id === 'class-grades-report' || id === 'scheduling-class-report' || id === 'school-class-grades-report') {
            return <ClassSelector />
        } else if (id === 'scheduling-report') {
            return <DateSelector />
        } else if (id === 'scheduling-audit-report') {
            return <AcademicPeriodSelector />
        }
    }

    const fetchReportData = async (type, selected) => {
        if (type.id === 'academic-report'
            || type.id === 'manager-report'
            || type.id === 'analytic-manager-report'
            || type.id === 'analytic-academic-report') {
            await dataProvider.getAnalyticReport('processes', { id: selected.id })
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })
        } else if (type.id === 'process-without-manager') {
            await dataProvider.getProcessesWithoutManager()
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })
        } else if (type.id === 'process-without-teacher') {
            await dataProvider.getProcessesWithoutTeacher()
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })
        } else if (type.id === 'activities-without-grade') {
            await dataProvider.getActivitiesWithoutGrade()
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })
        } else if (type.id === 'individual-grades-report') {
            await dataProvider.getUserActivityDashboard(selected.id)
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })
        } else if (type.id === 'class-grades-report') {
            await dataProvider.getClassGradeReport(selected.id)
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })
        } else if (type.id === 'scheduling-report') {
            await dataProvider.get('schedulings', `report/date/${selected}`)
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })
        } else if (type.id === 'scheduling-class-report') {
            await dataProvider.get('schedulings', `report/class/${selected.id}`)
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })
        } else if (type.id === 'scheduling-audit-report') {
            await dataProvider.get('schedulings', `report/audit/${selected.id}`)
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })
        } else if (type.id === 'school-class-grades-report') {
            await dataProvider.get('classes', `school-grade-report/${selected.id}`)
                .then(res => {
                    setData(res.data)
                    setRenderReport(true)
                })

        } else {
            setData({})
            setRenderReport(false)
        }
    }
    return (
        <>
            <Title title={'Relatórios'} />
            <Grid container className={styles.testContainer}>
                <Grid className={styles.testItem} item>
                    <Autocomplete
                        id='combobox-select'
                        options={product.id === 1 ? schoolOptions : officeOptions}
                        getOptionLabel={option => option.label || 'Selecione...'}
                        style={{ width: 350 }}
                        noOptionsText={'Sem resultados'}
                        groupBy={option => option.type}
                        size={'small'}
                        value={reportType}
                        onChange={handleReportChange}
                        renderInput={(params) => <TextField {...params} label={'Tipo de relatório'} variant='filled' />}
                    />
                </Grid>
                {
                    reportType?.params && <Grid className={styles.testItem} item>
                        {
                            renderParamSelector(reportType.id)
                        }
                    </Grid>
                }
                <span style={{ flexGrow: 1 }} />
                <Grid className={styles.testButton} item>
                    <Button variant="contained" color="primary" disabled={!reportType || (reportType.params && !selected)} onClick={() => fetchReportData(reportType, selected)}>
                        Emitir Relatório
                    </Button>
                </Grid>
            </Grid>
            <Divider style={{ marginTop: '8px' }} />
            <Grid container className={styles.testContainer}>
                <Grid style={{ width: '100%', height: '430px' }} item>
                    {renderReport &&
                        <PDFViewer width={'100%'} height={'100%'}>
                            {
                                <PDFReport type={reportType} data={data} params={selected} />
                            }
                        </PDFViewer>
                    }
                </Grid>
            </Grid>
        </>
    )
}