import {
    Timeline
} from '@material-ui/lab'
import 'moment/locale/pt-br'
import React from 'react'
import { ModelActivityTaskPanel } from './model-activity-task-panel'
import { NewTaskSkeletonCard } from '../../components/new-task-card'


export const ModelActivityTasks = ({ model_activity, isStudent, ...props }) => {

    const tasks = model_activity?.tasks

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Timeline align='left'>
                {
                    tasks?.sort((a, b) => a.order - b.order).map(
                        (task) => <ModelActivityTaskPanel key={task.id} model_activity={model_activity} task={task} isStudent={isStudent} />
                    )
                }
                <NewTaskSkeletonCard record={model_activity} resource={'school-model-activity-tasks'} redirectTo={`/model-activity-dashboard/${model_activity.id}`} />
            </Timeline>
        </div>
    )
}