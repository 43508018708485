import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@material-ui/core";
import { AccountCircle, GroupAdd } from "@material-ui/icons";
import React from "react";
import { useRedirect } from "react-admin";
import ExpandableCard from "../../components/expandable-card";

/*
const AddPartyTypeDialog = ({ school_activity, open, handleClose, toggleRefresh }) => {

    const [partyTypes, setPartyTypes] = useState([]);
    const [selected, setSelected] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const response = await dataProvider.getList(
                'party-types',
                {
                    pagination: { page: 1, perPage: 999 },
                    sort: { field: 'name', order: 'ASC' },
                }
            )
            setPartyTypes(response.data)
            setLoading(false)
        }
        getData();
    }, [1]);

    const handleInsert = () => {
        dataProvider.create(`school-activities/${school_activity.id}/partyType`, { data: { id: selected.id } })
            .finally(() => { toggleRefresh(); })
        handleClose();
    };


    return <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle>Adicionar partes</DialogTitle>
        <Divider />
        <DialogContent style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Autocomplete
                //multiple
                id="party_types"
                loading={loading}
                options={partyTypes}
                size={"small"}
                value={selected}
                filterSelectedOptions
                inputValue={inputValue}
                onInputChange={(event, value) => setInputValue(value)}
                onChange={(event, value) => { setSelected(value) }}
                getOptionLabel={(option) => option.name}
                noOptionsText={'Sem resultados'}
                renderInput={(params) => (
                    <TextField {...params} label="Partes" />
                )}
            />
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={handleClose} variant={"contained"} color={'textPrimary'}>Cancelar</Button>
            <span style={{ flexGrow: 1 }} />
            <Button onClick={handleInsert} variant={"contained"} color={'primary'}>Confirmar</Button>
        </DialogActions>
    </Dialog>

}
*/

export const SchoolActivityParties = ({ school_activity, refresh }) => {


    const redirect = useRedirect();
    const id = school_activity.id

    return (
        <>
            <ExpandableCard
                realce={true}
                title={`Sujeitos processuais`}
                action={
                    <>
                        <Tooltip title={'Adicionar parte'}>
                            <IconButton onClick={() => redirect(`/school-activities/${id}/parties`, undefined, id, undefined, { tab: 'parties', redirectTo: `/school-activity-dashboard/${id}` })}>
                                <GroupAdd />
                            </IconButton>
                        </Tooltip>
                    </>
                }
                mini={school_activity?.party_types?.length < 1}
            >
                <List component="nav" dense={true}>
                    {school_activity.party_types.map((party) => {
                        return (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AccountCircle />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText> {party.name} </ListItemText>
                            </ListItem >
                        )
                    })}
                </List >
            </ExpandableCard>
        </>
    )
}