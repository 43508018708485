import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    makeStyles
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ListIcon from "@material-ui/icons/List";
import React, { useState } from "react";
import { DangerousHTMLDiv } from "../../components/dangerousHTMLDiv";

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const ModelActivityInfoToolbar = ({ purpose, preRequisites }) => {
    const classes = useStyles();
    const [openPurpose, setOpenPurpose] = useState(false);
    const [openPre, setOpenPre] = useState(false);

    const handleClickOpenPurpose = () => {
        setOpenPurpose(true);
    };

    const handleClosePurpose = () => {
        setOpenPurpose(false);
    };

    const handleClickOpenPre = () => {
        setOpenPre(true);
    };

    const handleClosePre = () => {
        setOpenPre(false);
    };
    return (
        <>
            {preRequisites && (
                <>
                    <Tooltip title="Pré-requisitos do aluno">
                        <IconButton aria-label="list" onClick={handleClickOpenPre}>
                            <ListIcon />
                        </IconButton>
                    </Tooltip>
                    <Dialog onClose={handleClosePre} open={openPre}>
                        <DialogTitle className={classes.title}>
                            Pré-requisitos do aluno
                        </DialogTitle>
                        <DialogContent>
                            <DangerousHTMLDiv html={preRequisites} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClosePre} color="primary" autoFocus>
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
            {purpose && (
                <>
                    <Tooltip title="Proposta de Ensino/Aprendizagem">
                        <IconButton aria-label="info" onClick={handleClickOpenPurpose}>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                    <Dialog onClose={handleClosePurpose} open={openPurpose}>
                        <DialogTitle className={classes.title}>
                            Proposta de Ensino/Aprendizagem
                        </DialogTitle>
                        <DialogContent>
                            <DangerousHTMLDiv html={purpose} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClosePurpose} color="primary" autoFocus>
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default ModelActivityInfoToolbar;
