import React, { useState } from "react";
import { ExternalServiceRequestAgreement } from "./external-service-request-agreement";
import { ESRConfirmationPage } from "./external-service-request-confirmation";
import { ExternalServiceRequestForm } from "./external-service-request-form";

const ExternalServiceRequestPage = (props) => {

    const [step, setStep] = useState(0)
    const [created, setCreated] = useState(undefined)
    const { slug, uuid } = props.match.params
    switch (step) {
        case 0:
        default:
            return <ExternalServiceRequestAgreement slug={slug} uuid={uuid} setStep={setStep} />
        case 1:
            return <ExternalServiceRequestForm slug={slug} uuid={uuid} setStep={setStep} setCreated={setCreated} />
        case 2:
            return <ESRConfirmationPage created={created} />
    }
}


export { ExternalServiceRequestPage };
