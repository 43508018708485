import { AppBar, CssBaseline, Grid, makeStyles, Paper, ThemeProvider, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { ExternalServiceRequestTheme } from "./theme";


export const ESRConfirmationPage = ({ created, ...props }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        instructions: {
            padding: theme.spacing(2),
            textAlign: 'justify',
            color: theme.palette.text.secondary,
        },
        logo: {
            maxHeight: '48px',
            padding: '4px'
        },
        form: {
            marginTop: theme.spacing(1),
            justifyContent: "center",
        }
    }))

    const classes = useStyles();

    return (
        <ThemeProvider theme={ExternalServiceRequestTheme}>
            <CssBaseline />
            {
                < div className={classes.root}>
                    <AppBar position="static">
                        <Toolbar variant="dense">
                            <img alt="Juristta Office" src="https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristtaoffice1.png" className={classes.logo} />
                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={3} className={classes.form} >
                        <Grid item xs={8}>
                            <Paper elevation={3} style={{ marginTop: '5em' }} className={classes.paper}>
                                <Typography> Pedido feito com sucesso! </Typography>
                                <Typography> Seu pedido foi enviado à instituição de ensino com o protocolo <Typography color="primary" variant="h6" display="inline">{created.alias}</Typography>. </Typography>
                                <Typography> Caso tenha qualquer dúvida sobre o atendimento, entre em contato com a instituição e forneça este código. </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>

            }
        </ThemeProvider >
    )

}
