import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Spacer } from "../../components/spacer";
import dataProvider from "../../providers/data-provider";

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));


export const SchoolActivityDateSelector = ({ open, setDialogOpen, tasks, schoolActivity, setSchoolActivity, refresh, toggleRefresh, ...props }) => {

    const [formState, setFormState] = useState(tasks.map(task => ({
        id: task.id,
        name: task.name,
        start_at: task.start_at,
        student_end_at: task.student_end_at,
        teacher_end_at: task.teacher_end_at,
        error: {
            start_at: false, student_end_at: false, teacher_end_at: false
        }
    })))
    const [toggle, setToggle] = useState(true)

    const handleClose = () => { setDialogOpen(false) }
    const classes = useStyles();

    const handleChange = (id, value, field) => {
        const fs = formState
        const idx = fs.findIndex(item => item.id === id)
        fs[idx][field] = value
        fs[idx].error[field] = false
        setFormState(fs)
        setToggle(!toggle)
    }

    const handleSave = () => {
        let valid = true;
        const fs = formState
        for (let task of formState) {
            if (task.start_at === null) { task.error.start_at = true; valid = false }
            if (task.student_end_at === null) { task.error.student_end_at = true; valid = false }
            if (task.teacher_end_at === null) { task.error.teacher_end_at = true; valid = false }
        }
        if (valid) {
            dataProvider.post('school-activities', `${schoolActivity.id}/adjustDates`, { data: formState })
                .then(res => setSchoolActivity({ tasks: res.data.tasks, ...schoolActivity }))
                .then(() => toggleRefresh(!refresh))
                .then(() => handleClose())
        } else {
            setFormState(fs)
            setToggle(!toggle)
        }

    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg'>
            <DialogTitle> Ajustar datas </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>Ajuste as datas das movimentações desta prática com o auxílio desta interface.</DialogContentText>
                <form noValidate autoComplete="off">
                    <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                        {
                            formState.sort((a, b) => a.order - b.order).map((task, index) => (<>
                                <Grid container item style={{ width: '100%', alignItems: 'center' }}>
                                    <Typography style={{ flexGrow: 1 }}>
                                        <Chip style={{ marginRight: '4px' }} label={index + 1} />{`${task.name}`}
                                    </Typography>
                                    <TextField
                                        id={`di-${task.id}`}
                                        label="Data de início"
                                        type="date"
                                        defaultValue={task.start_at}
                                        value={task.start_at}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        required
                                        onChange={(event) => handleChange(task.id, event.target.value, 'start_at')}
                                        error={task.error.start_at}
                                    />
                                    <TextField
                                        id={`dt-${task.id}`}
                                        label="Data de término"
                                        type="date"
                                        defaultValue={task.student_end_at}
                                        value={task.student_end_at}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        required
                                        onChange={(event) => handleChange(task.id, event.target.value, 'student_end_at')}
                                        error={task.error.student_end_at}
                                    />
                                    <TextField
                                        id={`dl-${task.id}`}
                                        label="Data limite da correção"
                                        type="date"
                                        defaultValue={task.teacher_end_at}
                                        value={task.teacher_end_at}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"

                                        required
                                        onChange={(event) => handleChange(task.id, event.target.value, 'teacher_end_at')}
                                        error={task.error.teacher_end_at}
                                    />
                                </Grid>
                            </>
                            ))
                        }
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose}>Cancelar</Button>
                <Spacer />
                <Button variant='contained' color="primary" onClick={handleSave}>Salvar</Button>
            </DialogActions >
        </Dialog>
    )
}

