import { Document, Page, Text } from "@react-pdf/renderer"
import React from "react"
import PDFTable from "../../components/react-pdf-table"

export const ProcessWithoutManagerReport = ({ data, type, styles }) => {

    const headers = [
        { id: 'alias', text: 'Protocolo', width: '23%' },
        { id: 'client.name', text: 'Cliente', large: true },
        { id: 'created_at', text: 'Criado em', format: 'date', width: '15%' }
    ]

    return (
        <Document>
            <Page>
                <Text style={styles.title}>Relatório de atendimentos sem gestor</Text>
                <Text style={styles.subtitle}>{data.institution.name} - Emitido em {new Date().toLocaleString()}</Text>
                <PDFTable headers={headers} data={data.data} />
            </Page>
        </Document>
    )
}