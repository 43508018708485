import React from "react";
import { Datagrid, List, TextField, TextInput } from "react-admin";
import { storageProvider } from "../../providers/storage-provider";

const ClientList = (props) => {

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin
    const filters = [<TextInput label='Nome' source='name' alwaysOn />,]
    return (
        <List exporter={false} bulkActionButtons={false}
            title={'Clientes'}
            {...props}
            filters={filters}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label={'Nome'} />
                {superAdmin && <TextField source="institution.name" label={'Instituição'} />}
                <TextField source="email" label={'Email'} />
                <TextField source="phone" label={'Telefone'} />
            </Datagrid>
        </List>
    )
}

export default ClientList;