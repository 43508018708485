import { Grid, Button as MuiButton } from "@material-ui/core";
import { Delete, Link, Visibility } from "@material-ui/icons";
import axios from "axios";
import RichTextInput from "ra-input-rich-text";
import React, { useState } from "react";
import {
    ArrayField,
    BooleanField,
    BooleanInput,
    Button,
    ChipField,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    EmailField, FormDataConsumer, FormTab,
    ListButton,
    ReferenceField, ReferenceInput, ReferenceManyField, SelectInput, TabbedForm,
    TabbedFormTabs,
    TextField,
    TextInput,
    TopToolbar,
    useRedirect,
    useRefresh
} from "react-admin";
import AddNewAttachmentButton from "../../components/add-new-attachment-button";
import AttachmentDeleteButton from "../../components/attachment-delete-button";
import MaskedInput from "../../components/masked-input";
import { TruncatedTextField } from "../../components/truncated-text-field";
import cookiesProvider from "../../providers/cookies-provider";
import dataProvider from "../../providers/data-provider";
import { storageProvider } from "../../providers/storage-provider";
import { getApiUrl, getUrl } from "../../utils/urls";
import AddNewActivityButton from "./add-new-activity-button";
import AddNewPartyButton from "./add-new-party-button";
import AddNewProcessNoteButton from "./add-new-process-note-button";
import { AddRelatedProcessDialog } from "./add-related-process-dialog";
import ProcessEditAddStudent from "./process-edit-add-student";
import ProcessEditAddUser from "./process-edit-add-user";

const ProcessEditRemoveRelated = ({ id, record, refresh, resource, ...props }) => {

    const apiUrl = getApiUrl()
    const token = cookiesProvider.getToken()

    const handleClick = async () => {
        axios.delete(
            `${apiUrl}/processes/${id}/${resource}/${record.id}`,
            { headers: { Authorization: `Bearer ${token}` } }
        ).then(() => { refresh() })
    }

    return (
        <Button label={'Remover'} onClick={handleClick}>
            <Delete />
        </Button>
    )
}

const ProcessEdit = (props) => {

    const redirect = useRedirect()
    const refresh = useRefresh();

    const [processTypes,] = useState([])
    const [processTypeLoading,] = useState(false)
    const [quillState, setQuillState] = useState(undefined)

    const processTypeChoices = [{ id: 'Judicial' }, { id: 'Administrativo' }, { id: 'Outros' }]

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin
    const profiles = storageProvider.getCurrentUser()?.profiles

    const handleClick = () => {
        redirect(`/process-dashboard/${props.id}`)
    }

    const ToolbarEditWithView = ({ basePath, data }) => {
        return (
            <TopToolbar style={{ justifyContent: "flex-start" }}>
                <ListButton basePath={basePath} label={'Voltar'} />
                <Button onClick={handleClick} label={'Dashboard'}>
                    <Visibility />
                </Button>
                <div style={{ flexGrow: 1 }}></div>
                <Button
                    color={'primary'}
                    onClick={() => {
                        redirect(`/service-requests/${data.serviceRequest.id}`)
                    }}
                    label='Pedido de atendimento'>
                    <Visibility />
                </Button>
            </TopToolbar>
        )
    }

    const CustomTitle = ({ record }) => {
        return <span> Atendimento {record ? `${record.alias}` : ''}</span>
    }

    const ViewFileButton = (props) => {
        return <Button onClick={() => { window.open(props.record.path) }} label={'Visualizar'} />
    }

    const handleRemoveUser = (processId, userId) => {
        dataProvider.delete(`processes/${processId}/user`, { id: userId })
            .then(() => { refresh(); setTimeout(() => { }, 2000) })
    }

    const handleRemoveStudent = (processId, userId) => {
        dataProvider.delete(`processes/${processId}/student`, { id: userId })
            .then(() => { refresh(); setTimeout(() => { }, 2000) })
    }

    const deleteAttachment = (processId, attachmentId) => {
        dataProvider.delete(`attachments/processes/${processId}`, { id: attachmentId })
            .then(() => { refresh(); setTimeout(() => { }, 2000) })
    }

    const ProcessNoteButton = ({ origin, record, ...props }) => {
        const redirect = useRedirect()
        const handleRedirect = () => redirect(`/student-process-notes/${record.id}`, undefined, undefined, undefined, { origin: origin })
        return <MuiButton onClick={handleRedirect} color='primary' variant="contained" size='small' > Visualizar todas</MuiButton >
    }

    const handleQuillState = (value) => {
        setQuillState(value)
    }

    const CustomQuillComponent = ({ source, label, readOnly, ...props }) => {
        return <RichTextInput source={source} label={label} fullWidth options={{ readOnly: readOnly }} {...props} />
    }


    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            actions={<ToolbarEditWithView />}
            title={<CustomTitle />}
        >
            <TabbedForm redirect='edit' tabs={<TabbedFormTabs variant="scrollable" scrollButtons="on" style={{ minWidth: 0 }} />}>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="alias" label={'Protocolo'} fullWidth disabled />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return <>
                                <BooleanInput source="active" onChange={handleQuillState} label={formData.active ? 'Aberto' : 'Arquivado'} fullWidth />
                            </>
                        }
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return <CustomQuillComponent source="synthesis" label={'Síntese do atendimento'} readOnly={quillState === undefined ? !formData.active : !quillState} />
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Processo'}>
                    <Grid container>
                        <Grid item style={{ marginRight: '10px' }}>
                            <SelectInput label={'Tipo'} source={'type.type'} optionText={'id'} choices={processTypeChoices} fullWidth />
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    return (
                                        <>
                                            <ReferenceInput
                                                source="type.id"
                                                label={'Tipo do processo'}
                                                reference="process-types"
                                                filter={{ type: formData.type?.type }}
                                                fullWidth
                                            >
                                                <SelectInput source='name' fullWidth />
                                            </ReferenceInput>
                                        </>
                                    )
                                }}
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (!processTypeLoading && processTypes.find(item => item.id === formData['type'])?.pje)
                                return <MaskedInput label='Número do processo' onlyNumbers={false} source='legal_process_code' mask={'0000000-00.0000.000.0000'} fullWidth />
                            else {
                                return <TextInput source="legal_process_code" label={'Número do processo'} fullWidth />
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Íntegra'} path="record" >
                    <ReferenceManyField filter={{ record: true }} fullWidth reference='attachments' target='processId' label={'Íntegra do processo'}>
                        <Datagrid>
                            <TextField source={'name'} label={'Nome'} />
                            <ViewFileButton />
                            <FormDataConsumer>
                                {
                                    ({ formData, record, ...rest }) => formData.active && (
                                        <Button style={{ color: 'red' }} label="Remover" onClick={() => deleteAttachment(formData.id, record.id)} startIcon={<Delete />}></Button>
                                    )
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) =>
                                formData.active &&
                                <AddNewAttachmentButton
                                    record={formData}
                                    resource="processes"
                                    refresh={refresh}
                                    multiple={true}
                                    isRecord={true}
                                    fileSize={30000000}
                                />
                        }
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Cliente'}>
                    <TextInput source='client.name' label={'Nome'} disabled fullWidth />
                    <TextInput source='client.email' label={'Email'} disabled fullWidth />
                    <TextInput source='client.phone' label={'Telefone'} disabled fullWidth />
                </FormTab>
                <FormTab label={'Partes'} path="parties">
                    <ReferenceManyField fullWidth reference="parties" target="process.id" label={'Partes'}>
                        <Datagrid>
                            <TextField source='name' label={'Nome'} fullWidth />
                            <TextField source='email' label={'Email'} fullWidth />
                            <TextField source='phone' label={'Telefone'} fullWidth />
                            <ReferenceField label={'Tipo'} source="type.id" reference="party-types" fullWidth>
                                <TextField source="name" />
                            </ReferenceField>
                            <BooleanField source='isRepresented' label={'Representado pelo NPJ?'} fullWidth />
                            <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => formData.active && <EditButton />
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => formData.active && <AddNewPartyButton record={formData} refresh={refresh} />
                        }
                    </FormDataConsumer>
                </FormTab>
                {
                    (superAdmin || !(profiles.find(p => p.name === 'Professor') || profiles.find(p => p.name === 'Advogado'))) &&
                    <FormTab label={'Gestores'} path="users">
                        <ReferenceManyField fullWidth reference="users" target="process.id" label={'Gestores'}>
                            <Datagrid>
                                <TextField source='name' label={'Nome'} fullWidth />
                                <EmailField source='email' label={'Email'} fullWidth />
                                <FormDataConsumer>
                                    {
                                        ({ formData, record, ...rest }) => formData.active && <>
                                            <Button label="Remover" onClick={() => handleRemoveUser(formData.id, record.id)} startIcon={<Delete />}></Button>
                                        </>
                                    }
                                </FormDataConsumer>
                            </Datagrid>
                        </ReferenceManyField>
                        <FormDataConsumer>
                            {
                                ({ formData, record, ...rest }) => formData.active && (
                                    <ProcessEditAddUser
                                        record={record}
                                        resource={'processes'}
                                        path={'user'}
                                        title={`Adicionar usuários ao atendimento ${record.alias}`}
                                        refresh={refresh}
                                    />
                                )
                            }
                        </FormDataConsumer>
                    </FormTab>
                }
                <FormTab label={'Alunos'} path="students">
                    <ReferenceManyField fullWidth reference="users" target="student_process.id" label={'Alunos'}>
                        <Datagrid>
                            <TextField source='name' label={'Nome'} fullWidth />
                            <EmailField source='email' label={'Email'} fullWidth />
                            <FormDataConsumer>
                                {
                                    ({ formData, record, ...rest }) => formData.active && <>
                                        <Button label="Remover" onClick={() => handleRemoveStudent(formData.id, record.id)} startIcon={<Delete />}></Button>
                                    </>
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) => formData.active && <ProcessEditAddStudent
                                record={record}
                                resource={'processes'}
                                path={'student'}
                                title={`Adicionar usuários ao atendimento ${record.alias}`}
                                refresh={refresh}
                            />
                        }
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Notas'} path="notes">
                    <ReferenceManyField fullWidth reference="process-notes" target="process.id" label={'Notas de atendimento'}>
                        <Datagrid>
                            <ReferenceField label={'Autor'} link={false} source="author.id" reference="users">
                                <TextField source="name" />
                            </ReferenceField>
                            <TruncatedTextField source={'text'} label={'Conteúdo'} />
                            <DateField source="created_at" label={'Criado em'} />
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {({ formData, record, ...rest }) => {
                            const editable = record.active
                            return (
                                <>
                                    <AddNewProcessNoteButton origin={'processes'} record={formData} disabled={!editable} />
                                    <span style={{ marginLeft: '5px' }} />
                                    <ProcessNoteButton origin={`/processes/${record.id}/notes`} record={record} />
                                </>
                            )
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Anexos'} path="attachments" >
                    <ReferenceManyField filter={{ record: false }} fullWidth reference='attachments' target='processId' label={'Anexos'}>
                        <Datagrid>
                            <TextField source={'name'} label={'Nome'} />
                            <ViewFileButton />
                            <FormDataConsumer>
                                {({ formData, record, ...rest }) =>
                                    formData.active &&
                                    <AttachmentDeleteButton path='processes' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.active &&
                            <AddNewAttachmentButton record={formData} resource="processes" refresh={refresh} multiple={true} />
                        }
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Movimentações'} path="activities">
                    <ReferenceManyField fullWidth reference="activities" target="process.id" label={'Movimentações'}>
                        <Datagrid>
                            <ChipField size="small" source='type.name' label={'Tipo'} fullWidth />
                            <DateField source='start_at' locales={'pt-BR'} label={'Data de início'} fullWidth />
                            <DateField source='deadline' locales={'pt-BR'} label={'Data de término'} fullWidth />
                            <BooleanField source='evaluated' label={'Corrigida'} />
                            <BooleanField source='finished' label={'Finalizada'} />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.active &&
                            <AddNewActivityButton record={formData} refresh={refresh} />
                        }
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Processos conexos'}>
                    <ArrayField source='related' fullWidth>
                        <Datagrid>
                            <TextField source="alias" label='Protocolo' />
                            <TextField source="client.name" label='Cliente' />

                            <FormDataConsumer>
                                {
                                    ({ formData, record, ...rest }) => formData.active && <>
                                        <Button href={`${getUrl()}/#/process-dashboard/${record.id}`} target={'_blank'} label="Visualizar">
                                            <Link />
                                        </Button>
                                    </>
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {
                                    ({ formData, record, ...rest }) => formData.active && <>
                                        <ProcessEditRemoveRelated id={formData.id} resource={'related'} record={record} refresh={refresh} />                                    </>
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ArrayField>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.active &&
                            <AddRelatedProcessDialog process={formData} refresh={refresh} />
                        }
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit >
    )
}

export default ProcessEdit;