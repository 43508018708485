import { Button, Divider, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BooleanField, Datagrid, DateField, ListContextProvider, Loading, Pagination, TextField, useList } from "react-admin";
import dataProvider from "../../../providers/data-provider";
import { getUrl } from "../../../utils/urls";
import { TimeIconField } from "../../time-icon-field";
import { Edit } from "@material-ui/icons";
;

const TeacherDashboardPendingGrades = (props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page,] = useState(1)
    const [perPage,] = useState(25)
    const [filter, setFilter] = useState({ evaluated: false })
    const [value, setValue] = useState('pending');
    // const redirect = useRedirect()

    useEffect(() => {
        (async () => {
            setLoaded(false)
            setLoading(true)
            const resp = await dataProvider.getTeacherActivityDashboard()
            setData(resp.data[0])
            setLoading(false)
            setLoaded(true)
        })()
    }, [filter])

    const ids = data.map((row) => row.id)
    const sort = { field: 'id', order: 'DESC' };
    const context = useList({ data, ids, loading, loaded, page, perPage, sort, filter })

    const handleChange = (event) => {
        let value = event.target.value;

        setValue(value);

        switch (value) {

            case 'all':
                setFilter({})
                break;

            case 'pending':
            default:
                setFilter({ evaluated: false })
                break;

            case 'evaluated':
                setFilter({ evaluated: true })
                break;

        }
    };

    return (
        <>
            <FormControl style={{ marginBlock: '10px', marginInline: '10px', minWidth: '150px' }}>
                <InputLabel id={'filter-select-label'}>Filtro</InputLabel>
                <Select labelId={'filter-select-label'} id={'filter-select'} value={value} disabled={loading} onChange={handleChange} variant="filled">
                    <MenuItem value={'all'}> Todos</MenuItem>
                    <MenuItem value={'pending'}>Não corrigidas</MenuItem>
                    <MenuItem value={'evaluated'}>Corrigidas</MenuItem>
                </Select>
            </FormControl>

            <Divider />
            {
                loading ? <Loading /> : <ListContextProvider value={context}>
                    <Datagrid basePath="/activities" resource="activity">
                        <TimeIconField source="effective_deadline" label={'Legenda'} />
                        <TextField source="process.alias" label={'Protocolo'} />
                        <TextField source="process.client.name" label={'Cliente'} />
                        <TextField source="type.name" label={'Movimentação'} />
                        <DateField source="deadline" label={'Prazo aluno'} />
                        <DateField source="effective_deadline" label={'Prazo final'} />
                        <BooleanField source='evaluated' label={'Corrigido?'} />
                        <ActivityButton />
                    </Datagrid>
                    <Pagination rowsPerPageOptions={[25, 50, 100]} />
                </ListContextProvider>
            }
        </>
    )
}

const ActivityButton = ({ record, resource, ...props }) => {
    return (
        <Button href={
            resource === 'activity' ?
                `${getUrl()}/#/activities/${record.id}` :
                `${getUrl()}/#/activities/${record.activity.id}`
        }
            color="primary"
            target={'_blank'}
            startIcon={<Edit />}
        >
            Movimentação
        </Button>
    )
}

export default TeacherDashboardPendingGrades