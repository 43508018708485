import { Document, Link, Page, Text, View } from "@react-pdf/renderer"
import moment from "moment/moment"
import React from "react"
import { Html } from "react-pdf-html"
import { getUrl } from "../../utils/urls"

export const ManagerReport = ({ data, type, analytic, styles }) => {

    const student = type.id.includes('academic-report')

    const serviceRequestAttachments = data.serviceRequest?.attachments;
    const activitiesAttachments = data.activities?.map((activity) => activity.attachments).flat()
    const records = data.attachments?.filter(attachment => attachment.record === true);
    const processAttachments = data.attachments?.filter(attachment => attachment.record === false);
    const hearings = data.activities?.filter(activity => activity.type.is_hearing)
    const related = data.related;
    const attachments = [...processAttachments, ...activitiesAttachments, ...serviceRequestAttachments]

    return (
        <Document>
            <Page size={'A4'} style={styles.body}>
                <Text style={styles.title}>Relatório {analytic ? 'analítico' : ''}  {student ? "acadêmico" : "gerencial"} do atendimento {data.alias}</Text>
                <Text style={styles.subtitle}>{data.institution.name} - Emitido em {new Date().toLocaleString()}</Text>
                <View style={styles.container}>
                    <View style={styles.column}>
                        <View style={styles.item}>
                            <Text style={{ marginBottom: '8px', backgroundColor: 'lightgray' }}> Dados do atendimento </Text>
                            <Text> Protocolo: {data.alias}  </Text>
                            {data.legal_process_code && <Text> Número do processo: {data.legal_process_code} </Text>}
                            <Text> Tipo de processo: {data.type?.type} </Text>
                            <Text> Complemento: {data.type?.name} </Text>
                        </View>

                        <View style={styles.item}>
                            <Text style={{ backgroundColor: 'lightgray' }}> Processos conexos </Text>
                            {
                                related?.map(
                                    (process) => <Link src={`${getUrl()}/#/process-dashboard/${process.id}`}>
                                        <Text>{`Atendimento: ${process?.alias}`}</Text>
                                        <Text>{`Processo: ${process?.legal_process_code || '-'}`}</Text>

                                    </Link>
                                )
                            }
                        </View>


                        <View style={styles.item}>
                            <Text style={{ backgroundColor: 'lightgray' }}> Íntegra do processo </Text>
                            {
                                records && records?.map((attachment) => <Text>{attachment?.name}</Text>)
                            }
                        </View>


                        <View style={styles.item}>
                            <Text style={{ backgroundColor: 'lightgray' }}> Audiências </Text>
                            {
                                hearings && hearings?.map(
                                    activity => <View>
                                        <Text>{activity.type?.name}</Text>
                                        <Text>Data da audiência: {activity.conference_date ? moment(activity.conference_date).format('LLL') : 'A definir'}</Text>
                                        <Text>Tipo de audiência: {activity.hearing_type === 'p' ? 'Presencial' : activity.hearing_type === 'v' ? 'Virtual' : 'A definir'}</Text>
                                    </View>
                                )
                            }
                        </View>

                        <View style={styles.item}>
                            <Text style={{ backgroundColor: 'lightgray' }}> Gestores </Text>
                            {data.done_by.map(
                                (user) => <View style={{ marginTop: '8px' }}>
                                    <Text> Nome: {user.name}</Text>
                                    <Text> Email: {user.email}</Text>
                                </View>
                            )}
                        </View>

                        <View style={styles.item}>
                            <Text style={{ marginBottom: '8px', backgroundColor: 'lightgray' }}> Cliente </Text>
                            <Text> Nome: {data.client?.name} </Text>
                            <Text> Telefone: {data.client?.phone} </Text>
                            <Text> Email: {data.client?.email} </Text>
                        </View>

                        <View style={styles.item}>
                            <Text style={{ backgroundColor: 'lightgray' }}>Partes</Text>
                            {data.parties.map((party) => <View style={{ marginTop: '8px' }}>
                                <Text> Nome: {party.name} </Text>
                                <Text> Email: {party.email} </Text>
                                <Text> Telefone: {party.phone} </Text>
                            </View>)}
                        </View>

                        <View style={styles.item}>
                            <Text style={{ marginBottom: '8px', backgroundColor: 'lightgray' }}>Anexos</Text>
                            {attachments.map((attachment) => <View>
                                <Link src={attachment?.path}>{attachment?.name}</Link>
                            </View>)}
                        </View>
                    </View>

                    <View style={styles.column}>
                        {data.activities.map(
                            (activity) => <View style={styles.item}>
                                <Text style={{ marginBottom: '8px', backgroundColor: 'lightgray' }}> {activity.type.name} </Text>
                                {student && <Text> Prazo aluno: {moment(activity.deadline).format('DD/MM/YYYY')} </Text>}
                                <Text> Prazo final: {moment(activity.effective_deadline).format('DD/MM/YYYY')} </Text>
                                <Text> Concluída: {activity.finished ? "Sim" : "Não"} </Text>
                                {student && <Text> Avaliada: {activity.evaluated ? "Sim" : "Não"} </Text>}
                                {student && activity.users.length > 0 && <Text style={{ marginTop: 8, backgroundColor: 'lightgray' }}> Alunos responsáveis:</Text>}
                                {student && activity.users.length > 0 && activity.users.map((user) => <View style={{ marginTop: '8px' }}>
                                    <Text> Nome: {user.name}</Text>
                                    <Text> Email: {user.email}</Text>
                                </View>)}
                                {activity.details && <Html style={{ fontSize: 10, }}>
                                    {activity.details}
                                </Html>}
                            </View>
                        )}
                    </View>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
            {analytic && (
                <Page size={'A4'} style={styles.body}>
                    <View>
                        <Text style={{ marginBottom: '8px', backgroundColor: 'lightgray', ...styles.title }}> {'Notas de atendimento'} </Text>
                        {data.notes?.map((note, index) => {
                            return <View style={{ ...styles.note, borderTop: index > 0 ? '1px solid lightgray' : '' }} key={index}>
                                <Text> Autor: {note.author.name} </Text>
                                <Text> Criada em: {moment(note.created_at).format('DD/MM/YYYY hh:mm')} </Text>
                                <Html style={{ fontSize: 10, }}>
                                    {note.text}
                                </Html>
                            </View>
                        })}
                    </View>
                    <View>
                        <Text style={{ marginBottom: '8px', backgroundColor: 'lightgray', ...styles.title }}> {'Notas de movimentação'} </Text>
                        {data.activities?.map(
                            activity => {
                                return (
                                    <View>
                                        {activity.notes?.length > 0 && <Text style={{ marginBottom: '8px', backgroundColor: 'lightgray', fontSize: 12 }}> {activity.type.name} </Text>}
                                        {activity.notes?.map((note, index) => {
                                            return <View style={{ ...styles.note, borderTop: index > 0 ? '1px solid lightgray' : '' }} key={index}>
                                                <Text> Autor: {note.author?.name} </Text>
                                                <Text> Criada em: {moment(note.created_at).format('DD/MM/YYYY hh:mm')} </Text>
                                                <Html style={{ fontSize: 10, }}>
                                                    {note.text}
                                                </Html>
                                            </View>
                                        })}
                                    </View>
                                )
                            })}
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </Page>
            )}
        </Document >
    )
}
