import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider, Grid, List, Typography } from "@material-ui/core";
import { Add, Delete, List as ListIcon } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Loading, useRedirect } from "react-admin";
import cookiesProvider from "../../providers/cookies-provider";
import dataProvider from "../../providers/data-provider";
import { storageProvider } from "../../providers/storage-provider";
import { capitalizeFirstLetter } from "../../utils/functions";
import { DangerousHTMLDiv } from "../../components/dangerousHTMLDiv";

const StudentActivityNotes = (props) => {

    const activityId = props.match.params.id;

    const { origin } = props.history.location.state ? props.history.location.state : { origin: undefined }

    const [loading, setLoading] = useState(true)
    const [activity, setActivity] = useState(undefined)
    const [refresh, setRefresh] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const redirect = useRedirect()
    const profiles = storageProvider.getCurrentUser()?.profiles

    const isTeacherOrAdmin = profiles.find(
        p => p.name === 'Administrador'
            || p.name === 'Professor'
            || p.name === 'Advogado'
            || p.name === 'Coordenador'
    )

    useEffect(() => {
        const getData = async () => {
            const resp = await dataProvider.getActivityNotes(activityId)
            setActivity(resp.data)
            setLoading(false)
        }
        getData()
    }, [refresh, activityId])

    if (loading) { return <Loading /> }
    else {
        const editable = isTeacherOrAdmin ? true : moment().isSameOrBefore(moment(activity.deadline), 'day')
        const redirectTo = `/student-activity-notes/${activity.id}`;
        const userId = cookiesProvider.getUser()

        return (
            <div style={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            size={'small'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                                origin ? redirect(origin) : redirect(`/process-dashboard/${activity.process.id}`)
                            }}
                            startIcon={<ListIcon />}>
                            Retornar
                        </Button>
                        <span style={{ marginLeft: '5px' }} />
                        {
                            editable && <Button startIcon={<Add />} variant={'contained'} color={'primary'} size={'small'} onClick={() => {
                                redirect('create', '/activity-notes', {}, {}, { origin: origin, redirectTo: redirectTo, activity: activity.id })
                            }}>Adicionar nota</Button>
                        }
                        <Typography style={{ marginTop: '16px' }}>{`Notas da movimentação ${activity.type.name} - Atendimento ${activity.process.alias}`}</Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <List style={{ width: '100%' }}>
                            {activity.notes.map(note => (
                                <Grid item xs={12} style={{ marginTop: '1em' }} >
                                    <Card>
                                        <CardHeader titleTypographyProps={{ style: { fontSize: '1.2em' } }} title={note.author.name} subheader={capitalizeFirstLetter(moment(note.created_at).format('LLLL'))} />
                                        <Divider />
                                        <CardContent>
                                            <DangerousHTMLDiv html={note.text} />
                                        </CardContent>
                                        {note.author.id === userId &&
                                            <CardActions>
                                                {
                                                    editable && (
                                                        <Button
                                                            size={'small'}
                                                            variant='contained'
                                                            color="primary"
                                                            onClick={
                                                                () => {
                                                                    redirect('edit', '/activity-notes', note.id, undefined, { origin: origin, redirectTo: redirectTo })
                                                                }
                                                            }> Editar </Button>
                                                    )
                                                }
                                                {
                                                    editable && (
                                                        <Button
                                                            size={'small'}
                                                            variant='contained'
                                                            color="primary"
                                                            onClick={
                                                                () => {
                                                                    setDisabled(true);
                                                                    dataProvider.delete('activity-notes', { id: note.id })
                                                                        .then(() => { setRefresh(!refresh) })
                                                                }
                                                            }
                                                            disabled={disabled}
                                                            startIcon={disabled ? <CircularProgress size={'18'} /> : <Delete />}> Excluir </Button>
                                                    )
                                                }
                                            </CardActions>
                                        }
                                    </Card>
                                </Grid>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default StudentActivityNotes;