import { LinearProgress, withStyles } from "@material-ui/core";

export const VerticalLinearProgress = withStyles((theme) => ({

    root: {
        height: '100%',
        width: '20px',
    },

    colorPrimary: {
        backgroundColor: theme.palette.grey[600],
    },

    bar: {

        backgroundColor: '#006029',

        transform: ({ value }) => {
            return `translateY(${value}%) !important`
        }
    },

}))(LinearProgress) 