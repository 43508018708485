import { AppBar, Button, Checkbox, CssBaseline, Divider, FormControlLabel, Grid, makeStyles, Paper, ThemeProvider, Toolbar, Typography } from "@material-ui/core";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import dataProvider from "../../providers/data-provider";
import { ExternalServiceRequestTheme } from "./theme";

export const ExternalServiceRequestAgreement = ({ setStep, slug, uuid, ...props }) => {

    const [esr, setESR] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [agree, setAgree] = useState(false);

    const handleAgreementChange = (event) => {
        setAgree(event.target.checked)
    }

    const handleAdvance = () => {
        setStep(1)
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        instructions: {
            padding: theme.spacing(2),
            textAlign: 'justify',
            color: theme.palette.text.secondary,
        },
        logo: {
            maxHeight: '48px',
            padding: '4px'
        },
        form: {
            marginTop: theme.spacing(1),
            justifyContent: "center",
        }
    }))

    const classes = useStyles();

    useEffect(() => {
        (async () => {
            const res = await dataProvider.getExternalServiceRequestByUUID('external-service-requests', { uuid: uuid })
            setESR(res.data)
            setLoading(false)
        })()
    }, [uuid])

    return (
        <ThemeProvider theme={ExternalServiceRequestTheme}>
            <CssBaseline />
            {
                loading ? <></> :
                    < div className={classes.root}>
                        <AppBar position="static">
                            <Toolbar variant="dense">
                                <img alt="Juristta Office" src="https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristtaoffice1.png" className={classes.logo} />
                            </Toolbar>
                        </AppBar>
                        <Grid container spacing={3} className={classes.form} >
                            {moment().isBetween(moment(esr.start_at), moment(esr.end_at)) ?
                                <>
                                    <Grid item xs={8}>
                                        <Paper elevation={3} className={classes.paper}>
                                            <Typography> {esr.name || `Formulário online de Pedido de Atendimento`} </Typography>
                                            <Divider />
                                            <div className={classes.instructions} dangerouslySetInnerHTML={{ __html: esr.instructions }} />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Paper className={classes.paper}>
                                            <FormControlLabel
                                                control={<Checkbox checked={agree} onChange={handleAgreementChange} name="agreement" />}
                                                label="Li e concordo com o termo proposto acima"
                                            />
                                            <Button variant="outlined" disabled={!agree} onClick={handleAdvance}>Avançar</Button>
                                        </Paper>
                                    </Grid>
                                </> :
                                <Grid item xs={4}>
                                    <Paper elevation={3} style={{ marginTop: '5em' }} className={classes.paper}>
                                        <Typography> Período de atendimento online encerrado.</Typography>
                                        <Typography> Entre em contato com a instituição de ensino. </Typography>
                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                    </div>

            }
        </ThemeProvider >
    )
}