import { MuiThemeProvider } from "@material-ui/core";
import { useState } from "react";
import cookiesProvider from "../cookies-provider";
import { storageProvider } from "../storage-provider";
import { AppContext } from "./app.context";
import { calcStarterTheme } from "./calc-theme";

export const AppContextProvider = ({ children }) => {

    const lsCurrentUser = storageProvider.getCurrentUser();
    const lsPermissions = lsCurrentUser?.permissions
    const lsSuperAdmin = lsCurrentUser?.superAdmin
    const lsProduct = lsCurrentUser?.product
    const preferredTheme = cookiesProvider.getPreferredTheme()
    const lsInstitution = lsCurrentUser?.institution
    const lsInstitutions = lsCurrentUser?.institutions
    const lsProducts = lsCurrentUser?.products
    const lsSchoolStudentTutorialCompletion = lsCurrentUser?.schoolStudentTutorialCompletion
    const lsSchoolManagerTutorialCompletion = lsCurrentUser?.schoolManagerTutorialCompletion
    const lsOfficeStudentTutorialCompletion = lsCurrentUser?.officeStudentTutorialCompletion
    const lsOfficeManagerTutorialCompletion = lsCurrentUser?.officeManagerTutorialCompletion



    const [currentUser, setCurrentUser] = useState(lsCurrentUser)
    const [permissions, setPermissions] = useState(lsPermissions)
    const [superAdmin, setSuperAdmin] = useState(lsSuperAdmin)
    const [product, setProduct] = useState(lsProduct)
    const [products, setProducts] = useState(lsProducts)
    const [theme, setTheme] = useState(calcStarterTheme(product, preferredTheme))
    const [institution, setInstitution] = useState(lsInstitution)
    const [userInstitutions, setUserInstitutions] = useState(lsInstitutions)
    const [profile, setProfile] = useState(null)
    const [userProfiles, setUserProfiles] = useState(null)
    const [schoolStudentTutorialCompletion, setSchoolStudentTutorialCompletion] = useState(lsSchoolStudentTutorialCompletion)
    const [schoolManagerTutorialCompletion, setSchoolManagerTutorialCompletion] = useState(lsSchoolManagerTutorialCompletion)
    const [officeStudentTutorialCompletion, setOfficeStudentTutorialCompletion] = useState(lsOfficeStudentTutorialCompletion)
    const [officeManagerTutorialCompletion, setOfficeManagerTutorialCompletion] = useState(lsOfficeManagerTutorialCompletion)

    return (
        <AppContext.Provider value={
            {
                currentUser, setCurrentUser,
                permissions, setPermissions,
                product, setProduct,
                products, setProducts,
                theme, setTheme,
                superAdmin, setSuperAdmin,
                institution, setInstitution,
                userInstitutions, setUserInstitutions,
                profile, setProfile,
                userProfiles, setUserProfiles,
                schoolStudentTutorialCompletion, setSchoolStudentTutorialCompletion,
                schoolManagerTutorialCompletion, setSchoolManagerTutorialCompletion,
                officeStudentTutorialCompletion, setOfficeStudentTutorialCompletion,
                officeManagerTutorialCompletion, setOfficeManagerTutorialCompletion,
            }
        }>
            <MuiThemeProvider theme={theme}>
                {children}
            </MuiThemeProvider>
        </AppContext.Provider>
    )

}