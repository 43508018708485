import { officeDarkTheme, schoolDarkTheme } from "../../themes";


export const calcStarterTheme = (product, preferredTheme) => {
    if (product?.id === 1) {
        //   if (preferredTheme === 'light') {
        //        return schoolLightTheme
        //    } else {
        return schoolDarkTheme
        //   }
    } else if (product?.id === 2) {
        //    if (preferredTheme === 'light') {
        //        return officeLightTheme
        //    } else {
        return officeDarkTheme
        //    }
    } else {
        return officeDarkTheme
    }
}