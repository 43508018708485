import { List } from "@material-ui/icons";
import RichTextInput from 'ra-input-rich-text';
import React from "react";
import { Button, Edit, FormTab, ReferenceInput, SaveButton, SelectInput, TabbedForm, Toolbar, useRedirect } from "react-admin";

const ActivityNoteEdit = (props) => {

    const r = useRedirect()
    const { redirectTo, origin } = props.history.location.state

    const CustomToolbar = props => <Toolbar {...props}>
        <SaveButton disabled={props.pristine} />
        <span style={{ flexGrow: 1 }} />
    </Toolbar>

    return (
        <>
            <Button
                onClick={() => {
                    r(redirectTo, undefined, undefined, undefined, { origin })
                }}
                label={'Retornar'}
                style={{ alignSelf: 'flex-start' }}
                startIcon={<List />}
            />
            <Edit
                title={'Notas de atendimento'}
                mutationMode="pessimistic"
                {...props}
                onSuccess={() => { r(redirectTo, undefined, undefined, undefined, { origin }) }}
            >
                <TabbedForm toolbar={<CustomToolbar />}>
                    <FormTab label={'Dados Gerais'}>
                        <ReferenceInput source="author.id" label={'Autor'} reference="users" fullWidth disabled>
                            <SelectInput source="name" />
                        </ReferenceInput>
                        <RichTextInput source="text" label={'Conteúdo'} fullWidth />
                    </FormTab>
                </TabbedForm>
            </Edit >
        </>
    )
}

export default ActivityNoteEdit;