import { Document, Page, Text } from "@react-pdf/renderer"
import React from "react"
import PDFTable from "../../components/react-pdf-table"

export const SchedulingReport = ({ data, type, styles, params }) => {

    const headers = [
        { id: 'date', text: 'Data/Hora', format: 'datetime', width: '14%' },// 15% width
        { id: 'client.name', text: 'Cliente', width: '19%' },// 25% width
        { id: 'managers', text: 'Gestores', width: '24%', multiple: true, multipleSubfield: 'name' },// 20% width
        { id: 'memberships', text: 'Alunos', width: '24%', multiple: true, multipleSubfield: 'student.name' }, // 25% width
        { id: 'memberships', text: 'Turma', width: '14%', multiple: true, multipleSubfield: 'class.name' },// 15% width
    ]

    return (
        <Document>
            <Page orientation="landscape">
                <Text style={styles.title}>Relatório de agendamentos por data</Text>
                <Text style={styles.subtitle}>{data.institution.name} - Emitido em {new Date().toLocaleString('pt-BR')}</Text>
                <Text style={styles.subtitle}> Data de referência: {new Date(params + ' 03:00:00').toLocaleDateString()} </Text>
                <Text style={styles.subtitle}> Período letivo: {data?.data[0]?.academic_period.name} </Text>
                <PDFTable headers={headers} data={data.data} />
            </Page>
        </Document>
    )
}
