import React from "react";
import { Create, FormTab, TabbedForm, TextInput, useNotify, useRedirect, useRefresh } from "react-admin";
import MaskedInput from "../../components/masked-input";

const ClientCreate = (props) => {

    const redirect = useRedirect()
    const notify = useNotify()
    const refresh = useRefresh()

    const onSuccess = ({ data }) => {
        notify('Cliente criado com sucesso')
        redirect('/service-requests/create/general', undefined, {}, {}, { data })
        refresh()
    }

    return (
        <Create {...props} onSuccess={onSuccess}>
            <TabbedForm >
                <FormTab label={'Dados Gerais'} >
                    <TextInput source="name" label={'Nome'} fullWidth />
                    <TextInput source="email" type={'email'} label={'Email'} fullWidth />
                    <MaskedInput source="phone" onlyNumbers={true} mask={'(99) 99999-9999'} label={'Telefone'} fullWidth />
                </FormTab>
            </TabbedForm>
        </Create >
    )
}

export default ClientCreate;