import { Button, Dialog, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import axios from "axios";
import React, { useState } from "react";
import { PasswordInput, required, SaveButton, SimpleForm, Toolbar, useNotify } from "react-admin";
import cookiesProvider from "../../providers/cookies-provider";
import { getApiUrl } from "../../utils/urls";

export const ProfileChangePassword = ({ user }) => {
    const apiUrl = getApiUrl();
    const notify = useNotify();

    const [open, setOpen] = useState(false);
    const [fields, setFields] = useState({
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
    });

    const handleChange = (event) => {
        const { value, name } = event.target;
        setFields({ ...fields, [name]: value });
    };

    const validateRequired = [required("Campo obrigatório")]

    const samePassword = (message = 'As senhas não coincidem') =>
        value => value === fields['newPassword'] ? undefined : message;

    const validateSamePassword = [required("Campo obrigatório"), samePassword()]

    const ToolbarPasswordForm = (props) => {
        return <Toolbar {...props} >
            <SaveButton
                label="Salvar"
                submitOnEnter={false}
                onSave={(values) => handleChangePasswordSubmit(values)}
            />
            <Button
                style={{ marginLeft: '5px' }}
                variant={'contained'}
                onClick={() => setOpen(false)}>
                Cancelar
            </Button>
        </Toolbar>
    }


    //TODO Passar esse código para o dataProvider
    const handleChangePasswordSubmit = async () => {
        const user = cookiesProvider.getUser();
        const token = cookiesProvider.getToken();
        await axios.post(`${apiUrl}/users/updatePassword`,
            { id: user, newPassword: fields.newPassword, currentPassword: fields.password },
            { headers: { "Authorization": token } })
            .then(response => response.status === 500 ? notify('Erro', 'error') : notify('Senha atualizada com sucesso', 'success'))
            .catch((error) => {
                notify(error, 'error')
            });
        setOpen(false);
    }

    const handleChangePasswordDialogOpen = () => {
        setOpen(true);
    }

    const handleChangePasswordDialogClose = () => {
        setOpen(false);
        setFields({
            password: '',
            newPassword: '',
            newPasswordConfirm: '',
        })
    }

    return (
        <>
            <Button
                style={{ marginLeft: '8px', marginTop: '8px' }}
                variant="contained"
                color="primary"
                startIcon={<VpnKeyIcon />}
                onClick={handleChangePasswordDialogOpen}>
                Alterar Senha
            </Button>
            <Dialog onClose={handleChangePasswordDialogClose} open={open}>
                <DialogTitle> Alterar senha </DialogTitle>
                <Divider />
                <DialogContent>
                    <SimpleForm toolbar={<ToolbarPasswordForm />} onSubmit={handleChangePasswordSubmit}>
                        <PasswordInput variant="standard" source="password" name="password" label="Senha atual" validate={validateRequired} onChange={handleChange} />
                        <PasswordInput variant="standard" source="newPassword" name="newPassword" label="Nova senha" validate={validateRequired} onChange={handleChange} />
                        <PasswordInput variant="standard" source="newPasswordConfirm" name="newPasswordConfirm" label="Confirme a nova senha" validate={validateSamePassword} onChange={handleChange} />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
}
