import React from "react";
import { DateInput, Edit, FormTab, TabbedForm, TextInput } from "react-admin";

const AcademicPeriodEdit = (props) =>
    <Edit mutationMode="pessimistic" {...props}>
        <TabbedForm>
            <FormTab label={'Dados Gerais'}>
                <TextInput source="name" label={'Nome'} fullWidth />
                <TextInput source="description" label={'Descrição'} fullWidth />
                <DateInput source="start_at" label={'Data de início'} fullWidth />
                <DateInput source="end_at" label={'Data de término'} fullWidth />
            </FormTab>
        </TabbedForm>
    </Edit>

export default AcademicPeriodEdit;