import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { pdfTableStyles } from './styles';

const styles = pdfTableStyles;

const formatValue = (value, format) => {
    if (format === 'date') {
        return value ? moment(value).format('DD/MM/YYYY') : ''
    } else if (format === 'datetime') {
        return value ? moment(value).format('DD/MM/YYYY HH:mm') : ''
    } else if (format === 'time') {
        return value ? moment(value).format('HH:mm') : ''
    } else if (format === 'workload') {
        if (value === 'ct') {
            return 'Cumpriu totalmente'
        } else if (value === 'cp') {
            return 'Cumpriu parcialmente'
        } else if (value === 'nc') {
            return 'Não cumpriu'
        } else {
            return '-'
        }
    } else if (format === 'workload_type') {
        if (value === 'G') return 'Nota'
        else if (value === 'T') return 'Carga horária'
        else if (value === 'C') return 'Cumprimento de tarefa'
    } else {
        return value;
    }
}

const TableHeader = ({ headers }) => (
    <View style={styles.container}>
        {
            headers?.map((header, idx) => <View key={`${header.id}${idx}`} style={{ ...(header.large ? styles.tableHeaderLarge : styles.tableHeader), width: header.width }}>
                <Text style={styles.cellText}>{header.text}</Text>
            </View>)
        }
    </View>
);

const TableRows = ({ headers, items }) => {
    return <>
        {
            items?.map(
                (item, idx) =>
                    <View style={styles.row} key={`${item.id}${idx}`}>
                        {
                            // campo multiple deve sempre parar no elemento do objeto que é um array
                            // campo multipleSubfield são os campos dentro do array que serão mapeados
                            headers?.map(header => {
                                if (header.multiple) {
                                    const arr = _.get(item, header.id)
                                    /*return <Text key={header.id} style={{ ...(header.large ? styles.tableRowLarge : styles.tableRow), width: header.width }}>
                                        {arr.map(element => formatValue(_.get(element, header.multipleSubfield), header.format)).join(' ,')}
                                    </Text>*/
                                    return <View key={`${item.id}${idx}`} style={{ ...(header.large ? styles.tableRowLarge : styles.tableRow), width: header.width }}>
                                        {arr.map(element => <Text style={styles.cellText}> {formatValue(_.get(element, header.multipleSubfield), header.format)} </Text>)}
                                    </View>
                                } else {
                                    return (
                                        <View key={`${item.id}${idx}`} style={{ ...(header.large ? styles.tableRowLarge : styles.tableRow), width: header.width }}>
                                            <Text style={styles.cellText}>
                                                {formatValue(_.get(item, header.id), header.format)}
                                            </Text>
                                        </View>
                                    )
                                }
                            })
                        }
                    </View>
            )}
    </>
};

const PDFTable = ({ headers, data }) => {
    return (
        <View style={styles.tableContainer}>
            <TableHeader headers={headers} />
            <TableRows items={data} headers={headers} />
        </View>
    )
}

export default PDFTable