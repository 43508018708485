import {
    Avatar, IconButton, Link, List,
    ListItem, ListItemAvatar, ListItemText, ListSubheader, makeStyles, Tooltip, Typography
} from '@material-ui/core'
import { Assignment, AttachFile, Check, Edit, FileCopy, MoreHoriz, Person, Schedule, Warning } from '@material-ui/icons'
import {
    TimelineConnector, TimelineContent, TimelineDot,
    TimelineItem, TimelineOppositeContent, TimelineSeparator
} from '@material-ui/lab'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import { useNotify, useRedirect } from 'react-admin'
import ExpandableCard from '../../components/expandable-card'
import LinkToVideoConference from '../../components/link-to-conference-button.jsx'
import cookiesProvider from '../../providers/cookies-provider'
import { storageProvider } from '../../providers/storage-provider'
import { handleClipboard } from '../../utils/functions'
import ViewOrientationsDialog from './view-orientations-dialog'
import { DangerousHTMLDiv } from '../../components/dangerousHTMLDiv'

const useStyles = makeStyles((theme) => ({
    notStarted: {
        backgroundColor: '#a5a5a5',
    },
    inProgress: {
        backgroundColor: '#1b63cf',
    },
    completedSuccess: {
        backgroundColor: '#44bb33',
    },
    completedAlert: {
        backgroundColor: '#ebdc38',
    },
    completedFailure: {
        backgroundColor: '#bb3333',
    }
}))

const EditActivityButton = ({ activity, ...props }) => {
    const redirect = useRedirect();
    const handleClick = () => {
        redirect(`/activities/${activity.id}`)
    }
    return <Tooltip title={'Editar a movimentação'}><IconButton color={'text'} onClick={handleClick}><Edit /></IconButton></Tooltip>
}

export const ProcessActivityPanel = ({ activity, isStudent, process, ...props }) => {
    const notify = useNotify()
    const user = cookiesProvider.getUser()
    const redirect = useRedirect()
    const superadmin = storageProvider.getCurrentUser()?.superAdmin
    const userCanInteract = superadmin || activity.users?.find((u) => u.id === user) !== undefined || process.done_by?.find((u) => u.id === user) !== undefined
    const classes = useStyles()

    let formattedDeadline = moment(activity.deadline).set('hour', 23).set('minutes', 59)
    let formattedEffectiveDeadline = moment(activity.effective_deadline).set('hour', 23).set('minutes', 59)
    let status;

    if (activity.finished) {
        status = 'completedSuccess'
    } else {
        if (moment().isBefore(moment(activity.start_at))) {
            status = 'notStarted'
        } else if (moment().isBefore(moment(formattedDeadline))) {
            status = 'inProgress'
        } else if (moment().isBetween(moment(formattedDeadline), moment(formattedEffectiveDeadline))) {
            status = 'completedAlert'
        } else if (moment().isAfter(formattedEffectiveDeadline)) {
            status = 'completedFailure'
        }
    }

    const content = <DangerousHTMLDiv html={activity.details} />

    return (
        <TimelineItem>
            <TimelineOppositeContent style={{ display: 'none' }} />
            <TimelineSeparator>
                <TimelineDot className={classes[status]}>

                    {
                        status === 'notStarted' ? <Schedule /> :
                            status === 'inProgress' ? <MoreHoriz /> :
                                status === 'completedSuccess' ? <Check /> :
                                    status === 'completedAlert' ? <Warning /> :
                                        status === 'completedFailure' ? <Warning /> : <MoreHoriz />
                    }
                </TimelineDot>
                <TimelineConnector className={classes[status]} />
            </TimelineSeparator>
            <TimelineContent style={{maxWidth:'95%'}}>
                <ExpandableCard
                    realce
                    title={activity.type?.name}
                    content={content}
                    action={<>
                        {userCanInteract && activity.type?.requires_videoconference && activity.conference_date && activity.video_room && <>
                            <Tooltip title={'Copiar link da reunião'}><IconButton onClick={() => { handleClipboard(`https://meet.jit.si/${activity.video_room}`, notify) }}><FileCopy /></IconButton></Tooltip>
                            <LinkToVideoConference roomId={activity.video_room} roomPassword={activity.video_room_password} />
                        </>
                        }
                        {!isStudent && <EditActivityButton activity={activity} />}
                        {userCanInteract &&
                            <>
                                <Tooltip title={'Anexar'}>
                                    <IconButton
                                        disabled={isStudent ? moment().isAfter(formattedDeadline, 'day') : moment().isAfter(formattedEffectiveDeadline, 'day')}
                                        onClick={() => {
                                            redirect('create', '/attachments', {}, {}, { redirectTo: `/process-dashboard/${process.id}`, resource: 'activities', id: activity.id, fileSize: isStudent ? 5000000 : 25000000 })
                                        }}>
                                        <AttachFile />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                        <Tooltip title={'Notas da movimentação'}>
                            <IconButton onClick={() => { redirect(`/student-activity-notes/${activity.id}`, undefined, undefined, undefined, { origin: `/process-dashboard/${process.id}` }) }}>
                                <Assignment />
                            </IconButton>
                        </Tooltip>
                        <ViewOrientationsDialog activity={activity} />
                    </>
                    }>
                    {/*}
                    <Typography component={'div'}> Criado em: <Typography color='textSecondary' component={'span'}> {moment(activity.created_at).format('LLL')} </Typography></Typography>
                    */}
                    <Typography component={'div'}> Data de início: <Typography color='textSecondary' component={'span'}> {moment(activity.start_at, 'YYYY-MM-DD').format('LL')} </Typography></Typography>
                    <Typography component={'div'}> Data de término: <Typography color='textSecondary' component={'span'}> {moment(activity.deadline, 'YYYY-MM-DD').format('LL')} </Typography></Typography>
                    {
                        !isStudent && <Typography component={'div'}> Data de término para os gestores: <Typography color='textSecondary' component={'span'}> {moment(activity.effective_deadline, 'YYYY-MM-DD').format('LL')} </Typography></Typography>
                    }

                    {
                        activity.type?.requires_videoconference && <>
                            <Typography component={'div'}> Data da videoconferência: <Typography color='textSecondary' component={'span'}> {activity.conference_date ? moment(activity.conference_date).format('LLL') : 'A definir'} </Typography></Typography>
                        </>
                    }
                    {activity.users.length > 0 &&
                        <List dense={true} subheader={<ListSubheader disableSticky>Alunos</ListSubheader>}>
                            {activity.users?.map((user, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Person />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.name}
                                            secondary={user.email}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    }

                    {
                        activity.attachments.length > 0 &&
                        <List dense subheader={<ListSubheader disableSticky>Anexos</ListSubheader>}>
                            {
                                activity.attachments?.map((attachment, index) => {
                                    return <ListItem dense key={index}>
                                        <ListItemText>
                                            <Link target={'_blank'} rel={'noopener'} href={attachment.path}>
                                                <Typography noWrap={true}>
                                                    {attachment.name}
                                                </Typography>
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                })
                            }
                        </List>
                    }
                </ExpandableCard>
            </TimelineContent>
        </TimelineItem >
    )
}