import { Document, Page, Text } from "@react-pdf/renderer"
import React from "react"
import PDFTable from "../../components/react-pdf-table"

export const SchedulingAuditReport = ({ data, type, styles, params }) => {

    const headers = [
        { id: 'date', text: 'Data/Hora', format: 'datetime', width: '12%' },
        { id: 'client.name', text: 'Cliente', large: true },
        { id: 'class.name', text: 'Turma', width: '15%' },
        { id: 'memberships', text: 'Alunos', width: '30%', multiple: true, multipleSubfield: 'student.name' },
    ]

    return (
        <Document>
            <Page size={'A4'} orientation="landscape">
                <Text style={styles.title}>Auditoria de agendamentos</Text>
                <Text style={styles.subtitle}>Relatório com a finalidade de verificar a existência de agendamentos sem a quantidade máxima de alunos estipulada e horários que não foram marcados.</Text>
                <Text style={styles.subtitle}>{data.institution.name} - Emitido em {new Date().toLocaleString('pt-BR')}</Text>
                <PDFTable headers={headers} data={data.data} />
            </Page>
        </Document>
    )
}
