import axios from "axios";
import React, { useState } from 'react';
import { AutocompleteInput, Button, FileField, FileInput, FormWithRedirect, NumberInput, ReferenceInput, SaveButton, useNotify, useRefresh } from 'react-admin';
import { getApiUrl } from '../../utils/urls';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IconContentAdd from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconCancel from '@material-ui/icons/Cancel';
import { read, utils } from 'xlsx';
import cookiesProvider from '../../providers/cookies-provider';


const date2ms = (d) => {
    let date = new Date(Math.round((d - 25569) * 864e5));
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
}


const SchedulingImportButton = () => {

    const apiUrl = getApiUrl();
    const [showDialog, setShowDialog] = useState(false);
    const [showLog, setShowLog] = useState(false);
    const [logData, setLogData] = useState({});
    const [loading, setLoading] = useState(false);

    const notify = useNotify();
    const refresh = useRefresh();

    const HandleClick = () => {
        setShowDialog(true);
    }

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleReturn = () => {
        setShowLog(false);
    }

    const handleSubmit = async values => {

        setLoading(true);
        let reader = new FileReader();
        reader.readAsArrayBuffer(values.files.rawFile)

        reader.onload = async (e) => {

            const token = cookiesProvider.getToken()

            let data = new Uint8Array(reader.result);
            let workbook = read(data, { type: 'array' });
            let sheet = workbook.Sheets[workbook.SheetNames[0]];
            const ref = sheet['!ref'].split(':');
            let new_ref = ['A4', ref[1]].join(':');
            sheet['!ref'] = new_ref
            let data_json = utils.sheet_to_json(sheet);

            for (let row of data_json) {
                row.formatted_date = date2ms(row['Data'] + row['Hora'])
                row.min_apply_date = date2ms(row['Data inicial candidatura'])
                row.max_apply_date = date2ms(row['Data final candidatura'])
            }

            let req = { data: data_json, max_students: values.max_students, academic_period: values.academic_period };

            await axios.post(`${apiUrl}/schedulings/importFromExcel`, req, { headers: { "Authorization": token } })
                .then(({ data }) => {
                    setLoading(false);
                    setShowDialog(false);
                    setLogData(data);
                    refresh();
                    setShowLog(true);
                })
                .catch((error) => {
                    const message = error.response.data.error
                    notify(message, 'error');
                    setLoading(false);
                });
        }
    }

    return (
        <>
            <Button
                color="primary"
                startIcon={<IconContentAdd />}
                onClick={HandleClick}
                label="Importar tabela de agendamentos"
            />
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Importar tabela de agendamentos">
                <DialogTitle>Importar tabela de agendamentos</DialogTitle>
                <FormWithRedirect
                    resource="scheduling"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                            <DialogContent>
                                <Typography>
                                    Favor providenciar a planilha modelo preenchida (disponível {<Link target="_blank" href="https://game-juridico.s3.sa-east-1.amazonaws.com/assets/HorariosImportacao.xlsx">aqui</Link>}) para realizar a importação dos horários de agendamento.
                                </Typography>
                                <FileInput source="files" label="Arquivo" accept=".xlsx">
                                    <FileField source="src" title="title" fullWidth />
                                </FileInput>
                                <NumberInput source="max_students" label="Número máximo de alunos por atendimento" defaultValue={2} step={1} fullWidth />
                                <ReferenceInput label="Período letivo" source="academic_period.id" reference="academic-periods" fullWidth>
                                    <AutocompleteInput source="name" optionText={'name'} optionValue={'id'} />
                                </ReferenceInput>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
            <Dialog
                fullWidth
                open={showLog}
                onClose={handleReturn}
                aria-label="Log de Operações">
                <DialogTitle>Log de Operações</DialogTitle>
                <DialogContent>
                    {logData.error ? <Typography> {logData.error}</Typography> : <Typography>Registros inseridos com sucesso: {logData.length}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button
                        label="Retornar"
                        onClick={handleReturn}
                    >
                        <ArrowBackIcon />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SchedulingImportButton; 