import React from "react";
import { Button, useRedirect } from "react-admin";

const AddNewProcessNoteButton = ({ record, origin, disabled, ...props }) => {

    const redirect = useRedirect()

    const redirectTo = origin === 'processes' ? `/processes/${record.id}/notes` : `/student-process-notes/${record.id}`

    return (
        <Button
            onClick={() => {
                redirect('create', '/process-notes', {}, {}, { redirectTo: redirectTo, origin: redirectTo, process: record.id })
            }}
            label="Adicionar"
            variant="contained"
            disabled={disabled || false}
        >
        </Button>
    )
}

export default AddNewProcessNoteButton;