import axios from 'axios';
import React from "react";
import { Button, Create, FileField, FileInput, FormTab, required, TabbedForm, useNotify, useRedirect, useRefresh } from "react-admin";
import { getApiUrl } from '../../utils/urls';

import { makeStyles } from "@material-ui/core";
import cookiesProvider from '../../providers/cookies-provider';
import { Menu } from '@material-ui/icons';

const useStyles = makeStyles({
    root: {
        '& div > a:link': { color: '#ffffff' },
        '& div > a:active': { color: '#f0f0f0' },
        '& div > a:visited': { color: '#c0c0c0' },
        '& div > a:hover': { color: '#e1e1e1' },
    }
})

const AttachmentCreate = (props) => {

    const { redirectTo, resource, id, fileSize, isRecord } =
        props.history.location.state ? props.history.location.state
            : { redirectTo: null, resource: null, id: null, fileSize: 5000000, isRecord: false }

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const apiUrl = getApiUrl()
    const styles = useStyles();
    const onSuccess = ({ data }) => {
        notify(`Anexo criado com sucesso`);
        redirect(redirectTo === null ? '/' : redirectTo);
        refresh();
    };

    const uploadFiles = async (data) => {
        if (data.files && data.files.length > 0) {

            const token = await cookiesProvider.getToken();
            const cfg = {
                headers: { Authorization: `Bearer ${token}` },
                "Content-Type": "multipart/form-data",
                'Access-Control-Allow-Origin': 'https://app.juristta.com'
            }
            let uploadedFiles = []
            let fd = new FormData()
            fd.append("resource", resource)
            data.files.map(async (file) => {
                fd.append("files", file.rawFile)
            })

            const res = await axios.post(`${apiUrl}/attachments/upload`, fd, cfg);
            if (res.status === 201 && res.data) {
                uploadedFiles = { files: res.data, resource: resource, id: id, record: isRecord }
            }
            return uploadedFiles;
        }
    }

    return (
        <Create
            {...props}
            onSuccess={onSuccess}
            transform={uploadFiles}
            actions={<Button style={{ marginBottom: '16px' }} variant='contained' label='Retornar' startIcon={<Menu />} onClick={() => redirect(redirectTo)} />}
        >
            <TabbedForm redirect={redirectTo}>
                <FormTab label={isRecord ? 'Íntegra' : 'Anexos'}>
                    <FileInput
                        source="files"
                        className={styles.root}
                        multiple={true}
                        validate={[required(`Favor selecionar 1 ou mais arquivos com tamanho máximo de ${fileSize ? `${fileSize / 1000000}` : '5'}MB por arquivo.`)]}
                        labelMultiple={`Selecione arquivos ou arraste-os para esta área para anexá-los. Tamanho máximo de ${fileSize ? `${fileSize / 1000000}` : '5'}MB por arquivo`}
                        maxSize={fileSize || 5000000}
                    >
                        <FileField source="path" title="title" />
                    </FileInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

export default AttachmentCreate;