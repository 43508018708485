
import { Typography } from "@material-ui/core"
import { CodeBlock, dracula, CopyBlock, Code } from "react-code-blocks";

export const CadastroPeriodoLetivo = () => {

    const text = `{
    "nome": string,
    "descricao": string,
    "dt_inicio": data no formato ISO 8601 (YYYY-MM-DD),
    "dt_termino": data no formato ISO 8601 (YYYY-MM-DD),
}`;

    const text2 = `POST /v1/periodo-letivo
Host: api.app.juristta.com
Authorization: Bearer v1_a2f5i10a_abc123def456ghi789jk

{
    "nome": "2025/1",
    "descricao": "Primeiro semestre 2025",
    "dt_inicio": "2025-01-01",
    "dt_termino": "2025-06-30"
}`;

    const text3 = `{
    "id": "8f844d51-73e9-2c4a-ba3e-8d9b734a520b",
    "name": "2025/1",
    "description": "Segundo semestre 2025",
    "start_at": "2025-01-01T03:00:00.000Z",
    "end_at": "2025-06-30T03:00:00.000Z",
}`;

    return <>
        <Typography variant='h6'>Cadastro de períodos letivos</Typography>
        <div style={{ marginBottom: '16px' }} />

        <Typography variant='body1'>Este endpoint permite a criação de um novo período letivo. Um período letivo é um intervalo de tempo durante o qual as aulas e atividades acadêmicas são realizadas.</Typography>

        <div style={{ marginBlock: '16px', borderRadius: '8px', backgroundColor: '#1f1f1f', padding: '8px' }} >
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>Método:</Typography> <Typography component='span'>POST</Typography>
            </div>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>URL:</Typography> <Typography component='span'>https://api.app.juristta.com/v1/periodo-letivo </Typography>
            </div>
        </div>
        <Typography variant="h6">Parâmetros do corpo da requisição </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>

        <Typography variant="h6">Exemplo de requisição </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text2} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>

        <div style={{ marginBottom: '16px' }} />

        <Typography variant="h6">Exemplo de resposta </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CodeBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text3} language="json" theme={dracula} />
        </Typography>
        <Typography variant="body1">Requisições que não seguirem corretamente a especificação receberão uma resposta com o status <Code language='http' text="HTTP 400 Bad Request." theme={dracula} ></Code> com detalhes do problema. </Typography>
    </>


}