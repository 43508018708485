
import { useEffect, useState } from "react";
import { AdminUI, useAuthenticated } from "react-admin";
import { resourceProvider } from "./resource-provider";

export const ResourceProvider = ({ appContext, ...props }) => {

    useAuthenticated()

    const [resources, setResources] = useState([])

    useEffect(() => {
        const r = resourceProvider.getEnabledResources(appContext)
        setResources(r)
    }, [appContext])

    //{resources.map((resource, index) => <Resource key={`resource-${index}`} {...resource} />)}
    return (
        <AdminUI {...props}>
            {resources}
        </AdminUI>
    )
}
