import { Box, Divider, List, ListItem, ListItemText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { ExitToApp, Person } from "@material-ui/icons";
import { useContext } from "react";
import { useLogout, useRedirect } from "react-admin";
import { AppContext } from "../../providers/app-context-provider/app.context";
import { calcStarterTheme } from "../../providers/app-context-provider/calc-theme";
import cookiesProvider from "../../providers/cookies-provider";
import { storageProvider } from "../../providers/storage-provider";
import { Spacer } from "../spacer";

export const ProfileInfoComponent = ({ ...props }) => {
  const makeLogout = useLogout();
  const redirect = useRedirect();
  const appContext = useContext(AppContext);

  const toggleProduct = () => {
    const preferredTheme = cookiesProvider.getPreferredTheme()
    const newProduct = appContext.product.id === 1 ? appContext.products.find(product => product.id === 2) : appContext.products.find(product => product.id === 1)
    appContext.setProduct(newProduct)
    appContext.setTheme(calcStarterTheme(newProduct, preferredTheme))
    const currentUser = storageProvider.getCurrentUser()
    currentUser.product = newProduct
    storageProvider.setCurrentUser(currentUser)
    redirect('/')
  }



  return (
    <Box style={{ width: '450px' }}>
      <List dense disablePadding>
        <ListItem>
          <ListItemText primary={appContext.product.name} secondary={'Módulo atual'} />
          <Spacer />
          <Button
            disabled={appContext.products.length === 1}
            onClick={() => { toggleProduct() }}>
            Alterar módulo
          </Button>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={appContext.currentUser.name}
            secondary={appContext.superAdmin ? 'Superadministrador' : appContext.currentUser.profiles[0].name}
          />
          <Spacer />
          <Button startIcon={<Person />} onClick={() => redirect('/me')}>
            Meus dados
          </Button>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={appContext.institution.name}
            secondary={'Instituição selecionada'}
          />
          {/*    <Spacer />
          <Button disabled={appContext.userInstitutions.length === 1}>
            Alterar Instituição
          </Button> */}
        </ListItem>
        <Divider />
        <ListItem>
          <Spacer />
          <Button startIcon={<ExitToApp />} onClick={makeLogout}>
            Sair
          </Button>
        </ListItem>
      </List>
    </Box>
  )
}