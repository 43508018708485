import {
    List
} from '@material-ui/core'
import 'moment/locale/pt-br'
import React from 'react'
import ExpandableCard from '../../components/expandable-card'
import { ListAttachments } from '../../components/list-attachments/list-attachments.component'

export const SchoolActivityAttachments = ({ id, attachments, setAttachments, isStudent, ...props }) => {
    return (attachments &&
        <ExpandableCard realce={true} title={`Documentos gerais`} mini={!attachments || attachments.length === 0}>
            <List component="nav" dense={true}>
                <ListAttachments attachments={attachments} setAttachments={setAttachments} enableDelete={!isStudent} relatedResource={'school-activities'} relatedId={id} />
            </List>
        </ExpandableCard>
    )
}