import { Schedule } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import { SchedulingCreate } from "./scheduling-create";
import { SchedulingEdit } from "./scheduling-edit";
import { SchedulingList } from "./scheduling-list";

export const schedulingResource = {
    name: ApplicationResource.SCHEDULING,
    icon: Schedule,
    options: { label: "Agendamentos" },
    link: "/schedulings",
    tableName: 'scheduling',
    list: SchedulingList,
    edit: SchedulingEdit,
    create: SchedulingCreate,
    products: ['office']
}