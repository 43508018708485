import portugueseMessages from "@henriko/ra-language-portuguese";
import polyglotI18nProvider from "ra-i18n-polyglot";

const messages = {
  pt: portugueseMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "pt", {
  allowMissing: true,
});

export default i18nProvider;
