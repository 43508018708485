import { Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Link, List, ListItem, Menu, MenuItem, Snackbar, Typography } from "@material-ui/core";
import { Attachment, MoreVert, Visibility } from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Title, useRedirect } from "react-admin";
import cookiesProvider from "../../providers/cookies-provider";
import dataProvider from "../../providers/data-provider";
import { storageProvider } from "../../providers/storage-provider";
import { stringAvatar } from "../../utils/functions";
import { getApiUrl } from "../../utils/urls";
import { MessageDialog } from "./message-dialog";

export const ProcessMail = (props) => {

    const id = props.match.params.id;
    const redirect = useRedirect()
    const user = cookiesProvider.getUser()
    const institution = storageProvider.getCurrentUser()?.institution
    const apiUrl = getApiUrl();

    const [mail, setMail] = useState([]);
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [author, setAuthor] = useState(undefined)
    const [reload, setReload] = useState(false)
    const [process, setProcess] = useState('')

    useEffect(() => {
        const fetchMail = async () => {
            setLoading(true)
            const res = await dataProvider.getMail(id)
            setMail(res.data.data)
            setLoading(false)
        }
        fetchMail()
    }, [reload, id])
    useEffect(() => {
        const fetchUser = async () => {
            const res = await dataProvider.getOne('users', { id: user })
            const res2 = await dataProvider.getOneSimple('processes', { id: id })
            setAuthor(res.data)
            setProcess(res2.data)
        }
        fetchUser()
    }, [user, id])

    const handleMessageDialogOpen = () => {
        setOpen(true)
    }

    const handleMessageDialogClose = () => {
        setOpen(false)
    }

    const handleSubmit = async (data) => {

        let uploadedFiles = []

        if (data.attachments && data.attachments.length > 0) {
            let fd = new FormData()
            fd.append("resource", 'process-mail');
            fd.append("slug", institution.slug)
            for (const file of data.attachments) {
                fd.append('files', file)
            }
            const res = await axios.post(`${apiUrl}/attachments/uploadExternal`, fd);
            if (res.status === 201 && res.data) {
                uploadedFiles = { files: res.data, resource: 'process-mail', id: id }
            }
            data.files = uploadedFiles.files
        }

        data.process = id;
        const params = { data }

        dataProvider.create('process-mail', params).then(
            () => {
                setTimeout(() => {
                    setOpen(false)
                    setReload(!reload)
                }, 2000)

            })
    }

    return (
        loading ? <CircularProgress /> :
            <>
                <Title title={`Correio do atendimento`} />
                {/*<Typography style={{ margin: '16px 8px 8px 16px' }}>Correio do atendimento</Typography>
                <Divider /> */}
                <Box paddingTop={'8px'} marginLeft={'16px'}>
                    <Button variant="contained" color="default" size="small" startIcon={<Visibility />} onClick={() => redirect(`/process-dashboard/${id}`)}> Dashboard </Button>
                    <span style={{ marginLeft: '8px' }} />
                    <Button variant="contained" color="primary" size="small" startIcon={!author ? <CircularProgress size={20} /> : <Add />} onClick={handleMessageDialogOpen} disabled={!author}> Nova mensagem </Button>
                    <MessageDialog author={author} alias={process.alias} open={open} onClose={handleMessageDialogClose} onSubmit={handleSubmit} />
                </Box>
                <List>
                    {
                        mail && mail?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(
                            message => (
                                <ListItem>
                                    <Card style={{ width: '100%' }} variant={message.client ? 'outlined' : 'elevation'} id={message.id}>
                                        <CardHeader
                                            avatar={
                                                <Avatar variant={message.client ? 'circle' : 'rounded'} {...stringAvatar(message.author?.name || message.client || 'Juristta Office')} />
                                            }
                                            title={message.author?.name || (message.client && `${message.client} (Cliente)`) || 'Juristta Office'}
                                            subheader={moment(message.created_at).format('LLL')}
                                            action={<Sidemenu message={message} />} />
                                        <Divider />
                                        <CardContent>
                                            {
                                                message.client && <>
                                                    <Typography color={'primary'}> Dados para contato </Typography>
                                                    <Typography> <Typography component={'span'} color={'primary'}>Email:</Typography> {message.email} </Typography>
                                                    <Typography> <Typography component={'span'} color={'primary'}>Telefone:</Typography> {message.phone} </Typography>
                                                    <div style={{ marginBottom: '16px' }} />
                                                </>
                                            }
                                            {message.client && <Typography color={'primary'}> Mensagem </Typography>}
                                            <Typography>{message.body}</Typography>
                                            <List dense={true}>
                                                {
                                                    message.attachments && message.attachments?.length > 0 && message.attachments.map(
                                                        attachment => (
                                                            <ListItem dense={true}>
                                                                <Link variant="body1" target="_blank" href={attachment.path}> <Attachment /> {attachment.name} </Link>
                                                            </ListItem>
                                                        )
                                                    )
                                                }
                                            </List>
                                        </CardContent>
                                    </Card>
                                </ListItem>
                            )
                        )
                    }
                </List>
            </>
    )
}

const Sidemenu = ({ message, ...props }) => {


    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDialogClose = () => {
        setOpen(false)
    }

    const handleClick = () => {
        setOpen(true)
    }

    const handleSubmit = () => {
        dataProvider.sendFilesToProcess(message.id).then(() => {
            setAlertOpen(true)
            setOpen(false)
            setAnchorEl(null)

        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') { return }
        setAlertOpen(false)
    }

    return (
        <>
            <IconButton onClick={handleOpen}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disabled={message.attachments?.length === 0} onClick={handleClick}> Apropriar anexos ao atendimento </MenuItem>
                <Dialog
                    open={open}
                    onClose={handleDialogClose}
                >
                    <DialogTitle id="dialog-title">{"Enviar arquivos ao atendimento?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-description">
                            Deseja enviar os arquivos a seguir ao atendimento relacionado?
                            Esta ação só pode ser desfeita por um gestor do atendimento.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleSubmit} color="primary" autoFocus>
                            Enviar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Menu>
            <Snackbar open={alertOpen} onClose={handleAlertClose} autoHideDuration={3500} >
                <Alert severity="success" onClose={handleAlertClose}>Arquivos enviados com sucesso!</Alert>
            </Snackbar>
        </>
    )
}