import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
    ArrayInput,
    BooleanInput,
    Button,
    CheckboxGroupInput,
    Create,
    FormTab,
    ReferenceInput, SelectInput, SimpleFormIterator,
    TabbedForm, TextInput, Title, required, useNotify, useRedirect, useRefresh
} from "react-admin";
import { VideoUrlInput } from "../../components/video-url-input";
import { videoIdExtractorTransform } from "../../utils/video-id-extractor-transform";
import { Menu } from "@material-ui/icons";

const SchoolModelActivityTaskCreate = (props) => {

    const state = props.history.location.state ? props.history.location.state : { data: null, redirectTo: null }

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(`Movimentação criada com sucesso`);
        redirect(state.redirectTo !== null ? state.redirectTo : `/model_activities/${data.model_activity.id}/activities`);
        refresh();
    };

    return (
        <Create title={<Title title={'Nova Etapa Matriz'} />} onSuccess={onSuccess} transform={videoIdExtractorTransform}
            actions={<Button style={{ marginBottom: '16px' }} label={'Dashboard'} variant="contained" startIcon={<Menu />} onClick={() => redirect(`/model-activity-dashboard/${state.data.id}`)} />}
            {...props}>
            <TabbedForm warnWhenUnsavedChanges syncWithLocation={false}>
                <FormTab label="Dados Principais">
                    <TextInput source="model_activity.id" initialValue={state.data.id} style={{ display: 'none' }} fullWidth disabled />
                    <TextInput source="model_activity.name" initialValue={state.data.name} label={'Atividade matriz'} fullWidth disabled />
                    <TextInput source="name" label="Título" variant="filled" fullWidth validate={[required()]} />
                    <CheckboxGroupInput
                        source="roles"
                        label="Partes que podem ver esta etapa"
                        variant="filled"
                        choices={state.data.party_types.map(item => ({ ...item, id: item.id.toString() }))}
                        optionText="name"
                        optionValue={'id'}
                        fullWidth
                        parse={value =>
                            value && value.map(v => ({ id: v }))
                        }
                        format={value => value && value.map(v => v.id)}
                        validate={[required()]}
                    />
                    <ReferenceInput source="guideline_type" reference="guideline-types" label="Orientação padrão" fullWidth>
                        <SelectInput source="name" optionText={"name"} fullWidth />
                    </ReferenceInput>

                    <BooleanInput source="video" label="Audiência de vídeo" />
                    <BooleanInput source="metaverse" label="Audiência no metaverso" />
                    <BooleanInput source="requires_tpu" label="Requer código TPU" />
                    <BooleanInput defaultValue={true} source="requires_grade" label="Requer correção" />

                    <ArrayInput
                        source="checklist"
                        label="Checklist de correção"
                        variant="filled"
                    >
                        <SimpleFormIterator>
                            <TextInput source="description" label="Descrição" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Instruções" path="details">
                    <RichTextInput
                        source="details"
                        label="Descrição"
                        validate={[required()]}
                    // toolbar={quillConfig}
                    />
                    <ArrayInput source="links" label="Vídeos" variant="standard">
                        <SimpleFormIterator>
                            <TextInput
                                source="title"
                                label="Título"
                                fullWidth
                            />
                            <VideoUrlInput source="url" label="URL" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Espelho de correção" path="guidelines">
                    <RichTextInput source="ai_guidelines" label="Espelho de correção da movimentação" />
                </FormTab>
            </TabbedForm>
        </Create>
    );
}

export default SchoolModelActivityTaskCreate 