import { School } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import ClassCreate from "./class-create";
import ClassEdit from "./class-edit";
import ClassList from "./class-list";

export const classResource = {
    name: ApplicationResource.CLASSES,
    icon: School,
    options: { label: "Turmas" },
    tableName: 'class',
    list: ClassList,
    edit: ClassEdit,
    create: ClassCreate,
    link: '/classes',
    products: ['school', 'office']
};