import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";
import { storageProvider } from "../../providers/storage-provider";

const ActivityTypeList = (props) => {

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin

    return (
        <List exporter={false} bulkActionButtons={false}
            title={'Tipos de movimentação'}
            sort={{ field: 'name', order: 'ASC' }}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label={'Nome'} />
                {superAdmin && <TextField source="institution.name" label={'Instituição'} />}
                <DateField source="created_at" label={'Criado em'} />
            </Datagrid>
        </List>
    )
}


export default ActivityTypeList;