import { Typography } from "@material-ui/core"
import { Link } from "react-admin"
import { Code, CopyBlock, dracula } from "react-code-blocks"

export const Introduction = () => {

    const text1 = `GET /v1/{endpoint}
Host: api.app.juristta.com
Authorization: Bearer v1_a2f5i10a_abc123def456ghi789jk`

    return <>
        <Typography variant='h6'>Introdução</Typography>
        <div style={{ marginBottom: '16px' }} />

        <Typography variant='body1'>A API de integração Juristta está hospedada no endereço <Link href="#">api.app.juristta.com/v1</Link></Typography>

        <Typography variant='body1'>Para garantir a segurança e o controle de acesso, todas as requisições à API exigem autenticação através de um token de autenticação no formato Bearer.</Typography>
        <Typography variant='body1'>Esse token é fornecido ao cliente no momento do registro ou solicitação de acesso à API.</Typography>

        <Typography variant='body1'>Cada requisição à API deve incluir um header HTTP chamado Authorization, onde o valor deve seguir o formato:</Typography>

        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock text={"Authorization: Bearer {chave_api}"} language="javascript" theme={dracula} codeBlock showLineNumbers />
        </Typography>

        <Typography variant="body1"> O parâmetro {'{ '}chave_api{' }'} é o token de autenticação exclusivo fornecido para o cliente.
            Ele deve ser mantido em segredo e nunca compartilhado publicamente ou exposto em código-fonte público.</Typography>
        <div style={{ marginBottom: '16px' }} />
        <Typography variant="h6">Exemplo de Requisição Autenticada</Typography>
        <Typography variant="body1">Aqui está um exemplo de como deve ser configurado o header para uma requisição autenticada:</Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text1} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>
        <Typography variant="body1">Requisições que não incluírem o header Authorization corretamente formatado ou que utilizarem um token inválido
            serão rejeitadas com uma resposta de erro, geralmente com o status <Code language='http' text="HTTP 401 Unauthorized." theme={dracula} ></Code> </Typography>
    </>

}