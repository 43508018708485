import { People } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import ClientCreate from "./client-create";
import ClientEdit from "./client-edit";
import ClientList from "./client-list";

export const clientResource = {
    name: ApplicationResource.CLIENTS,
    icon: People,
    options: { label: "Clientes" },
    link: "/clients",
    tableName: 'client',
    list: ClientList,
    edit: ClientEdit,
    create: ClientCreate,
    products:['office']
};