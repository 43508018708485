import { Class } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import { ThemeCreate } from "./theme-create";
import { ThemeEdit } from "./theme-edit";
import { ThemeList } from "./theme-list";

export const themeResource = {
    name: ApplicationResource.THEMES,
    icon: Class,
    options: { label: "Ramos do direito" },
    tableName: 'theme',
    list: ThemeList,
    edit: ThemeEdit,
    create: ThemeCreate,
    products: ['school', 'office'],
    link: '/themes',
};