import {
    IconButton, Link, List, ListItem, ListItemText, ListSubheader, Tooltip, Typography
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import { useRedirect } from 'react-admin'
import ExpandableCard from '../../components/expandable-card'

export const ProcessHearings = ({ process, isStudent, ...props }) => {

    const EditActivityButton = ({ activity, ...props }) => {
        const redirect = useRedirect();
        const handleClick = () => {
            redirect(`/activities/${activity.id}`)
        }
        return <Tooltip title={'Editar a movimentação'}><IconButton color={'text'} onClick={handleClick}><Edit /></IconButton></Tooltip>
    }

    const hearings = process.activities?.filter(activity => activity.type.is_hearing)

    return (
        <ExpandableCard
            realce={true}
            title={`Audiências`}
            mini={hearings.length < 1}
        >
            <List component="nav" dense={true}>
                {
                    hearings.map(
                        (activity) => (
                            activity && <ExpandableCard
                                title={activity.type.name}
                                action={
                                    <>
                                        {!isStudent && <EditActivityButton activity={activity} />}
                                    </>
                                }>

                                {
                                    activity.type.is_hearing && <>
                                        <Typography component={'div'}>
                                            Data da audiência: <Typography color='textSecondary' component={'span'}>
                                                {activity.conference_date ? moment(activity.conference_date).format('LLL') : 'A definir'}
                                            </Typography>
                                        </Typography>
                                        <Typography component={'div'}>
                                            Tipo de audiência: <Typography color='textSecondary' component={'span'}>
                                                {activity.hearing_type === 'p' ? 'Presencial' : activity.hearing_type === 'v' ? 'Virtual' : 'A definir'}
                                            </Typography>
                                        </Typography>
                                    </>
                                }

                                {
                                    activity.attachments.length > 0 &&
                                    <List dense subheader={<ListSubheader disableSticky>Anexos</ListSubheader>}>
                                        {
                                            activity.attachments?.map((attachment, index) => {
                                                return <ListItem dense key={index}>
                                                    <ListItemText><Link target={'_blank'} rel={'noopener'} href={attachment.path}>{attachment.name}</Link></ListItemText>
                                                </ListItem>
                                            })
                                        }
                                    </List>
                                }

                            </ExpandableCard>
                        )
                    )
                }
            </List >

        </ExpandableCard >
    )
}
