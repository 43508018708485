import { Typography } from "@material-ui/core"
import { Code, CodeBlock, CopyBlock, dracula } from "react-code-blocks"

export const ConsultaTurmas = () => {

    const text = `GET /v1/consulta-turmas?periodo-letivo={id_periodo_letivo}
Host: api.app.juristta.com
Authorization: Bearer v1_a2f5i10a_abc123def456ghi789jk`

    const text2 = `[
    {
        "id": "2ce636c6-63a6-409b-aa1a-ed94973b0cb6",
        "name": "Turma Teste 1",
        "description": "Turma Teste 1",
        "active": true,
    },
    {
        "id": "586fb41d-d74c-465c-90c3-f3be597887b9",
        "name": "Turma Teste 2",
        "description": "Turma Teste 2",
        "active": true,
    },
]
`

    return <>
        <Typography variant="h6">Consulta de turmas</Typography>
        <div style={{ marginBottom: '16px' }} />

        <Typography variant='body1'>Este endpoint permite consultar informações detalhadas de todas as turmas da instituição, como o id associado no sistema.</Typography>
        <Typography variant='body1'>Opcionalmente pode se incluir na query da requisição um período letivo para que o resultado seja filtrado.</Typography>
        <div style={{ marginBottom: '16px' }} />

        <div style={{ marginBlock: '16px', borderRadius: '8px', backgroundColor: '#1f1f1f', padding: '8px' }} >
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>Método:</Typography> <Typography component='span'>GET</Typography>
            </div>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>URL:</Typography> <Typography component='span'>https://api.app.juristta.com/v1/consulta-turmas </Typography>
            </div>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>Query (Opcional): </Typography> <Typography component='span'>periodo-letivo={'{'}id_periodo_letivo{'}'}</Typography>
            </div>

        </div>

        <Typography variant="h6">Exemplo de Requisição </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>
        <Typography variant="body1"> O parâmetro opcional {'{ '}id_periodo_letivo{' }'} é o id do período letivo que se deseja filtrar. </Typography>

        <div style={{ marginBottom: '16px' }} />

        <Typography variant="h6">Exemplo de Resposta </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CodeBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text2} language="json" theme={dracula} />
        </Typography>
        <Typography variant="body1">Requisições que não seguirem corretamente a especificação receberão uma resposta com o status <Code language='http' text="HTTP 400 Bad Request." theme={dracula} ></Code> com detalhes do problema. </Typography>
    </>
}