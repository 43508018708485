import RichTextInput from 'ra-input-rich-text';
import React from "react";
import {
    Datagrid,
    DeleteButton,
    Edit, FormDataConsumer, FormTab, ReferenceManyField,
    SaveButton,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar
} from "react-admin";
import { useForm } from 'react-final-form';
import AddNewAttachmentButton from "../../components/add-new-attachment-button";
import MaskedInput from "../../components/masked-input";
import ServiceRequestCustomActions from "./service-request-custom-actions";

import { ViewFileButton } from '../../components/view-file-button';

const choices = [{ id: "IN_PERSON", name: "Presencial" }, { id: "ONLINE", name: "Online" }, { id: "BY_PHONE", name: "Por telefone" }, { id: "SYSTEM", name: "Automação", disabled: true }]
const status = [{ id: "PENDING", name: "Pendente" }, { id: "ACCEPTED", name: "Aceito" }, { id: "REJECTED", name: "Rejeitado" }]

const ServiceRequestEdit = (props) => {

    const ServiceRequestCustomToolbar = (props) => {
        const form = useForm()
        const values = form.getState().values;

        return <Toolbar {...props}>
            <SaveButton disabled={props.pristine} />
            {
                values.status === 'Aceito' && values.process === null && (
                    <SaveButton
                        label='Salvar com atendimento'
                        variant='contained'
                        disabled={values.status !== 'Aceito' && values.process !== null}
                        transform={data => ({ ...data, generateProcess: true })}
                        style={{ marginLeft: '1em' }}
                    />
                )
            }
        </Toolbar>
    }

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            actions={<ServiceRequestCustomActions />}
            title={<CustomTitle />}
        >
            <TabbedForm toolbar={<ServiceRequestCustomToolbar />}>
                <FormTab label={'Cliente'}>
                    <TextInput source="client.id" style={{ display: 'none' }} type="hidden" hidden={true} disabled />
                    <TextInput source="client.name" label={'Nome'} fullWidth disabled />
                    <TextInput source="client.email" label={'Email'} fullWidth disabled />
                    <MaskedInput source="client.phone" onlyNumbers={true} mask={'(99) 99999-9999'} label={'Telefone'} fullWidth disabled />
                </FormTab>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="alias" label={'Protocolo'} fullWidth disabled />
                    <RichTextInput source="description" label={'Breve relato'} fullWidth />
                    <SelectInput source="origin" optionValue={"name"} optionText={"name"} label={'Origem'} disabled choices={choices} fullWidth />
                </FormTab>

                <FormTab label={'Anexos'} path="attachments" >
                    <ReferenceManyField fullWidth reference='attachments' target='serviceRequestId' label={'Anexos'}>
                        <Datagrid>
                            <TextField source={'name'} label={'Nome'} />
                            <ViewFileButton />
                            <DeleteButton undoable={false} redirect={false} />
                        </Datagrid>
                    </ReferenceManyField>
                    <AddNewAttachmentButton multiple={true} small={true} />
                </FormTab>
                <FormTab label={'Triagem'} path="triage">
                    <FormDataConsumer>
                        {({ formData, ...rest }) => <>
                            <SelectInput source="status" optionValue={"name"} optionText={"name"} label={'Status'} choices={status} fullWidth disabled={formData.process !== null} />
                            {formData.status !== "Pendente" && <RichTextInput label={'Observações'} source="observation" fullWidth configureQuill={(quill) => quill.enable(!formData.process)} />}
                        </>
                        }
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit >
    )
}

const CustomTitle = ({ record }) => {
    return <span> {`Pedido de atendimento ${record.alias}`} </span>
}

export default ServiceRequestEdit;