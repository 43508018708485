import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Tab,
    Tabs,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core'
import { AddCircle, AttachFile, Edit } from '@material-ui/icons'
import 'moment/locale/pt-br'
import React, { useState } from 'react'
import { useRedirect } from 'react-admin'
import AddNewAttachmentButton from '../../components/add-new-attachment-button'
import ExpandableCard from '../../components/expandable-card'
import { ListAttachments } from '../../components/list-attachments/list-attachments.component'
import { Spacer } from '../../components/spacer'
import TabPanel from '../../components/tab-panel'
import { orderByObjectName, stringAvatar } from '../../utils/functions'

const useStyles = makeStyles(theme => ({
    teamCard: {
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        flexBasis: '30%',
        marginInline: '4px'
    },
    teamPanelContainer: {

    },
    teamTitle: {
        paddingInline: '16px',
        paddingBlock: '4px',
        fontSize: '1.2em',
        borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
    },
    tabPanelRoot: {
        paddingInline: '8px',
        display: 'flex',
    },

    tabSelected: {
        minWidth: '0px',
        border: '1px solid #C64134',
        borderRadius: '8px 0px 0px 8px',
        backgroundColor: '#C64134',
    },

    link: {
        '& a:link': { color: '#ffffff' },
        '& a:active': { color: '#f0f0f0' },
        '& a:visited': { color: '#c0c0c0' },
        '& a:hover': { color: '#e1e1e1' },

    },

}))

const TeamPanel = ({ roles, team, value, idx, school_activity }) => {
    const classes = useStyles();
    const team_members = team.team_memberships;

    const [open, setOpen] = useState(false)
    const [teamState,] = useState(team)
    const [attachments, setAttachments] = useState(team.attachments)

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    return (
        <TabPanel className={classes.tabPanelRoot} label={teamState.name} index={idx} value={value}>
            <Grid container item>
                <Spacer />
                <AddNewAttachmentButton
                    record={teamState}
                    multiple
                    resource={'team'}
                    label={`Anexar autos do ${teamState.name}`}
                    redirectTo={`/school-activity-dashboard/${school_activity.id}`}
                    fileSize={25000000}
                />
                <Button style={{ marginInline: '4px' }} onClick={() => handleOpen()} variant='contained' size='small' startIcon={<AttachFile />}>Autos do {teamState.name}</Button>
                <Dialog open={open} onClose={handleClose} scroll={"paper"} fullWidth maxWidth={'sm'}>
                    <DialogTitle>
                        Autos do {teamState.name}
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {
                            attachments.length > 0 &&
                            <List dense subheader={<ListSubheader disableSticky>Anexos</ListSubheader>}>
                                <ListAttachments attachments={attachments} setAttachments={setAttachments} enableDelete={true} relatedResource={'teams'} relatedId={team.id} />
                            </List>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            <Grid container item className={classes.teamPanelContainer}>
                {
                    roles.map((role) => {
                        return (
                            <Grid item className={classes.teamCard}>
                                <Typography className={classes.teamTitle}>{role.name}</Typography>
                                <List dense>
                                    {team_members.filter(tm => tm.role.id === role.id).map((tm) => (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant={'circle'} {...stringAvatar(tm.student.name)} />
                                            </ListItemAvatar>
                                            <ListItemText primary={tm.student.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        )
                    })
                }
            </Grid >
        </TabPanel>
    )
}


export const SchoolActivityTeams = ({ school_activity, teams, ...props }) => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [filter, setFiter] = React.useState('');
    const redirect = useRedirect()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    teams = teams.sort(orderByObjectName)
    const displayTeams = teams.filter(team => team.team_memberships.filter(tm => tm.student.name.toLowerCase().includes(filter.toLowerCase())).length > 0)

    const roles = school_activity.party_types.sort(orderByObjectName)

    const SATTitle = () => <div style={{ display: 'flex', alignItems: 'center' }}>
        <img alt='equipes' height='40px' width='40px' src='https://game-juridico.s3.sa-east-1.amazonaws.com/assets/pictograma_EQUIPES.png'></img>
        <p style={{ margin: ' auto auto  auto 8px' }}>{`Processos (${teams.length})`}</p>
        <div style={{ flexGrow: 1 }}></div>
        {
            (teams && teams.length > 0) ?
                <IconButton onClick={() => redirect(`/manage-activity-teams/${school_activity.id}`)}>
                    <Edit />
                </IconButton>
                :
                <IconButton onClick={() => redirect(`/manage-activity-teams/${school_activity.id}/create`)}>
                    <AddCircle />
                </IconButton>
        }
    </div>

    return (
        <ExpandableCard
            realce={true}
            fullWidth
            mini={true}
            title={<SATTitle />}
            onlyTitle={!teams || teams.length === 0}
        >
            <Grid container style={{ maxHeight: '600px' }}>
                <Grid item>
                    <TextField
                        label={'Filtro'}
                        placeholder={'Nome'}
                        variant={'filled'}
                        value={filter}
                        onChange={(event) => setFiter(event.target.value)}
                    />
                </Grid>
                <Grid container item spacing={2}>
                    <Grid xs={2} item>
                        <Tabs style={{ maxHeight: '300px', borderRight: '1px solid rgba(255, 255, 255, 0.25)', }} onChange={handleChange} value={value} orientation='vertical' variant='scrollable' scrollButtons='on' >
                            {displayTeams && displayTeams.map(
                                (team, idx) => (
                                    <Tab classes={{ selected: classes.tabSelected }} label={team?.name} value={idx} />
                                ))}
                        </Tabs>
                    </Grid>
                    <Grid xs={9} item>
                        {displayTeams && displayTeams.map((team, idx) => <TeamPanel roles={roles} team={team} idx={idx} value={value} school_activity={school_activity} />)}
                    </Grid>
                </Grid>
            </Grid >
        </ExpandableCard >
    )
}