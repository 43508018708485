import Button from "@material-ui/core/Button";
import RichTextInput from 'ra-input-rich-text';
import React, { useMemo } from "react";
import {
  Datagrid,
  Edit,
  FormTab,
  FunctionField,
  ListContextProvider,
  TabbedForm,
  TextField,
  TextInput,
  useGetOne,
  useList,
  useMutation
} from "react-admin";

const ProfileEdit = (props) => {
  const { data, loaded, loading, refetch } = useGetOne('permissions', props.id);

  const CustomTitle = ({ record }) => {
    return <span>{`${record.name}`}</span>
  }

  return (
    <Edit
      mutationMode="pessimistic"
      {...props}
      title={<CustomTitle />}
    >
      <TabbedForm>
        <FormTab label={'Dados Gerais'}>
          <TextInput source="name" label={'Nome'} fullWidth />
          <RichTextInput source="description" label={'Descrição'} fullWidth />
        </FormTab>

        <FormTab label={'Permissões'} >
          {
            loaded && !loading && (
              <ListPermissions
                data={data.permissions}
                profileId={props.id}
                reload={refetch}
              />
            )
          }
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

/**
 *
 * @param {{
 *   data: any[],
 *   profileId: string,
 *   reload
 * }} props
 * @constructor
 */
export function ListPermissions(props) {
  const { data, profileId } = props;
  const permissionContextProvider = useList({
    data: Object.values(data),
    ids: data.map(w => w.id),
    basePath: '/permissions',
    resource: 'permissions',
  })
  const [mutate, ] = useMutation();

  const togglePermission = data => mutate({
    type: 'create',
    resource: 'permissions',
    payload: {
      data
    }
  }, {
    onSuccess: (e) => {
      props.reload()
    }
  });

  const toggleAllPermissions = () => {
    togglePermission({
      read: !allEnabled,
      write: !allEnabled,
      resourceNames: props.data.map(w => w.resourceName),
      profileId,
    })
  }

  const allEnabled = useMemo(() => {
    for (const item of props.data) {
      if (!item.read || !item.write) {
        return false
      }
      return true;
    }
  }, [props])

  return (
    <ListContextProvider value={permissionContextProvider} >
      <div style={{ textAlign: 'right' }}>
        <Button size={'small'} onClick={toggleAllPermissions} >
          {allEnabled ? 'Desabilitar' : 'Habilitar'} Todos Recursos
        </Button>
      </div>
      <Datagrid>
        <TextField source="resourceDescription" label={'Recurso'} />
        <FunctionField
          source="read"
          label={'Leitura'}
          render={(record, a) => (
            <input
              type={"checkbox"}
              checked={record.read}
              onChange={() => togglePermission({
                ...record,
                read: !record.read,
                resourceNames: [record.resourceName],
                profileId,
              })}
            />)}
        />
        <FunctionField
          source="write"
          label={'Escrita'}
          render={(record, a) => (
            <input
              type={"checkbox"}
              checked={record.write}
              onChange={() => togglePermission({
                ...record,
                write: !record.write,
                resourceNames: [record.resourceName],
                profileId,
              })}
            />)}
        />
      </Datagrid>
    </ListContextProvider>
  )
}

export default ProfileEdit;