import { Card, CardHeader, Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from 'react';
import { intersection, not, union } from "./utils";

export const CustomList = ({ title, items, checked, setChecked, styles }) => {

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };


    return (
        <Card variant='outlined'>
            <CardHeader
                className={styles.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items?.length && items?.length !== 0}
                        indeterminate={numberOfChecked(items) !== items?.length && numberOfChecked(items) !== 0}
                        disabled={items?.length === 0}
                        inputProps={{ 'aria-label': 'all items selectedUsers' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items?.length} selecionados`}
            />
            <Divider />
            <List className={styles.list} component="div" role="list">
                {items?.map((value) => {
                    const labelId = `transfer-list-all-item-${value.id}-label`;

                    return (
                        <ListItem key={`${value.id}-${value.class_id}`} className={styles.list_item} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText className={styles.list_item_text} id={labelId} primary={`${value.name}`} secondary={`${value.class?.name || value.class_name || value.email || ''}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    )
}