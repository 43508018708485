import React from "react";
import { Button, useRedirect } from "react-admin";

const AddNewActivityButton = ({ record }) => {
    const redirect = useRedirect();
    return (
        <Button
            onClick={() => { redirect('create', '/activities', {}, {}, { redirect: `/process/${record.id}/activities`, process: record }) }}
            label="Adicionar movimentação"
            variant="contained"
        >
        </Button>
    )
}

export default AddNewActivityButton;