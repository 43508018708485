import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import UserTransferList from "../../components/user-transfer-list/user-transfer-list.component";
import dataProvider from "../../providers/data-provider";
import { AppContext } from "../../providers/app-context-provider/app.context";

const ClassEditAddUser = ({ record, resource, refresh, path, title, ...props }) => {

    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState([]);

    const context = useContext(AppContext)

    useEffect(() => {
        const getData = async () => {
            const response = await dataProvider.get('student', `fromProduct/${context.product.id}`)
            setUsers(response.data)
        }
        getData();
    }, [context.product.id]);

    const handleSubmit = () => dataProvider.create(`${resource}/${record.id}/${path}`, { data: { users: selected } })
        .then(() => { refresh(); setTimeout(() => { }, 2000) })
        .finally(() => { setOpen(false); })

    const handleClose = () => {
        setOpen(false)
        setSelected([])
    }

    const filterUsers = (data) => {
        return data.filter(item => !record.users?.find(i => i.id === item.id))
    };

    return (
        <div style={{ width: '100%', textAlign: 'left' }}>
            <Dialog maxWidth={"lg"} fullWidth open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent dividers>
                    <UserTransferList
                        data={filterUsers(users)}
                        selected={selected}
                        setSelected={setSelected}
                    />
                    <DialogActions>
                        <Button onClick={handleClose} size={'medium'} variant={'contained'} color={'default'}> Cancelar </Button>
                        <span style={{ flexGrow: 1 }} />
                        <Button
                            onClick={handleSubmit}
                            size={'medium'}
                            variant={'contained'}
                            color={'primary'}
                            disabled={selected.length === 0}
                        >
                            Confirmar
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Button onClick={() => setOpen(true)} size={'small'} variant={'contained'} color={'primary'}>
                Adicionar usuários
            </Button>
        </div>
    )
};

export default ClassEditAddUser;
