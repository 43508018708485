import { PostAdd } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import ServiceRequestCreate from "./serviceRequests-create";
import ServiceRequestEdit from "./serviceRequests-edit";
import ServiceRequestList from "./serviceRequests-list";

export const serviceRequestResource = {
    name: ApplicationResource.SERVICE_REQUESTS,
    icon: PostAdd,
    options: { label: "Pedidos de atendimento" },
    link: "/service-requests",
    tableName: 'service-request',
    list: ServiceRequestList,
    edit: ServiceRequestEdit,
    create: ServiceRequestCreate,
    products:['office']
}