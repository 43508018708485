import { Add, Visibility } from "@material-ui/icons";
import React, { useState } from "react";
import { Button, ListButton, TopToolbar, useNotify, useRedirect } from "react-admin";
import dataProvider from "../../providers/data-provider";
import { storageProvider } from "../../providers/storage-provider";

const ServiceRequestCustomActions = ({ data }) => {
    const [disabled, setDisabled] = useState(false)

    const notify = useNotify();
    const redirect = useRedirect();
    const profile = storageProvider.getCurrentUser()?.profiles
    const superAdmin = storageProvider.getCurrentUser()?.superAdmin
    const shouldShow = superAdmin || profile[0].name !== 'Atendimento'

    return (
        <TopToolbar>
            <ListButton label='Retornar' />
            <span style={{ flexGrow: 1 }} />
            {shouldShow && (data?.status === "Aceito" && !data?.process) &&
                <Button
                    color={'primary'}
                    disabled={disabled}
                    onClick={() => {
                        setDisabled(true)
                        dataProvider.create('processes', {
                            data: {
                                alias: data.alias,
                                institution: data.institution.id,
                                client: data.client.id,
                                serviceRequest: data.id,
                            }
                        }).then(() => {
                            notify('Atendimento criado com sucesso!');
                            redirect('list', '/service-requests')
                        })
                    }}
                    label='Gerar atendimento'>
                    <Add />
                </Button>
            }
            {shouldShow && data?.process &&
                <Button
                    color={'primary'}

                    onClick={() => {
                        redirect(`/processes/${data.process.id}`)
                    }}
                    label='Visualizar atendimento'>
                    <Visibility />
                </Button>
            }
        </TopToolbar >
    )
}

export default ServiceRequestCustomActions;