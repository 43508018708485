import Cookies from "js-cookie"

const userKey = '__3358791__';
const tokenKey = '__9784411__';
const themeKey = '__7763122__';
const tutorialModalKey = '__938190382__'

const cookiesProvider = {
    setUser: (user) => {
        Cookies.set(userKey, user)
    },
    getUser: () => {
        return Cookies.get(userKey);
    },
    removeUser: () => {
        Cookies.remove(userKey)
    },

    setToken: (token) => {
        Cookies.set(tokenKey, token)
    },
    getToken: () => {
        return Cookies.get(tokenKey);
    },
    removeToken: () => {
        Cookies.remove(tokenKey)
    },
    setPreferredTheme: (theme) => {
        Cookies.set(themeKey, theme)
    },
    getPreferredTheme: () => {
        return Cookies.get(themeKey)
    },
    removePreferredTheme: () => {
        Cookies.remove(themeKey)
    },
    setTutorialModal: (theme) => {
        Cookies.set(tutorialModalKey, theme)
    },
    getTutorialModal: () => {
        return Cookies.get(tutorialModalKey)
    },
    removeTutorialModal: () => {
        Cookies.remove(tutorialModalKey)
    }

}

export default cookiesProvider;