import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";

export const ThemeList = (props) =>
    <List exporter={false} bulkActionButtons={false}
        title={'Ramos do direito'}
        sort={{ field: 'name', order: "ASC" }}
        {...props}>
        <Datagrid rowClick={'edit'}>
            <TextField source="name" label={'Nome'} />
            <DateField source="created_at" label={'Criado em'} />
        </Datagrid>
    </List>