import jsonExport from 'jsonexport/dist';
import moment from "moment";
import React from "react";
import { ArrayField, AutocompleteInput, BooleanField, BooleanInput, CreateButton, Datagrid, ExportButton, FilterButton, List, ReferenceInput, SelectInput, SingleFieldList, TextField, TextInput, TopToolbar, downloadCSV, sanitizeListRestProps, useListContext } from "react-admin";
import UserImportButton from "../../components/user-import-button/user-import-button";
import { storageProvider } from "../../providers/storage-provider";

const UserFilters = [
    <TextInput label='Nome' source='name' alwaysOn />,
    <TextInput label='Email' source='email' />,
    <BooleanInput label='Ativo' source='active' />,
    <SelectInput label='Perfil' source='profile' optionText={"id"} choices={[{ id: "Aluno" }, { id: "Administrador" }, { id: "Advogado" }, { id: "Coordenador" }, { id: "Professor" }, { id: "Secretaria" }]} />,

]

const superAdminFilters = [
    <ReferenceInput label="Instituição" source="institution" reference="institutions" sort={{ field: "name", order: "ASC" }} >
        <AutocompleteInput
            source="name"
            emptyText="Todos"
            fullWidth
        />
    </ReferenceInput>
]
const exporter = data => {
    const toExport = data.map(item => {
        return {
            name: item.name,
            email: item.email,
            active: item.active ? "Ativo" : "Inativo",
            profiles: item.profiles.map(i => i.name).join(', '),
            products: item.products.map(j => j.name).join(', '),
            created_at: moment(item.created_at).format('DD/MM/YYYY')
        }
    })
    jsonExport(toExport, {
        headers: ['name', 'email', 'active', 'profiles', 'products', 'created_at'],
        rename: ['Nome', 'Email', 'Ativo', 'Perfil', 'Módulos', 'Criado em'],
    },
        (err, csv) => {
            downloadCSV(csv, `Exportar_Alunos_${moment().format('YYYY_MM_DD_HH_mm')}`)
        })
}

const UserListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        basePath,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />
            <CreateButton basePath={basePath} />
            <ExportButton />
            <UserImportButton label={'Importar usuários'} />
        </TopToolbar>
    );
}

const UserList = (props) => {

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin

    return (
        <List
            exporter={exporter}
            bulkActionButtons={false}
            title={'Usuários'}
            sort={{ field: 'name', order: 'ASC' }}
            filters={superAdmin ? [...UserFilters, ...superAdminFilters] : UserFilters}
            actions={<UserListActions />}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label={'Nome'} />
                <TextField source="email" label={'Email'} />
                <TextField source="phone" label={'Telefone'} />
                <BooleanField source="active" label={'Ativo'} />
                <ArrayField source="profiles" sortable={false} label={'Perfil'}>
                    <SingleFieldList linkType={false}>
                        <TextField source="name" />
                    </SingleFieldList>
                </ArrayField>
                {superAdmin && <ArrayField source="institutions" sortable={false} label={'Insitituição'}>
                    <SingleFieldList linkType={false}>
                        <TextField source="name" />
                    </SingleFieldList>
                </ArrayField>}
            </Datagrid>
        </List>
    )
}

export default UserList;