import { StyleSheet } from "@react-pdf/renderer";

export const reportStyles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontSize: 16,
        textAlign: 'center',
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 10,
        marginTop: 8,
        textAlign: 'center'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 12,
        width: '50%',
        marginTop: 8,
        marginLeft: 8,
        marginRight: 8
    },
    item: {
        padding: '8px',
        alignSelf: 'stretch',
        marginBottom: 10,
        fontSize: 12
    },
    note: {
        fontSize: 10,
        paddingTop: '5px',
        paddingBottom: '5px',
    }
});