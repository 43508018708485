import { AppBar, Button, CssBaseline, Divider, Grid, makeStyles, Paper, TextField, ThemeProvider, Toolbar, Typography } from "@material-ui/core";
import React, { useState } from "react";
import dataProvider from "../../providers/data-provider";
import { ExternalProcessMailTheme } from "./theme";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    instructions: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    },
    logo: {
        maxHeight: '48px',
        padding: '4px'
    },
    form: {
        marginTop: theme.spacing(1),
        justifyContent: "center",
    },
    formField: {
        marginTop: theme.spacing(2),
    },
}))

export const ExternalProcessMailCheck = ({ setStep, setProcess, ...props }) => {

    const classes = useStyles();
    const [value, setValue] = useState('')
    const [error, setError] = useState(undefined)
    const [loading, setLoading] = useState(false)

    const handleChange = (event) => {
        setValue(event.target.value)
    }

    const handleMaskedInput = (event) => {
        if (error) setError(undefined);
        var mask = event.target.value.replace(/\W/g, '').match(/(\d{0,4})(\d{0,2})(\d{0,2})(\w{0,8})/)
        event.target.value = (`${mask[1]}${mask[2] && '.'}${mask[2]}${mask[3] && '.'}${mask[3]}${mask[4] && '/'}${mask[4]}`)
    }

    const handleClick = () => {
        setLoading(true)
        dataProvider.getProcessSimpleByAlias({ alias: value.replace(/\W/g, '') })
            .then(res => {
                if (res.data.error) {
                    setError(res.data.message)
                    setLoading(false)
                } else if (res.data.id) {
                    setProcess(res.data)
                    setStep(1)
                }
            })
    }

    return (
        <ThemeProvider theme={ExternalProcessMailTheme}>
            <CssBaseline />
            {
                <div className={classes.root}>
                    <AppBar position="static">
                        <Toolbar variant="dense">
                            <img alt="Juristta Office" src="https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristtaoffice1.png" className={classes.logo} />
                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={3} className={classes.form} >
                        <Grid item xs={4}>
                            <Paper elevation={3} style={{ marginTop: '5em' }} className={classes.paper}>
                                <Typography> Solicitação online de retorno sobre o atendimento </Typography>
                                <Divider />
                                <div style={{ marginTop: '24px' }} />
                                <Typography> Insira abaixo o protocolo do atendimento  </Typography>
                                <TextField
                                    className={classes.formField}
                                    onBlur={handleChange}
                                    onInput={handleMaskedInput}
                                    helperText={"Ex.: 2023.01.01/ab12CD34"}
                                    id={'alias'}
                                    error={error}
                                    label={"Protocolo"}
                                    variant={'filled'}
                                    size={'small'} fullWidth
                                />
                                <Button color={"primary"} variant={'contained'} onClick={handleClick} disabled={loading}>Enviar</Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            }
        </ThemeProvider >
    )
}