import RichTextInput from 'ra-input-rich-text';
import React from "react";
import { AutocompleteInput, BooleanInput, Create, DateInput, FormDataConsumer, ReferenceInput, required, SimpleForm, TextInput, useNotify, useRedirect, useRefresh } from "react-admin";

const ActivityCreate = (props) => {

    const { process, redirectTo } = props.history.location.state ? props.history.location.state : { process: null, redirectTo: null }
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        notify(`Movimentação criada com sucesso`);
        redirect(redirectTo !== null ? redirectTo : `/processes/${data.process.id}/activities`);
        refresh();
    };

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm redirect={redirectTo}>
                <TextInput source="process.id" initialValue={process.id} style={{ display: 'none' }} fullWidth disabled />
                <TextInput source="process.alias" initialValue={process.alias} label={'Atendimento'} fullWidth disabled />
                <ReferenceInput source="type" reference="activity-types" sort={{ field: 'name', order: 'ASC' }} label={'Tipo de movimentação'} validate={[required()]} fullWidth>
                    <AutocompleteInput source='name' optionText={'name'} />
                </ReferenceInput>
                <DateInput source="start_at" label={'Data de início'} defaultValue={new Date()} validate={[required()]} fullWidth />
                <DateInput source="deadline" label={'Data de término para o aluno'} fullWidth validate={[required()]} />
                <DateInput source="effective_deadline" label={'Data de término para os gestores'} fullWidth validate={[required()]} />
                <FormDataConsumer>
                    {({ formData, record, ...rest }) => <BooleanInput source="requires_grade" defaultValue={true} label={formData?.requires_grade ? 'Requer correção' : 'Não requer correção'} />}
                </FormDataConsumer>
                <RichTextInput source="details" label={'Detalhes'} fullWidth />
            </SimpleForm>
        </Create>
    )
}
export default ActivityCreate;