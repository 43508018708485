import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import React from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import useStyles from "./expandable-card.styles";
import { Typography } from "@material-ui/core";
const ExpandableCard = ({
  content,
  children,
  title,
  action,
  realce,
  actionTextShow = "Mostrar Detalhes",
  actionTextHide = "Ocultar Detalhes",
  mini = false,
  onlyTitle = false,
  fullWidth = false,
  outlined = false,
  ...props
}) => {

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  const [minimized, setMinimized] = React.useState(mini || onlyTitle)

  const toggleMinimized = () => {
    if (minimized) {
      setMinimized(false)
    } else {
      setMinimized(true)
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={clsx(fullWidth && classes.fullWidth)} variant={outlined ? 'outlined' : 'elevation'} {...props}>
      <CardHeader
        action={action}
        title={<Typography style={{ fontSize: '1.2em' }} onClick={onlyTitle ? () => { } : toggleMinimized}>{title}</Typography>}
        className={clsx(minimized && classes.minimized, realce && !minimized && classes.realce)}
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      <Collapse in={!minimized}>
        <CardContent>
          {children}
        </CardContent>
        {content && (
          <>
            <CardActions className={classes.cardActions} disableSpacing={true}>
              <Button
                className={classes.button}
                onClick={handleExpandClick}
                endIcon={
                  <ExpandMoreIcon
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                  />
                }
              >
                {expanded ? actionTextHide : actionTextShow}
              </Button>
            </CardActions>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>{content}</CardContent>
            </Collapse>
          </>
        )}
      </Collapse>
    </Card>
  );
};

export default ExpandableCard;
