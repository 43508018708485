import { Button, Collapse, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Typography, makeStyles, useTheme } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { Link, useRedirect } from "react-admin";

import Image1 from './assets/1.png';
import Image11 from './assets/11.png';
import Image13 from './assets/13.png';
import Image3 from './assets/3.png';
import Image5 from './assets/5.png';
import Image7 from './assets/7.png';
import Image9 from './assets/9.png';

import WaveDark from './assets/wave_fundobranco.png';
import Wave from './assets/wave_telabrancalogin.png';

import LogoJuristtaBranca from './assets/JURISTTA_logobranca.png';
import LogoJuristtaPreta from './assets/JURISTTA_logpreta.png';

import LogoJuristtaOfficeBranca from './assets/jo_branco.png';
import LogoJuristtaOfficePreta from './assets/jo_preto.png';

import LogoJuristtaSchoolBranca from './assets/js_branco.png';
import LogoJuristtaSchoolPreta from './assets/js_preto.png';

import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ImageButton } from "../../components/image-button/image-button.component";
import authProvider from "../../providers/auth-provider";

import { Alert } from "@material-ui/lab";
import { random } from "lodash";
import { AppContext } from "../../providers/app-context-provider/app.context";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    position: 'absolute',
    height: '100%',
  },

  currentImage: {
    animation: '$fadeOut 750ms ease-out',
    animationDelay: '9250ms'
  },

  nextImage: {
    animation: '$fadeIn 750ms ease-in',
    animationDelay: '750ms'
  },

  "@keyframes fadeOut": {
    "0%": {
      opacity: 1
    },
    "50%": {
      opacity: 0.5
    },
    "100%": {
      opacity: 0
    }
  },

  "@keyframes fadeIn": {
    "0%": {
      opacity: 0
    },
    "50%": {
      opacity: 0.5
    },
    "100%": {
      opacity: 1
    }
  },

  not_active: {
    opacity: '0%',
    height: '0px',
  },
  paper: {
    paddingInline: theme.spacing(4),
    paddingBlock: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    backgroundImage: `url(${theme.palette.type === 'light' ? LogoJuristtaPreta : LogoJuristtaBranca})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '125px',
    width: '100%',
    marginTop: '32px'
  },
  waveBackground: {
    backgroundImage: theme.palette.type === 'light' ? `url(${Wave})` : `url(${WaveDark})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%'
  },
}));


const finishLogin = async (username, password, institution, product, context) => {
  return await authProvider.login({ username, password, institutionId: institution, productId: product })
    .then(() => window.location.assign('/'))
    .catch(err => { })
}


const MultiStepForm = ({ context, ...props }) => {

  const [step, setStep] = useState(0)
  const [institutions, setInstitutions] = useState(null)
  const [products, setProducts] = useState(null)
  const [user, setUser] = useState(null)

  switch (step) {
    default:
    case 0:
      return <CredentialsForm context={context} setStep={setStep} setInstitutions={setInstitutions} setProducts={setProducts} setUser={setUser} />
    case 1:
      return <InstitutionSelector context={context} user={user} institutions={institutions} setStep={setStep} setProducts={setProducts} setUser={setUser} />
    case 2:
      return <ProductSelector context={context} user={user} setStep={setStep} products={products} />
  }
}


const CredentialsForm = ({ setStep, setInstitutions, setProducts, setUser, context, ...props }) => {
  const classes = useStyles();
  const redirect = useRedirect()
  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center"> {'Copyright © '}
        <Link color="inherit" href="https://www.juristta.com.br/"> Juristta </Link>
        {' '} {new Date().getFullYear()} {'.'}
      </Typography>
    );
  }

  const onSubmit = (event) => {
    event.preventDefault();
    authProvider.getInstitutionsAndProducts({ username, password })
      .then(res => {
        const { name, institutions, products } = res.data
        if (institutions.length === 1 && products.length === 1) {
          finishLogin(username, password, institutions[0].id, products[0].id, context)
        } else {
          if (institutions.length === 1) {
            setUser({ name, username, password, institution: institutions[0].id })
            setProducts(products)
            setStep(2)
          } else if (products.length === 1) {
            setUser({ name, username, password, product: products[0].id })
            setInstitutions(institutions)
            setStep(1)
          } else {
            setInstitutions(institutions);
            setProducts(products)
            setUser({ name, username, password })
            setStep(1);
          }
        }
      }).catch(err => {
        setErrorMessage('Usuário ou senha inválidos')
        setError(true)
      })
  }

  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = (event) => event.preventDefault()
  const handleUsernameChange = (event) => setUsername(event.target.value.trim())
  const handlePasswordChange = (event) => setPassword(event.target.value.trim())
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')


  return (
    <>
      <div className={classes.logo} />
      <form className={classes.form} onSubmit={onSubmit} noValidate >
        <TextField
          id="email"
          type="text"
          value={username}
          label="Email"
          onChange={handleUsernameChange}
          variant="outlined"
          margin="dense"
          fullWidth
          name="email"
          autoComplete="email"
          autoFocus
        />
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            label="Senha"
            onChange={handlePasswordChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Entrar
        </Button>
      </form>

      <Collapse style={{ margin: 'auto', width: '100%' }} in={error}>
        <Alert severity="error" onClose={() => setError(false)}>
          {errorMessage}
        </Alert>
      </Collapse>

      <div style={{ marginBlock: '8px' }}>
        <Button onClick={() => redirect('/password-reset')}>Esqueceu sua senha?</Button>
      </div>
      <Copyright />
    </>
  )
}

const InstitutionSelector = ({ user, institutions, setStep, setProducts, context, setUser, ...props }) => {

  const handleClick = (institution) => {
    if (user.product) {
      finishLogin(user.username, user.password, institution, user.product, context)
    } else {
      setUser({ ...user, institution: institution })
      setStep(2)
    }
  }

  return (
    <>
      <div>
        <Typography>Olá, {user.name}!</Typography>
        <Typography>Selecione abaixo a intituição que deseja acessar.</Typography>
      </div>
      <div style={{ marginBlock: '48px', display: 'flex', flexDirection: 'column', minHeight: '100px', justifyContent: 'space-around', width: '100%' }}>
        {
          institutions?.map((institution, idx) => (
            <Button onClick={() => handleClick(institution.id)} variant="outlined">{institution.name}</Button>
          ))
        }
      </div>
    </>
  )
}

const ProductSelector = ({ user, setStep, products, context, ...props }) => {
  const theme = useTheme();

  const handleClick = (product) => {
    finishLogin(user.username, user.password, user.institution, product, context)
  }

  return (
    <>
      <div style={{ marginTop: '32px' }}>
        <Typography style={{ lineHeight: 3 }}>Olá, <Typography style={{ fontSize: '28px', fontWeight: 'bolder' }} component={'span'}>{user.name}</Typography></Typography>
        <Typography>Selecione abaixo o sistema que deseja acessar.</Typography>
      </div>
      <div style={{ marginBlock: '48px', display: 'flex', flexDirection: 'column', width: '100%' }}>
        {products?.map(product => (
          <>
            <ImageButton
              url={product.id === 1 ? theme.palette.type === 'light' ? LogoJuristtaSchoolPreta : LogoJuristtaSchoolBranca : theme.palette.type === 'light' ? LogoJuristtaOfficePreta : LogoJuristtaOfficeBranca}
              title={product.name}
              width={'100%'}
              height={'90px'}
              onClick={() => handleClick(product.id)}
            />
            <Typography style={{ marginBottom: '48px', textAlign: 'center', fontSize: '12px', fontWeight: 'bolder', marginTop: '4px' }} > {product.id === 1 ? 'SIMULADOR DE PRÁTICA JURÍDICA' : 'GESTÃO DE NPJ'}</Typography>
          </>))}
      </div>
    </>
  )
}

const BackgroundImageCarousel = () => {
  const classes = useStyles();
  const slidePresentationTime = 10000
  const backgroundImageList = [Image1, Image3, Image5, Image7, Image9, Image11, Image13];
  const [currentSlide, setCurrentSlide] = useState(random(backgroundImageList.length - 1))

  const nextSlide = () => {
    if (currentSlide === backgroundImageList.length - 1) {
      return 0
    }
    return currentSlide + 1
  }

  const calcAnimation = (idx) => {
    if (idx === currentSlide) {
      return classes.image + ' ' + classes.currentImage
    } else if (idx === nextSlide()) {
      return classes.image + ' ' + classes.nextImage
    } else {
      return classes.image + ' ' + classes.not_active
    }
  }


  useEffect(() => {
    setTimeout(() => { setCurrentSlide(nextSlide()) }, slidePresentationTime)
  })

  return backgroundImageList.map((image, idx) =>
    <img
      key={idx}
      id={idx}
      src={image}
      alt='ambientação-direito'
      style={{ zIndex: `-${idx + 1}` }}
      className={calcAnimation(idx)}
    />
  )
}

export const LoginPage = (props) => {

  const classes = useStyles();
  const context = useContext(AppContext)

  return (
    <Grid container style={{ height: '100vh' }} spacing={0}>
      <Grid item xs={false} sm={6} md={8}>
        <BackgroundImageCarousel />
      </Grid>
      <Grid className={classes.waveBackground} item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <MultiStepForm context={context} />
        </div>
      </Grid>
    </Grid >
  )

}