import { Avatar, Badge, Card, CardContent, CardHeader, Divider, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@material-ui/core"
import { Delete, Markunread, MoreVert } from "@material-ui/icons"
import moment from "moment"
import { useEffect, useState } from "react"
import { Loading, Title, useRedirect } from "react-admin"
import dataProvider from "../../providers/data-provider"
import { useAuthenticated } from "react-admin";


const Sidemenu = (props) => {

    const { notification, reload, setReload } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const markRead = (id) => {
        dataProvider.markNotificationRead(id).then(() => setReload(!reload))
    }

    const markUnread = (id) => {
        dataProvider.markNotificationUnread(id).then(() => setReload(!reload))
    }

    const deleteNotification = (id) => {
        dataProvider.delete('notifications', { id: id }).then(() => setReload(!reload))
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    notification.read ?
                        <MenuItem onClick={() => markUnread(notification.id)}>
                            <ListItemIcon> <Markunread /> </ListItemIcon>
                            <ListItemText primary={'Marcar como não lido'} />
                        </MenuItem>
                        :
                        <MenuItem onClick={() => markRead(notification.id)}>
                            <ListItemIcon> <Markunread /> </ListItemIcon>
                            <ListItemText primary={'Marcar como lido'} />
                        </MenuItem>
                }
                <MenuItem onClick={() => deleteNotification(notification.id)}>
                    <ListItemIcon> <Delete /> </ListItemIcon>
                    <ListItemText primary={'Excluir'} />
                </MenuItem>
            </Menu>
        </>
    )
}

export const NotificationsPage = () => {
    useAuthenticated();
    const [notifications, setNotifications] = useState([])
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false)

    const redirect = useRedirect()

    useEffect(() => {
        const getNotifications = async () => {
            setLoading(true)
            const res = await dataProvider.getNotifications({ sort: 'created_at', order: 'DESC' })
            setNotifications(res.data.data)
            setLoading(false)
        }
        getNotifications();
    }, [reload])

    const handleClick = (event, id, link) => {
        event.preventDefault()
        dataProvider.markNotificationRead(id).then(() => redirect(link.split('/#')[1]))
    }

    return (
        <>
            <Title title={'Minhas notificações'} />
            {/*<Typography style={{ margin: '16px 8px 8px 16px' }}>Minhas notificações</Typography>*/}
            <List>
                {
                    loading ? <Loading /> :
                        notifications && notifications?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(
                            notification =>
                                <ListItem>
                                    <Card style={{ width: '100%' }} id={notification.id}>
                                        <CardHeader
                                            avatar={
                                                <Badge color='error' variant="dot" invisible={notification.read}>
                                                    <Avatar src={'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristta_simples.png'} variant='rounded' />
                                                </Badge>}
                                            title={notification.title}
                                            titleTypographyProps={{ style: { fontWeight: notification.read ? 'normal' : 'bold' } }}
                                            subheader={`por Juristta Office em ${moment(notification.created_at).format('LL')}`}
                                            subheaderTypographyProps={{ style: { fontWeight: notification.read ? 'normal' : 'bold' } }}
                                            action={<Sidemenu notification={notification} reload={reload} setReload={setReload} />} />
                                        <Divider />
                                        <CardContent>
                                            <Typography style={{ fontWeight: notification.read ? 'normal' : 'bold' }} >{notification.body}</Typography>
                                            {
                                                notification.link && <Link
                                                    style={{ fontWeight: notification.read ? 'normal' : 'bold', cursor: 'pointer' }}
                                                    variant={'body1'}
                                                    onClick={(e) => handleClick(e, notification.id, notification.link)}
                                                >
                                                    Clique aqui para visualizar
                                                </Link>
                                            }
                                        </CardContent>
                                    </Card>
                                </ListItem>
                        )
                }
            </List>
        </>
    )

}
