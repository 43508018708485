import { CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core"
import { useEffect, useRef } from "react"
import { Spacer } from "../../components/spacer"
import LogoJuristtaIA from './182.png'
import React from "react"
import MarkdownView from "react-showdown"

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        marginInline: "15%",
        width: "100%",
    },

    aiMessage: {
        width: '50%',
        alignSelf: 'center',
        lineHeight: '1.6',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
    },
    userMessage: {
        maxWidth: '30%',
        minWidth: "15%",
        paddingInline: '24px',
        borderRadius: "8px",
        background: theme.palette.background.paper,
        lineHeight: '1.6',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize
    },
    timestamp: {
        textAlign: 'right',
        marginInlineEnd: '8px',
        color: theme.palette.text.secondary,
        fontSize: '70%'
    },
    aiTimestamp: {
        marginLeft: "25%",
        paddingLeft: "25px",
        textAlign: 'left',
        color: theme.palette.text.secondary,
        fontSize: '70%'
    },
    messagePart: {
        '& pre': {
            whiteSpace: 'pre-wrap',
        },
        '& pre > code': {
            display: 'inline-block',
        }
    },
    imageContainer: {
        marginLeft: '-16px',
        marginRight: '16px',
    },
    userContainer: {

    },
    aiContainer: {
        justifyContent: 'center',
    },
}))

export const MessageList = ({ messages, ...props }) => {
    const messageBottom = useRef(null)

    const scrollToBottom = () => {
        messageBottom.current?.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(scrollToBottom, [messages])
    const styles = useStyles()
    return (
        <div className={styles.main}>
            {
                messages && messages.map((msg) => (
                    <Grid container column>
                        <Grid item container row className={msg.role === 'user' ? styles.userContainer : styles.aiContainer}>
                            {msg.role === 'user' ?
                                <Spacer /> :
                                <Grid item className={styles.imageContainer}>
                                    <img alt="IA Juristta" src={LogoJuristtaIA} height={'50px'} width={'50px'} />
                                </Grid>
                            }
                            <Grid item className={msg.role === 'user' ? styles.userMessage : styles.aiMessage}>
                                {msg.role === 'system' ? <CircularProgress /> :
                                    <MarkdownView
                                        className={styles.messagePart}
                                        markdown={msg.content}
                                    />}
                            </Grid>
                        </Grid>
                        <Grid container item>
                            {msg.role === "user" && <Spacer />}
                            <Typography className={msg.role === 'user' ? styles.timestamp : styles.aiTimestamp}>{msg.timestamp}</Typography>
                        </Grid>
                    </Grid>
                ))
            }
            <div ref={messageBottom} />
        </div>
    )
}