import ApplicationResource from "../../utils/resources.enum";

export const permissionsResource = {
  name: ApplicationResource.PERMISSIONS,
  tableName: 'permissions',
  products: ['school', 'office']
}

export const permissionsResourceResource = {
  name: 'permissions/resources',
  tableName: 'permissionsResource',
  //  isPublic: true,
  products: ['school', 'office']
}

export const myPermissionsResource = {
  name: 'permissions/me',
  tableName: 'myPermissions',
  //isPublic: true,
  products: ['school', 'office']
}