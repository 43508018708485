import { Document, Page, Text } from "@react-pdf/renderer"
import React from "react"
import PDFTable from "../../components/react-pdf-table"

export const ActivitiesWithoutGradeReport = ({ data, type, styles }) => {

    const headers = [
        { id: 'process.alias', text: 'Protocolo', width: '21%' },
        { id: 'process.done_by', text: 'Gestores', width: '30%', multiple: true, multipleSubfield: 'name' },
        { id: 'type.name', text: 'Movimentação', width:'38%' },
        { id: 'effective_deadline', text: 'Prazo final', format: 'date', width: '11%' }
    ]

    return (
        <Document>
            <Page size={'A4'} orientation="portrait">
                <Text style={styles.title}>Relatório de movimentações sem correção</Text>
                <Text style={styles.subtitle}>{data.institution.name} - Emitido em {new Date().toLocaleString()}</Text>
                <PDFTable headers={headers} data={data.data} />
            </Page>
        </Document>
    )
}
