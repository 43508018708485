import { IconButton } from "@material-ui/core";
import { AddCircle, MoreHoriz } from "@material-ui/icons";
import { TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import { useRedirect } from "react-admin";
import ExpandableCard from "../expandable-card";

const NewTaskButton = ({ record, resource, redirectTo, ...props }) => {
    const redirect = useRedirect();
    return (
        <IconButton
            onClick={() => {
                redirect('create', `/${resource}`, {}, {}, { redirectTo: redirectTo, data: record })
            }}
            label="Adicionar movimentação"
        >
            <AddCircle fontSize="large" />
        </IconButton>
    )
}

export const NewTaskSkeletonCard = ({ record, resource, redirectTo, ...props }) => {
    return (
        <TimelineItem>
            <TimelineOppositeContent style={{ display: 'none' }} />
            <TimelineSeparator>
                <TimelineDot>
                    <MoreHoriz />
                </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
                <ExpandableCard realce={true} title={'Criar nova movimentação'} onlyTitle={false} mini={false} >
                    <div style={{
                        display: 'flex', justifyContent: 'center'
                    }}>
                        <NewTaskButton record={record} resource={resource} redirectTo={redirectTo} />
                    </div>
                </ExpandableCard>
            </TimelineContent>
        </TimelineItem>
    )
}