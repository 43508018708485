import { Add } from "@material-ui/icons";
import { Button, useRedirect } from "react-admin";

const ProcessAddActivity = ({ record, ...props }) => {
    const redirect = useRedirect();

    return (
        <Button
            style={{ alignSelf: 'center', flexGrow: 1 }}
            onClick={() => {
                redirect('create', '/activities', {}, {}, { redirectTo: `/process-dashboard/${record.id}`, process: record })
            }}
            label="Adicionar movimentação"
            variant="contained"
        >
            <Add />
        </Button>
    )
}

export default ProcessAddActivity;
