import { ButtonBase, makeStyles } from "@material-ui/core";



export const ImageButton = ({ url, width, height, title, ...props }) => {

    const useStyles = makeStyles((theme) => ({
        image: {
            height: image.height,
            width: image.width,
            backgroundImage: `url(${image.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            '&:hover': {
                opacity: 0.6,
                transition: 'opacity 250ms ease-in-out'
            },
        }
    }));


    const image = { url, width, height, title }

    const classes = useStyles();

    return <ButtonBase
        focusRipple
        key={image.title}
        className={classes.image}
        {...props}
    />
}