import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider, Grid, List, Typography } from "@material-ui/core";
import { Add, Delete, Edit, List as ListIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Loading, useRedirect } from "react-admin";
import cookiesProvider from "../../providers/cookies-provider";

import moment from "moment";
import dataProvider from "../../providers/data-provider";
import { capitalizeFirstLetter } from "../../utils/functions";
import { DangerousHTMLDiv } from "../../components/dangerousHTMLDiv";

const StudentProcessNotes = (props) => {

    const processId = props.match.params.id;

    const { origin } = props.history.location.state ? props.history.location.state : { origin: undefined }

    const redirect = useRedirect()

    const [loading, setLoading] = useState(true)
    const [process, setProcess] = useState(undefined)
    const [disabled, setDisabled] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const getData = async () => {
            const resp = await dataProvider.getProcessNotes(processId)
            setProcess(resp.data)
            setLoading(false)
        }
        getData()
    }, [refresh, processId])

    if (loading) { return <Loading /> }
    else {
        const editable = process.active
        const redirectTo = `/student-process-notes/${process.id}`;
        const userId = cookiesProvider.getUser()

        return (
            <div style={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            size={'small'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                                origin ? redirect(origin) : redirect(`/process-dashboard/${process.id}`)
                            }}
                            startIcon={<ListIcon />}>
                            Retornar
                        </Button>
                        <span style={{ marginLeft: '5px' }} />
                        {
                            editable && <Button startIcon={<Add />} variant={'contained'} color={'primary'} size={'small'} onClick={() => {
                                redirect('create', '/process-notes', {}, {}, { origin: origin, redirectTo: redirectTo, process: process.id })
                            }}>Adicionar nota</Button>
                        }
                        <Typography style={{ marginTop: '16px' }}>{`Notas do atendimento ${process.alias}`}</Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <List style={{ width: '100%' }}>
                            {process.notes.map((note, index) => (
                                <Grid item xs={12} key={index} style={{ marginTop: '1em' }} >
                                    <Card>
                                        <CardHeader titleTypographyProps={{ style: { fontSize: '1.2em' } }} title={note.author.name} subheader={capitalizeFirstLetter(moment(note.created_at).format('LLLL'))} />
                                        <Divider />
                                        <CardContent>
                                            <DangerousHTMLDiv html={note.text} />                                        
                                        </CardContent>
                                        {note.author.id === userId &&
                                            <CardActions>
                                                {
                                                    editable && (
                                                        <Button
                                                            size={'small'}
                                                            variant='contained'
                                                            color="primary"
                                                            onClick={
                                                                () => {
                                                                    redirect('edit', '/process-notes', note.id, undefined, { origin: origin, redirectTo: redirectTo })
                                                                }}
                                                            startIcon={<Edit />
                                                            }> Editar </Button>
                                                    )
                                                }
                                                {
                                                    editable && (
                                                        <Button
                                                            size={'small'}
                                                            variant='contained'
                                                            color="primary"
                                                            onClick={
                                                                () => {
                                                                    setDisabled(true);
                                                                    dataProvider.delete('process-notes', { id: note.id })
                                                                        .then(() => { setRefresh(!refresh) })
                                                                }
                                                            }
                                                            disabled={disabled}
                                                            startIcon={disabled ? <CircularProgress size={'18'} /> : <Delete />}> Excluir </Button>
                                                    )
                                                }
                                            </CardActions>
                                        }
                                    </Card>
                                </Grid>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default StudentProcessNotes;