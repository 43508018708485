import React from "react";
import { Edit, FormTab, TabbedForm, TextInput } from "react-admin";

const PartyTypeEdit = (props) => {

    const CustomTitle = ({ record }) => {
        return <span> {`Sujeito processual - ${record.name}`} </span>
    }

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            title={<CustomTitle />}
        >
            <TabbedForm>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label={'Nome'} fullWidth />
                </FormTab>
            </TabbedForm>
        </Edit>

    )
}

export default PartyTypeEdit;