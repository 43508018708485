import { Check, Clear } from "@material-ui/icons";
import jsonExport from 'jsonexport/dist';
import moment from "moment";
import React from "react";
import { Datagrid, DateField, downloadCSV, FunctionField, List, SearchInput, SelectInput, TextField } from "react-admin";
import { storageProvider } from "../../providers/storage-provider";

const ServiceRequestList = (props) => {

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin

    const exporter = data => {
        const toExport = data.map(item => {
            return {
                alias: item.alias,
                name: item.client.name,
                email: item.client.email,
                phone: item.client.phone,
                status: item.status,
                origin: item.origin,
                description: item.description,
                created_at: moment(item.created_at).format('DD/MM/YYYY')
            }
        })
        jsonExport(toExport, {
            headers: ['alias', 'name', 'email', 'phone', 'status', 'origin', 'description', 'created_at'],
            rename: ['Protocolo', 'Cliente', 'Email', 'Telefone', 'Status', 'Origem', 'Relato', 'Criado em'],
        },
            (err, csv) => {
                downloadCSV(csv, 'Pedidos_de_atendimento')
            })
    }

    const filters = [
        <SearchInput placeholder="Protocolo" source="alias" alwaysOn />,
        <SelectInput label="Status" source="status" choices={[{ name: "Pendentes e aceitos", id: "P+A" }, { name: "Pendente", id: "Pendente" }, { name: "Aceito", id: "Aceito" }, { name: "Rejeitado", id: "Rejeitado" }]} alwaysOn />,
        <SelectInput label="Possui atendimento?" source="hasProcess" choices={[{ id: true, name: "Sim" }, { id: false, name: "Não" }]} alwaysOn />,
        <SelectInput label="Origem" source="origin" choices={[{ id: "IN_PERSON", name: "Presencial" }, { id: "ONLINE", name: "Online" }, { id: "BY_PHONE", name: "Por telefone" }]} />,
    ]

    return (
        <List exporter={exporter} bulkActionButtons={false}
            title={'Pedidos de atendimento'}
            sort={{ field: 'created_at', order: 'DESC' }}
            filters={filters}
            filterDefaultValues={{ hasProcess: false, status: 'P+A' }}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="alias" label={'Protocolo'} />
                <TextField source="client.name" label={'Cliente'} />

                {superAdmin && <TextField source="institution.name" label={'Instituição'} />}
                <TextField source="status" label={'Status'} />
                <TextField source="origin" label={'Origem'} />
                <FunctionField label="Possui atendimento" source="process.id" render={record => record.process ? <Check /> : <Clear />} />
                <DateField source="created_at" label={'Criado em'} />
            </Datagrid>
        </List>
    )
}

export default ServiceRequestList;