import { Person } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import UserCreate from "./user-create";
import UserEdit from "./user-edit";
import UserList from "./user-list";

export const userResource = {
    name: ApplicationResource.USERS,
    icon: Person,
    options: { label: "Usuários" },
    link: "/users",
    tableName: 'user',
    list: UserList,
    edit: UserEdit,
    create: UserCreate,
    products:['school', 'office']
};