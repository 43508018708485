import { Card, CardContent, Grid, Paper, Typography, makeStyles } from "@material-ui/core"
import moment from "moment"
import { useEffect, useState } from "react"
import { Loading } from "react-admin"
import dataProvider from "../../providers/data-provider"

const useStyles = makeStyles((theme) => ({

    body: {
        backgroundColor: '#1f1f1f',
        padding: '16px 8px 16px 8px',
        height: '100%'
    },

    serv_req_row: {
        //  border: '1px solid red',
        flexBasis: '24%',
        borderRadius: '4px',
        margin: '2px',
        backgroundColor: '#3C4168',
    },

    serv_req_card: {
        backgroundColor: '#4A5282',
    },

    proc_row: {
        // border: '1px solid green',
        flexBasis: '24%',
        borderRadius: '4px',
        margin: '2px',
        backgroundColor: '#C16200',
    },

    proc_card: {
        backgroundColor: '#FF850A',
    },

    legal_proc_row: {
        //border: '1px solid blue',
        flexBasis: '24%',
        borderRadius: '4px',
        margin: '2px',
        backgroundColor: '#4A7543',
    },

    legal_proc_card: {
        backgroundColor: '#629B59',
    },

    npj_row: {
        //  border: '1px solid yellow',
        flexBasis: '24%',
        borderRadius: '4px',
        margin: '2px',
        backgroundColor: '#F61A13',

    },

    npj_card: {
        backgroundColor: '#F9544E',
    },

    card_row: {
        justifyContent: 'space-between',
    },

    header_row: {
        flexGrow: 1,
    },

}))


const DataCard = ({ title, primaryText, secondaryText, className, ...props }) => {

    return (
        <Card className={className} variant="elevation">
            <CardContent>
                <Typography variant={'h4'} style={{ textAlign: 'center' }}> {primaryText}</Typography>
                <Typography color="textSecondary" style={{ fontSize: 12, textAlign: 'center' }}>{secondaryText}</Typography>
            </CardContent>
        </Card>
    )
}


export const InstitutionStatsPage = () => {

    const styles = useStyles()
    const [data, setData] = useState(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            await dataProvider.get('institutions', 'stats').then((res) => setData(res.data))
            setLoading(false)
        }
        getData();
    }, [])

    return (
        loading ? <Loading /> :
            <Paper elevation={12} className={styles.body}>
                <Grid container direction="column" spacing={1}>

                    <Grid container item direction="row" spacing={1}>
                        <Grid item className={styles.header_row}>
                            <DataCard
                                title={`Período letivo`}
                                primaryText={`${moment(data?.academicPeriod?.start_at).format('DD/MM/YYYY')} a ${moment(data?.academicPeriod?.end_at).format('DD/MM/YYYY')}`}
                                secondaryText={`${data?.academicPeriod?.name}`}
                            />
                        </Grid>

                    </Grid>

                    <Grid container item direction="row" spacing={1}>

                        <Grid container item direction="row" className={styles.card_row} spacing={1}>

                            <Grid container item direction="column" className={styles.serv_req_row} spacing={1}>

                                <Grid item>
                                    <Typography>Pedidos de assistência</Typography>
                                </Grid>

                                <Grid item>
                                    <DataCard
                                        className={styles.serv_req_card}
                                        primaryText={data?.serviceRequestAndStatus?.reduce((acc, cur) => acc + parseInt(cur.count), 0)}
                                        secondaryText={'pedidos registrados'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.serv_req_card}
                                        primaryText={data?.serviceRequestAndStatus?.find((item) => item.status === 'Aceito')?.count}
                                        secondaryText={'pedidos aceitos'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.serv_req_card}
                                        primaryText={data?.serviceRequestAndStatus?.find((item) => item.status === 'Rejeitado')?.count}
                                        secondaryText={'pedidos rejeitados'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" className={styles.proc_row} spacing={1}>
                                <Grid item>
                                    <Typography>Atendimentos</Typography>
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.proc_card}
                                        primaryText={data?.processesAndStatus?.reduce((acc, cur) => acc + parseInt(cur.count), 0)}
                                        secondaryText={'atendimentos realizados'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.proc_card}
                                        primaryText={data?.processesAndStatus?.find((item) => item.active === true)?.count}
                                        secondaryText={'atendimentos ativos'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.proc_card}
                                        primaryText={data?.processesAndStatus?.find((item) => item.active === false)?.count}
                                        secondaryText={'atendimentos inativos'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" className={styles.legal_proc_row} spacing={1}>
                                <Grid item>
                                    <Typography>Processos classificados</Typography>
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.legal_proc_card}
                                        primaryText={data?.processesAndProcessTypes?.find((item) => item.type === 'Judicial')?.count}
                                        secondaryText={'processos judiciais'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.legal_proc_card}
                                        primaryText={data?.processesAndProcessTypes?.find((item) => item.type === 'Administrativo')?.count}
                                        secondaryText={'processos administrativos'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.legal_proc_card}
                                        primaryText={data?.processesAndProcessTypes?.find((item) => item.type === 'Outros')?.count}
                                        secondaryText={'outros processos'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" className={styles.npj_row} spacing={1}>
                                <Grid item>
                                    <Typography>Equipe do NPJ</Typography>
                                </Grid>

                                <Grid item>
                                    <DataCard
                                        className={styles.npj_card}
                                        primaryText={data?.institutionUsers?.find((item) => item.profile === 'Coordenador')?.count}
                                        secondaryText={'coordenadores'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.npj_card}
                                        primaryText={data?.institutionUsers?.find((item) => item.profile === 'Professor')?.count}
                                        secondaryText={'professores'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.npj_card}
                                        primaryText={data?.institutionUsers?.find((item) => item.profile === 'Secretaria')?.count}
                                        secondaryText={'secretários'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.npj_card}
                                        primaryText={data?.institutionUsers?.find((item) => item.profile === 'Advogado')?.count}
                                        secondaryText={'advogados'}
                                    />
                                </Grid>
                                <Grid item>
                                    <DataCard
                                        className={styles.npj_card}
                                        primaryText={data?.institutionUsers?.find((item) => item.profile === 'Aluno')?.count}
                                        secondaryText={'alunos'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper >
    )
}