import { MenuItem } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
}));

const CollapsableMenuItem = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    children,
    dense,
    ...props
}) => {
    const classes = useStyles();
    const header = (
        <MenuItem dense={dense} button onClick={handleToggle} key={props.id} {...props} >
            <ListItemIcon className={classes.icon}>
                {isOpen ? <ExpandMore /> : icon}
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {name}
            </Typography>
        </MenuItem>
    );

    return (
        <Fragment>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            {
                children && children.length > 0 && <Collapse in={isOpen} timeout="auto">
                    <List
                        dense={dense}
                        component="div"
                        disablePadding
                        className={
                            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
                        }
                    >
                        {children}
                    </List>
                </Collapse>
            }
        </Fragment>
    );
};

export default CollapsableMenuItem;
