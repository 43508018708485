import React from "react";
import { BooleanField, Datagrid, DateField, List, TextField } from "react-admin";
import { storageProvider } from '../../providers/storage-provider';

const GuidelineTypeList = (props) => {

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin

    return (
        <List exporter={false} bulkActionButtons={false}
            title={'Tipos de parte'}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label={'Nome'} />
                <TextField source="author.name" label={'Autor'} />
                <BooleanField source="public" label={'Pública?'} />
                {superAdmin && <TextField source="institution.name" label={'Instituição'} />}
                <DateField source="created_at" label={'Criado em'} />
            </Datagrid>
        </List>
    )
}


export default GuidelineTypeList;