import { DateRange } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import ActivityNoteCreate from "./activity-note-create";
import ActivityNoteEdit from "./activity-note-edit";

export const activityNoteResource = {
    name: ApplicationResource.ACTIVITY_NOTES,
    icon: DateRange,
    options: { label: "Notas de movimentação" },
    tableName: 'activity-note',
    edit: ActivityNoteEdit,
    create: ActivityNoteCreate,
    products:['office']
};