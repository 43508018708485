import { Typography, makeStyles } from "@material-ui/core"


const useStyles = makeStyles({

    root: {
        '& a:link': { color: '#ffffff' },
        '& a:active': { color: '#f0f0f0' },
        '& a:visited': { color: '#c0c0c0' },
        '& a:hover': { color: '#e1e1e1' },
    }
})

export const DangerousHTMLDiv = ({ html, ...props }) => {
    const styles = useStyles()

    return <Typography>
        <div className={styles.root} dangerouslySetInnerHTML={{ __html: html }} {...props} />
    </Typography>
}