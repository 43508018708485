import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'
import { Title } from 'react-admin'

const TelesapiensContent = (props) => {

    const [selected, setSelected] = useState(undefined);

    const options = [{
        id: 'UN1',
        type: 'Direito Civil',
        name: 'Unidade 1',
        url: 'https://scorm.onilearning.com.br/item-trilha.php?Componente=f000195a0e7fbac5ad1f586100e5d185',
    }, {
        id: 'UN2',
        type: 'Direito Civil',
        name: 'Unidade 2',
        url: 'https://scorm.onilearning.com.br/item-trilha.php?Componente=36e77f2ef8bf6faa369febc6a498cb82',
    }, {
        id: 'UN3',
        type: 'Direito Civil',
        name: 'Unidade 3',
        url: 'https://scorm.onilearning.com.br/item-trilha.php?Componente=68ac92377aca5e0b9de973d978c70ecb',
    }, {
        id: 'UN4',
        type: 'Direito Civil',
        name: 'Unidade 4',
        url: 'https://scorm.onilearning.com.br/item-trilha.php?Componente=9c7d7427e663d767f15c52c451aacb4e',
    }]

    const handleSelect = (event, value) => {
        setSelected(value)
    }

    return (
        <>
            <Title title={'Material de apoio Telesapiens'} />
            <Grid container>
                <Autocomplete
                    id='combobox-select'
                    options={options}
                    getOptionLabel={option => option.name || 'Selecione...'}
                    style={{ width: 350 }}
                    groupBy={option => option.type}
                    noOptionsText={'Sem resultados'}
                    size={'small'}
                    value={selected}
                    onChange={handleSelect}
                    renderInput={(params) => <TextField {...params} label={'Selecione o módulo'} variant='filled' />}
                />
            </Grid>
            <Grid container style={{ height: '100%', margin: '2px', width: '100%' }}>
                {selected ?
                    <iframe
                        title='content'
                        src={selected.url}
                        style={{ height: '100%', width: 'inherit' }}
                    />
                    :
                    <iframe title='nocontent' style={{ height: '100%', width: 'inherit' }} />
                }
            </Grid>
        </>
    )
}

export default TelesapiensContent