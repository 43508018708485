import { VideoCall } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import HearingCreate from "./hearing-create";
import HearingEdit from "./hearing-edit";

export const hearingResource = {
    name: ApplicationResource.HEARINGS,
    icon: VideoCall,
    options: { label: "Audiências" },
    link: "/hearings",
    tableName: 'hearing',
    edit: HearingEdit,
    create: HearingCreate,
    products:[ 'office']
}