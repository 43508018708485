import { createTheme } from "@material-ui/core";

export const ExternalProcessMailTheme = createTheme({
    palette: {
        type: 'dark',
        primary: { main: '#d49435', },
        secondary: { main: '#d49435', },
        error: { main: '#fb291b', },
    },
    typography: { fontFamily: 'Roboto', },
    shape: { borderRadius: 4, },
    props: { MuiTooltip: { arrow: true, }, },
    spacing: 8,
    zIndex: { appBar: 1200, drawer: 1100, },
    topBar: { height: "56px", }
})