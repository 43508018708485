import React from "react";
import { Button, useRedirect } from "react-admin";

const AddNewAttachmentButton = ({ record, resource, disabled, multiple, isRecord, fileSize, label = false, redirectTo = false, ...props }) => {

    const redirect = useRedirect()

    return (
        <Button
            onClick={() => {
                redirect(
                    'create',
                    '/attachments',
                    {},
                    {},
                    {
                        redirectTo: redirectTo ? redirectTo : `/${resource}/${record.id}/${isRecord ? 'record' : 'attachments'}`,
                        resource: resource,
                        id: record.id,
                        multiple: multiple,
                        isRecord: isRecord,
                        fileSize: fileSize,
                    })
            }}
            label={label ? label : `Adicionar ${isRecord ? 'íntegra' : 'anexo'}`}
            variant="contained"
            disabled={disabled || false}
        >
        </Button>
    );
}

export default AddNewAttachmentButton;