import RichTextInput from "ra-input-rich-text";
import React from "react";
import { Create, DateInput, FormTab, TabbedForm, TextInput } from "react-admin";

const ExternalServiceRequestCreate = (props) =>
    <Create {...props}>
        <TabbedForm>
            <FormTab label={'Dados gerais'}>
                <TextInput source='name' label={'Título'} fullWidth />
                <DateInput source='start_at' label={'Data de início'} fullWidth />
                <DateInput source='end_at' label={'Data de fim'} fullWidth />
                <RichTextInput source='instructions' label={'Instruções'} fullWidth />
            </FormTab>
        </TabbedForm>
    </Create>

export default ExternalServiceRequestCreate;