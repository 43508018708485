import React from "react";
import { BooleanInput, Edit, FormTab, SelectInput, TabbedForm, TextInput } from "react-admin";

export const ProcessTypeEdit = (props) => {

    const { redirect } = props.history.location.state ? props.history.location.state : { redirect: 'list' }

    const choices = [{ id: 'Judicial' }, { id: 'Administrativo' }, { id: 'Outros' }]

    return (
        <Edit
            title={'Tipos de processo'}
            mutationMode="pessimistic"
            {...props}
        >
            <TabbedForm redirect={redirect}>
                <FormTab label={'Dados Gerais'}>
                    <SelectInput source="type" optionText={'id'} label={'Tipo'} choices={choices} fullWidth />
                    <TextInput source="name" label={'Complemento'} fullWidth />
                    <BooleanInput source='pje' label={'PJE?'} />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}