import { Class } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import { ProcessTypeCreate } from "./process-type-create";
import { ProcessTypeEdit } from "./process-type-edit";
import { ProcessTypeList } from "./process-type-list";

export const processTypeResource = {
    name: ApplicationResource.PROCESS_TYPES,
    icon: Class,
    options: { label: "Tipos de processo" },
    tableName: 'process-type',
    list: ProcessTypeList,
    edit: ProcessTypeEdit,
    create: ProcessTypeCreate,
    link: '/process-types',
    products: ['office']
};