import { Step, StepButton, Stepper } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';
import React, { useState } from "react";
import {
    Button,
    Create,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect
} from "react-admin";
import { Spacer } from '../../components/spacer';
import MaskedInput from '../../components/masked-input';

const choices = [{ id: "IN_PERSON", name: "Presencial" }, { id: "ONLINE", name: "Online" }, { id: "BY_PHONE", name: "Por telefone" }]

const ServiceRequestStepper = ({ steps, currentStep, setStep, ...props }) => {

    return (
        <Stepper activeStep={currentStep}>
            {steps.map((step, idx) => <Step key={`step${idx}`}>
                <StepButton onClick={() => setStep(idx)}>{step.title}</StepButton>
            </Step>)}
        </Stepper>
    )
}

const ServiceRequestCreate = (props) => {

    const steps = [{ id: 0, title: 'Selecionar cliente' }, { id: 1, title: 'Preencher dados' }]
    const [step, setStep] = useState(0)

    const redirect = useRedirect()

    const onSuccess = ({ data }) => {
        redirect(`/service-requests/${data.id}/attachments`)
    }

    const StepToolbar = ({ steps, currentStep, setStep, ...props }) => {

        const goPrev = () => setStep(currentStep - 1)
        const goNext = () => setStep(currentStep + 1)

        return (
            <Toolbar {...props}>
                <Button label='Anterior' onClick={goPrev} disabled={currentStep === 0} />
                <Button label='Próximo' onClick={goNext} disabled={currentStep === steps.length - 1} />
                <Spacer />
                <SaveButton disabled={currentStep !== steps.length - 1} />
            </Toolbar>
        )
    }

    return (
        <>
            <ServiceRequestStepper currentStep={step} steps={steps} setStep={setStep} />
            <Create {...props} title="Novo pedido de atendimento" onSuccess={onSuccess}>
                <SimpleForm toolbar={<StepToolbar steps={steps} currentStep={step} setStep={setStep} />}>
                    {step === 0 && (
                        <>
                            <TextInput label={'Nome'} source="client.name" validate={[required()]} fullWidth />
                            <MaskedInput label={'Telefone'} source="client.phone" onlyNumbers={true} mask={'(99) 99999-9999'} validate={[required()]} fullWidth autoComplete="off" />
                            <TextInput label={'Email'} source="client.email" fullWidth />
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <SelectInput
                                source="origin"
                                label={'Origem'}
                                choices={choices}
                                optionText={"name"}
                                optionValue={"name"}
                                defaultValue={"IN_PERSON"}
                                validate={[required()]}
                                fullWidth
                            />
                            <RichTextInput source="description" label={'Breve relato'} validate={[required()]} fullWidth />
                        </>
                    )}
                </SimpleForm>
            </Create >
        </>
    )
}

export default ServiceRequestCreate;