
import {
    Grid
} from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { useEffect, useState } from 'react'
import { Loading, Title } from 'react-admin'
import dataProvider from '../../providers/data-provider'
import { storageProvider } from '../../providers/storage-provider'
import { ModelActivityAttachments } from './model-activity-attachments'
import { ModelActivityInfo } from './model-activity-info'
import { ModelActivityParties } from './model-activity-parties'
import { ModelActivityStepper } from './model-activity-stepper'
import { ModelActivityTasks } from './model-activity-tasks'

moment.locale('pt-br');

const SchoolModelActivityDashboard = (props) => {

    const id = props.match.params.id;

    const profiles = storageProvider.getCurrentUser()?.profiles
    const isStudent = profiles[0]?.name === 'Aluno'

    const [model_activity, setModelActivity] = useState(undefined)
    const [refresh, setRefresh] = useState(true)
    const [attachments, setAttachments] = useState(null)

    const toggleRefresh = () => setRefresh(!refresh)

    useEffect(() => {
        (async () => {
            await dataProvider.getDashboard('school-model-activities', { id: id })
                .then(res => {
                    setModelActivity(res.data)
                    setAttachments(res.data.attachments)
                })
        })()
    }, [refresh, id])

    return model_activity === undefined ? <Loading /> : (
        <Grid container>
            <Title title={'Atividade matriz'} />
            <Grid container item key={'controls'} direction="row">
                <Grid container item spacing={1} xs={12} direction='row' justifyContent='flex-start' key={'left-column'}>
                    <ModelActivityStepper model_activity={model_activity} setModelActivity={setModelActivity} />
                </Grid>
            </Grid>
            <Grid container item spacing={1} direction="row" key={'external'}>
                <Grid container item spacing={1} xs={5} lg={5} direction='column' key={'left-column'}>
                    <Grid item key={'model-activity-info'} style={{ marginTop: '28px' }}>
                        <ModelActivityInfo model_activity={model_activity} isStudent={isStudent} />
                    </Grid>

                    <Grid item key={'model-activity-attachments'}>
                        <ModelActivityAttachments id={model_activity.id} attachments={attachments} setAttachments={setAttachments} />
                    </Grid>

                    <Grid item key={'model-activity-parties'}>
                        <ModelActivityParties model_activity={model_activity} refresh={toggleRefresh} />
                    </Grid>
                </Grid>
                <Grid container item spacing={1} xs={7} lg={7} direction='column' key={'right-column'}>
                    <Grid item key={'model-activity-tasks'}>
                        <ModelActivityTasks model_activity={model_activity} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SchoolModelActivityDashboard;
