import { Button } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React from "react";
import { getUrl } from "../../utils/urls";

export const DashboardButton = ({ record, resource, ...props }) => {
    return (
        <Button href={
            resource === 'processes' ?
                `${getUrl()}/#/process-dashboard/${record.id}` :
                `${getUrl()}/#/process-dashboard/${record.process.id}`
        }
            color="primary"
            target={'_blank'}
            startIcon={<Visibility />}
        >
            Dashboard
        </Button>
    )
}