import { Typography } from "@material-ui/core"
import { Code, CodeBlock, CopyBlock, dracula } from "react-code-blocks"

export const ConsultaPeriodoLetivo = () => {

    const text = `GET /v1/consulta-periodos-letivos
Host: api.app.juristta.com
Authorization: Bearer v1_a2f5i10a_abc123def456ghi789jk`

    const text2 = `[
    {
        "id": "abcd1234-56ef-78gh-90ij-abcdef123456",
        "name": "01/2024",
        "description": "1º semestre 2024",
        "start_at": "2024-01-01",
        "end_at": "2024-06-30",
    },
    {
        "id": "1234abcd-ef56-gh78-ij90-123456abcdef",
        "name": "02/2024",
        "description": "2º semestre 2024",
        "start_at": "2024-01-01",
        "end_at": "2024-06-30",
    },
]
`

    return <>
        <Typography variant="h6">Consulta de períodos letivos</Typography>
        <div style={{ marginBottom: '16px' }} />

        <Typography variant='body1'>Este endpoint permite consultar informações detalhadas de todos períodos letivos da instituição, como o id associado no sistema e as datas de início e término.</Typography>

        <div style={{ marginBlock: '16px', borderRadius: '8px', backgroundColor: '#1f1f1f', padding: '8px' }}>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>Método:</Typography> <Typography component='span'>GET</Typography>
            </div>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>URL:</Typography> <Typography component='span'>https://api.app.juristta.com/v1/consulta-periodos-letivos </Typography>
            </div>
        </div>
        <Typography variant="h6">Exemplo de Requisição </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>


        <Typography variant="h6">Exemplo de Resposta </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CodeBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text2} language="json" theme={dracula} />
        </Typography>

        <Typography variant="body1">Requisições que não seguirem corretamente a especificação receberão uma resposta com o status <Code language='http' text="HTTP 400 Bad Request." theme={dracula} ></Code> com detalhes do problema. </Typography>

    </>
}