import { Collapse, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@material-ui/core";
import { Assignment, Code as CodeIcon, ExpandLess, ExpandMore, PersonAdd, Search } from "@material-ui/icons";
import { useState } from "react";
import { CadastroPeriodoLetivo } from "./cadastroPeriodoLetivo";
import { CadastroTurmas } from "./cadastroTurma";
import { ConsultaPeriodoLetivo } from "./consultaPeriodoLetivo";
import { ConsultaTurmas } from "./consultaTurmas";
import { Introduction } from "./introduction";
import { RegraAlunoTurma } from "./regraAlunoTurma";
import { RegraInativar } from "./regraIntivar";

const apiDocsContent = {
    introduction: { jsx: <Introduction /> },
    consultaPeriodoLetivo: { jsx: <ConsultaPeriodoLetivo /> },
    consultaTurmas: { jsx: <ConsultaTurmas /> },
    cadastroPeriodoLetivo: { jsx: <CadastroPeriodoLetivo /> },
    cadastroTurmas: { jsx: <CadastroTurmas /> },
    regraAlunoTurma: { jsx: <RegraAlunoTurma /> },
    regraInativar: { jsx: <RegraInativar /> },
}

const ApiDocsPage = () => {

    const [selected, setSelected] = useState("introduction")

    return (
        <Grid container spacing={3}>
            {/*Cabeçalho*/}
            <Grid container item>
                <Paper elevation={3} style={{ width: '100%', padding: '16px' }}>
                    <Typography variant={'h5'} style={{ fontWeight: '300', marginBottom: '16px' }}>
                        Documentação da API de integração Juristta</Typography>
                    <Typography variant={'body2'}>
                        Esta documentação tem por finalidade auxiliar na integração com os serviços fornecidos pelo Juristta.
                    </Typography>
                    <Typography variant={'body2'}>
                        Todos os dados enviados e recebidos pela API estão/deverão ser em formato JSON (application/json).
                    </Typography>
                    <Typography variant={'body2'}>
                        Em caso de dúvidas, entre em contato conosco pelo email <Link href={'mailto:tecnologia@juristta.com'}>tecnologia@juristta.com</Link>
                    </Typography>
                </Paper>
            </Grid>
            {/* Sidebar + Conteudo */}
            <Grid container item direction="row" spacing={3}>
                {/* Sidebar */}
                <Grid item direction="column" xs={3}>
                    <Paper style={{ padding: '16px' }} elevation={2}>
                        <List component={'nav'} dense>
                            <NestedListItem label="Introdução" icon={<CodeIcon />} onClick={() => { setSelected("introduction") }} />
                            <FoldableListItem label="Consultas" icon={<Search />} >
                                <NestedListItem label={"Períodos letivos"} onClick={() => { setSelected("consultaPeriodoLetivo") }} />
                                <NestedListItem label={"Turmas"} onClick={() => { setSelected("consultaTurmas") }} />
                            </FoldableListItem>
                            <FoldableListItem label="Cadastros" icon={<PersonAdd />} >
                                <NestedListItem label={"Período letivo"} onClick={() => { setSelected("cadastroPeriodoLetivo") }} />
                                <NestedListItem label={"Turmas"} onClick={() => { setSelected("cadastroTurmas") }} />
                            </FoldableListItem>
                            <FoldableListItem label="Regras de negócio" icon={<Assignment />}>
                                <NestedListItem label={"Inserir alunos em turma"} onClick={() => { setSelected("regraAlunoTurma") }} />
                                <NestedListItem label={"Inativar usuários"} onClick={() => { setSelected("regraInativar") }} />
                            </FoldableListItem>
                        </List>
                    </Paper>
                </Grid>

                {/* Conteúdo */}
                <Grid item xs={9}>
                    <Paper style={{ padding: '32px' }} elevation={3}>
                        <ListContent selected={selected} />
                    </Paper>
                </Grid>
            </Grid>
        </Grid >
    )
}

const ListContent = ({ selected }) => {

    const content = apiDocsContent[selected]

    return content.jsx
}



const FoldableListItem = ({ label, icon, children }) => {

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{label}</ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </>
    )
}

const NestedListItem = ({ label, icon, onClick }) => {

    return (
        <ListItem button onClick={onClick}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText>{label}</ListItemText>
        </ListItem>
    )
}

export default ApiDocsPage;