import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import { BooleanInput, DateInput, Edit, FormTab, TabbedForm, TextInput } from 'react-admin';
import TextFieldWithClipboardButton from '../../components/text-field-with-clipboard-button';

const ExternalServiceRequestEdit = (props) => {

    return <Edit {...props}>
        <TabbedForm>
            <FormTab label={'Dados gerais'}>
                <TextInput source='name' label={'Título'} fullWidth />
                <TextFieldWithClipboardButton source='url' label={'URL'} />
                <DateInput source='start_at' label={'Data de início'} fullWidth />
                <DateInput source='end_at' label={'Data de fim'} fullWidth />
                <BooleanInput source='available' label={'Disponível'} fullWidth />
                <RichTextInput source='instructions' label={'Instruções'} fullWidth />
            </FormTab>
        </TabbedForm>
    </Edit >
}

export default ExternalServiceRequestEdit;