import { Menu } from "@material-ui/icons";
import React from "react";
import { AutocompleteArrayInput, Button, Create, FormTab, ReferenceInput, TabbedForm, TextInput, Title, useNotify, useRedirect } from "react-admin";

const SchoolModelActivityCreate = (props) => {

    const redirect = useRedirect();
    const notify = useNotify()

    const onSuccess = ({ data }) => {
        notify('Atividade matriz criada com sucesso!')
        redirect(`/model-activity-dashboard/${data.id}`)
    }

    return (
        <Create title={<Title title={'Nova atividade matriz'} />} onSuccess={onSuccess}
            actions={<Button style={{ marginBottom: '16px' }} variant='contained' label='Retornar' startIcon={<Menu />} onClick={() => redirect('/school-model-activities')} />}
            {...props}>
            <TabbedForm>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source='name' label='Título' fullWidth />
                    <TextInput source='author' label='Autor' fullWidth />
                    <ReferenceInput source='themes' label='Ramos do direito' sort={{ field: 'name', order: 'ASC' }} perPage={100} reference="themes" fullWidth>
                        <AutocompleteArrayInput
                            source="name"
                            optionText={"name"}
                            optionValue={"id"}
                            parse={value =>
                                value && value.map(v => ({ id: v }))
                            }
                            format={value => value && value.map(v => v.id)}
                            suppressRefError={true}
                            options={{
                                suggestionsContainerProps: {
                                    modifiers: {
                                        computeStyle: {
                                            enabled: true,
                                            gpuAcceleration: false,
                                        },
                                        applyStyle: {
                                            enabled: true,
                                        },
                                    },
                                }
                            }}
                            fullWidth
                        />
                    </ReferenceInput>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}

export default SchoolModelActivityCreate 