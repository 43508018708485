export const jitsiConfig = {
  defaultLanguage: "ptBR",
  prejoinPageEnabled: false,
  disableReactions: true,
  disableReactionsModeration: true,
  disableRemoteMute: true,
  startAudioOnly: true,
  disableThirdPartyRequests: true,
  remoteVideoMenu: {
    disableKick: true,
  },
};

export const jitsiAdminConfig = {
  startAudioOnly: true,
  prejoinPageEnabled: false,
  disableReactions: true,
  disableReactionsModeration: true,

  disableThirdPartyRequests: true,
  defaultLanguage: "ptBR",
};
