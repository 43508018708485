import {
    Button,
    CardActions,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    Divider, Grid, IconButton, List, ListItem,
    ListItemSecondaryAction, ListItemText,
    ListSubheader, Paper, Popover, TextField, Typography, makeStyles
} from "@material-ui/core";
import { Add, ChevronRight, Delete, Remove, Save, Shuffle } from "@material-ui/icons";
import { random } from "lodash";
import React, { useEffect, useState } from "react";
import { useNotify, useRedirect } from "react-admin";
import ExpandableCard from "../../components/expandable-card";
import dataProvider from "../../providers/data-provider";
import { orderByObjectName } from "../../utils/functions";

const useStyles = makeStyles((theme) => ({
    grid: {

    },
    rowContainer: {
        flexDirection: 'row',
    },

    columnContainer: {
        flexDirection: 'column',
    },

    titleHeader: {
        textAlign: 'right',
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        margin: '-8px 8px -8px -16px',
        flexBasis: '15%'
    },

    titleText: {
        flexBasis: 'content',
        alignSelf: 'center',
        flexGrow: 1,
        marginRight: '-16px'
    },

    title: {
        fontWeight: 'bold',

    },

    body: {
        flexDirection: 'row',
    },

    paper: {
        padding: '8px 16px 8px 16px',
        marginTop: '8px',
        marginBottom: '8px'
    },

    listHeader: {
        width: '100%',
        marginLeft: '-16px',
        paddingLeft: '16px',
        marginRight: '-16px',
        paddingRight: '16px',
        borderBottom: '1px solid #8c8989',
        marginBottom: '8px',
        backgroundColor: theme.palette.primary.main,
        marginTop: '-8px',
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px',
        paddingBlock: '4px',
    },

    teamList: {
        overflowY: 'scroll',
        maxHeight: '55vh',
        height: '55vh',
        width: '100%',
        alignContent: 'flex-start',
        paddingRight: '8px',
        listStyleType: 'none',
    },

    teamCard: {
        justifyContent: 'space-evenly',
        backgroundColor: theme.palette.background.default,
        marginBlock: '4px'
    },

    teamParties: {
        flexBasis: '45%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
    },
    teamEmpty: {
        flexBasis: '45%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        border: '1px solid red'
    },

}))

const sortByName = (a, b) => a.name > b.name ? 1 : -1;

const CreateActivityTeams = ({ ...props }) => {

    const id = props.match.params.id;

    const [teams, setTeams] = useState([]);
    const [students, setStudents] = useState([]);
    const [school_activity, setSchoolActivity] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [val, setVal] = useState(1);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [parties, setParties] = useState(null);
    const [errors, setErrors] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => await dataProvider.get('school-activities', `${id}/teamCreator`)
        fetchData().then(res => {
            setStudents(res.data?.class?.users?.sort((a, b) => orderByObjectName(a, b)))
            setParties(res.data.party_types)
            setSchoolActivity(res.data)
        })
    }, [id])

    useEffect(() => {
        let hasError = true
        const rolesWithoutUsers = teams.filter(team => team.parties.filter(party => party.students.length === 0).length > 0)
        if (teams.length > 0 && rolesWithoutUsers.length === 0) { hasError = false }
        setErrors(hasError)
    }, [teams, students])

    const redirect = useRedirect();
    const notify = useNotify();

    const openTeamSelectionPopover = (event, studentId) => {
        setAnchorEl(event.currentTarget);
        const student = students.find(student => student.id === studentId)
        setSelectedStudent(student)
    };

    const openPartySelectionPopover = (event, teamId) => {
        setAnchorEl2(event.currentTarget);
        const team = teams.find(team => team.id === teamId)
        setSelectedTeam(team)
    };

    const closeTeamSelectionPopover = () => {
        setAnchorEl(null);
        setSelectedStudent(null);
    };

    const closePartySelectionPopover = () => {
        setAnchorEl2(null);
        setSelectedTeam(null);
    };

    const openDialog = () => {
        setDialogOpen(true)
    }

    const closeDialog = () => {
        setVal(1)
        setDialogOpen(false)
    }

    const classes = useStyles()

    const addTeam = () => {
        if (teams.length > 0) {
            const id = teams.slice(-1)[0].id + 1
            setTeams([...teams, { id: id, name: `Processo ${(id).toString().padStart(2, "0")}`, students: [], parties: parties.map((party) => ({ id: party.id, name: party.name, students: [] })) }])
        } else {
            setTeams([{ id: 1, name: 'Processo 01', students: [], parties: parties.map((party) => ({ id: party.id, name: party.name, students: [] })) }])
        }
    }

    const removeTeam = (id) => {
        const team = teams.find(team => team.id === id)
        setStudents([...students, ...team.students].sort(sortByName))
        setTeams(teams.filter(team => team.id !== id))
    }

    const addStudentToParty = (partyId) => {
        const newTeams = teams
        const student = students.find(student => student.id === selectedStudent.id)
        const teamidx = teams.findIndex(team => team.id === selectedTeam.id)
        const partyidx = teams[teamidx].parties.findIndex(party => party.id === partyId)
        newTeams[teamidx].students.push(student)
        newTeams[teamidx].parties[partyidx].students.push(student)
        const newStudents = students.filter(student => student.id !== selectedStudent.id)
        setStudents(newStudents)
        setTeams(newTeams)
        closeTeamSelectionPopover()
        closePartySelectionPopover()
    }

    const removeStudentFromTeam = (teamId, studentId, partyId) => {
        let newStudents = [...students]
        let newTeams = [...teams]

        const team = newTeams.find(team => team.id === teamId)
        const student = team.students.splice(team.students.findIndex(student => student.id === studentId), 1)
        const party = team.parties.find(party => party.id === partyId)

        party.students.splice(party.students.findIndex(student => student.id === studentId), 1)
        newStudents.push(...student)
        setStudents(newStudents.sort(sortByName))
        setTeams(newTeams)
    }

    const handleValChange = (e) => e.target.value > 0 && setVal(e.target.value)

    const generateRandomDist = () => {
        const newTeams = [...teams]
        const studentsPerParty = val
        const qtParties = parties.length
        let teamsAmount = parseInt(students.length / (studentsPerParty * qtParties))
        const rest = students.length % (studentsPerParty * qtParties)
        const newStudents = students
        const nextId = newTeams.length > 0 ? newTeams[newTeams.length - 1].id + 1 : 1
        const generatedTeams = []
        if (rest > 0) teamsAmount += 1;
        for (let i = 0; i < teamsAmount; i++) {
            let team = { id: i + nextId, name: `Processo ${(i + nextId).toString().padStart(2, "0")}`, students: [], parties: parties.map((party) => ({ id: party.id, name: party.name, students: [] })) }
            for (let j = 0; j < studentsPerParty; j++) {
                for (let k = 0; k < qtParties; k++) {
                    let randIndex = random(0, newStudents.length - 1, false)
                    let s = newStudents.splice(randIndex, 1)
                    team.students.push(...s)
                    team.parties[k].students.push(...s)
                }
            }

            generatedTeams.push(team)
        }
        setTeams([...newTeams, ...generatedTeams])
    }

    const submitTeams = async () => {
        setLoading(true)
        dataProvider.post('school-activities', 'create-teams', { activity: id, teams })
            .then(() => {
                notify('Processos criados com sucesso', 'success')
                redirect(`/school-activity-dashboard/${id}`)
            }).catch((error) => {
                notify('Erro ao criar os processos.', 'error')
            }).finally(() => {
                setLoading(false)
            })
    }

    const handleConfirmationDialogOpen = () => setConfirmationDialogOpen(true)
    const handleConfirmationDialogClose = () => setConfirmationDialogOpen(false)

    return (
        <Grid container className={classes.columnContainer}>
            <Grid container item className={classes.rowContainer}>
                <Grid container item className={classes.columnContainer} style={{ flexShrink: 1, flexBasis: '70%' }}>
                    <Paper elevation={1} className={classes.paper}>
                        <Grid container style={{ padding: '0px' }}>
                            <Grid container className={classes.titleHeader} item spacing={2} direction="column">
                                <Grid item style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}><Typography className={classes.title} color="textPrimary"> Atividade: </Typography> </Grid>
                                <Grid item><Typography className={classes.title} color="textPrimary"> Turma: </Typography></Grid>
                            </Grid>
                            <Grid container className={classes.titleText} item spacing={2} direction="column">
                                <Grid item style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}><Typography color="textPrimary"> {school_activity?.name} </Typography> </Grid>
                                <Grid item><Typography color="textPrimary"> {school_activity?.class?.name} </Typography></Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid container item className={classes.rowContainer} style={{ flexGrow: 1, flexBasis: '20%', justifyContent: 'flex-end' }}>
                    <Button onClick={openDialog} startIcon={<Shuffle />} variant="contained" size="medium" color="secondary" style={{ alignSelf: 'center' }}> Gerar distribuição aleatória </Button>
                    <Dialog
                        open={dialogOpen}
                        onClose={closeDialog}
                    >
                        <DialogTitle>Gerar distribuição aleatória</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Ao gerar a distribuição aleatória, os alunos da turma serão distribuídos em grupos de maneira automática, respeitando o parâmetro abaixo.
                            </DialogContentText>
                            <TextField type="number" label="Alunos por parte" value={val} onChange={handleValChange} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog}>Cancelar</Button>
                            <Button onClick={() => { generateRandomDist(); closeDialog() }}>OK</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
            <Grid container item className={classes.body} spacing={2}>
                <Grid item container key={'students'} className={classes.columnContainer} style={{ flexShrink: 1, flexBasis: '25%', height: '70vh', maxHeight: '70vh' }}>
                    <Paper elevation={1} className={classes.paper}>
                        <div className={classes.listHeader}>
                            <Typography className={classes.title}>Alunos disponíveis ({students.length})</Typography>
                        </div>
                        <div className={classes.teamList}>
                            {students && students.length > 0 && students.map((student, idx) => (
                                <>
                                    {idx !== 0 && <Divider />}
                                    <ListItem dense key={`student${student.id}`} >
                                        <ListItemText primary={student.name} />
                                        <ListItemSecondaryAction>
                                            <IconButton size="small" onClick={(event) => openTeamSelectionPopover(event, student.id)} disabled={teams.length < 1}> <ChevronRight /></IconButton>
                                            <Popover
                                                id={`popover${student.id}`}
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}
                                                onClose={closeTeamSelectionPopover}
                                                anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
                                            >
                                                <List dense>
                                                    {teams.map(team => (
                                                        <ListItem dense>
                                                            <ListItemText>
                                                                <Typography>{team.name}</Typography>
                                                            </ListItemText>
                                                            <ListItemSecondaryAction>
                                                                <IconButton size="small" onClick={(event) => { openPartySelectionPopover(event, team.id) }}><ChevronRight /></IconButton>
                                                                <Popover
                                                                    id={`popover${team.id}`}
                                                                    open={Boolean(anchorEl2)}
                                                                    anchorEl={anchorEl2}
                                                                    onClose={closePartySelectionPopover}
                                                                    anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
                                                                >
                                                                    <List dense>
                                                                        {team.parties.map(party => (
                                                                            <ListItem dense>
                                                                                <ListItemText>
                                                                                    <Typography>{party.name}</Typography>
                                                                                </ListItemText>
                                                                                <ListItemSecondaryAction>
                                                                                    <IconButton size="small" onClick={() => { addStudentToParty(party.id) }}><ChevronRight /></IconButton>
                                                                                </ListItemSecondaryAction>
                                                                            </ListItem>
                                                                        ))}
                                                                    </List>
                                                                </Popover>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Popover>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </>
                            ))}
                        </div>
                    </Paper>
                </Grid>
                <Grid item container key={'teams'} className={classes.columnContainer} style={{ flexGrow: 1, flexBasis: '70%', height: '70vh', maxHeight: '70vh' }}>
                    <Paper elevation={1} className={classes.paper}>
                        <div className={classes.listHeader}>
                            <Grid item container key={'team-controls'} className={classes.rowContainer} style={{ alignItems: 'center', maxHeight: '24px' }}>
                                <img alt='processos' src={'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/pictograma_EQUIPES.png'} width={'50px'} height={'50px'} style={{ opacity: '0.5', margin: '-12px', marginRight: '8px' }} />
                                <Typography className={classes.listTitle}>Processos ({teams.length})</Typography>
                                <span style={{ flexGrow: 1 }} />
                                <IconButton size="small" onClick={() => addTeam()}><Add /></IconButton>
                            </Grid>
                        </div>
                        <Grid item container key={'team-data'}>
                            <div className={classes.teamList}>
                                {teams && teams.length > 0 && teams.map((team) => <Team data={team} removeTeam={removeTeam} removeStudent={removeStudentFromTeam} />)}
                            </div>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container item className={classes.rowContainer}>
                <Button onClick={() => redirect(`/school-activity-dashboard/${id}`)} color="secondary" variant="contained" disabled={loading} >Cancelar</Button>
                <span style={{ flexGrow: 1 }} />
                <Button onClick={students.length > 0 ? handleConfirmationDialogOpen : submitTeams} variant="contained" disabled={errors || loading} color="primary" startIcon={loading ? <CircularProgress size={16} /> : <Save />}>Salvar</Button>
                <Dialog
                    open={confirmationDialogOpen}
                    onClose={handleConfirmationDialogClose}
                >
                    <DialogTitle>Ainda existem {students.length} alunos disponíveis!</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Deseja prosseguir com a criação da atividade sem alocar todos os alunos disponíveis nesta turma?
                        </DialogContentText>
                        <DialogContentText>
                            Esta distribuição de alunos não poderá ser editada posteriormente.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmationDialogClose}>Cancelar</Button>
                        <Button onClick={() => { submitTeams(); handleConfirmationDialogClose() }}>OK</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid >
    )
}

const Team = ({ data, removeTeam, removeStudent }) => {
    const classes = useStyles()

    return (
        <ExpandableCard className={classes.teamCard} outlined title={data.name}>
            <Grid container>
                {data.parties.map(party => (
                    <List className={(party.students && party.students.length > 0) ? classes.teamParties : classes.teamEmpty} style={{ paddingTop: '4px', marginInline: '4px' }} dense subheader={<ListSubheader style={{ lineHeight: '32px', borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}> {party.name} </ListSubheader>}>
                        {(!party.students || party.students.length === 0) && <ListItem dense><ListItemText primary={'Sem representante'} /></ListItem>}
                        {party.students.map(student => (
                            <ListItem dense>
                                <ListItemText primary={student.name} />
                                <ListItemSecondaryAction>
                                    <IconButton size="small" onClick={() => { removeStudent(data.id, student.id, party.id) }}>
                                        <Remove />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                ))}
            </Grid>
            <CardActions>
                <div style={{ flexGrow: 1 }}></div>
                <Button size="small" style={{ marginBottom: '-20px', marginRight: '-15px' }} variant="outlined" onClick={() => removeTeam(data.id)} startIcon={<Delete />}>Remover processo</Button>
            </CardActions>
        </ExpandableCard>
    )
}
export default CreateActivityTeams;