import {
    IconButton, List,
    ListItem, ListItemSecondaryAction,
    ListItemText
} from '@material-ui/core'
import { Link as LinkIcon } from '@material-ui/icons'
import 'moment/locale/pt-br'
import React from 'react'
import ExpandableCard from '../../components/expandable-card'
import { getUrl } from '../../utils/urls'

export const ProcessRelatedProcesses = ({ process, isStudent, ...props }) => {

    return (
        <ExpandableCard
            realce={true}
            title={`Processos conexos`}
            mini={process.related?.length < 1}
        >
            <List component="nav" dense={true}>
                {
                    process.related?.map(
                        (process) => <ListItem key={'related'}>
                            <ListItemText primary={`Atendimento: ${process?.alias}`} secondary={`Processo: ${process?.legal_process_code || '-'}`} />
                            <ListItemSecondaryAction>
                                <IconButton href={`${getUrl()}/#/process-dashboard/${process.id}`} target={'_blank'}>
                                    <LinkIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                }
            </List >
        </ExpandableCard >
    )
}
