import { AssignmentInd } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";

import SchoolActivityTaskCreate from "./school-activity-task-create";
import SchoolActivityTaskEdit from "./school-activity-task-edit";

export const schoolActivityTaskResource = {
    name: ApplicationResource.SCHOOL_ACTIVITY_TASKS,
    icon: AssignmentInd,
    tableName: 'school-activity-task',
    edit: SchoolActivityTaskEdit,
    create: SchoolActivityTaskCreate,
    products:['school']
};