import { AssignmentInd } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import SchoolModelActivityCreate from "./school-model-activities-create";
import SchoolModelActivityEdit from "./school-model-activities-edit";
import SchoolModelActivityList from "./school-model-activities-list";

export const schoolModelActivitiesResource = {
    name: ApplicationResource.SCHOOL_MODEL_ACTIVITIES,
    icon: AssignmentInd,
    options: { label: "Atividade matriz" },
    tableName: 'school-model-activity',
    list: SchoolModelActivityList,
    edit: SchoolModelActivityEdit,
    create: SchoolModelActivityCreate,
    products: ['school'],
    link: '/school-model-activities',
};