import { StyleSheet } from '@react-pdf/renderer';

export const pdfTableStyles = StyleSheet.create({
    tableContainer: {
        marginTop: '16px',
        marginHorizontal: '16px',
        borderWidth: 1,
        borderColor: '#303030',
    },
    container: {
        flexDirection: 'row',
        borderBottomColor: '#303030',
        backgroundColor: '#303030',
        borderBottomWidth: 1,
        fontStyle: 'bold',
        color: '#ffffff',
        fontSize: '12'
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#303030',
        borderBottomWidth: 1,
        fontStyle: 'bold',
        fontSize: '11'
    },
    tableHeader: {
        textAlign: 'left',
        paddingHorizontal: '0.5%'
    },
    tableHeaderLarge: {
        textAlign: 'left',
        flexGrow: 1,
        paddingHorizontal: '0.5%'
    },
    tableRow: {
        textAlign: 'left',
        paddingHorizontal: '0.5%',
        paddingVertical: '2px'
    },
    tableRowLarge: {
        textAlign: 'left',
        flexGrow: 1,
        paddingHorizontal: '0.5%'
    },
    cellText: {
        minWidth: 0,
        maxWidth: '100%',
    }
});


