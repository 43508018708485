import { Language } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import ExternalServiceRequestCreate from "./external-service-request-create";
import ExternalServiceRequestEdit from "./external-service-request-edit";
import ExternalServiceRequestList from "./external-service-request-list";

export const externalServiceRequestResource = {
    name: ApplicationResource.EXTERNAL_SERVICE_REQUESTS,
    icon: Language,
    options: { label: "Pedidos externos" },
    tableName: 'external_service_request',
    list: ExternalServiceRequestList,
    edit: ExternalServiceRequestEdit,
    create: ExternalServiceRequestCreate,
    link:'/external-service-requests',
    products:['office']
};