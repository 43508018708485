import { Card, CardContent, Collapse, Container, IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { ExpandLess, ExpandMore, Link } from '@material-ui/icons';

const VadeMecum = () => {

    const [state, setState] = useState({
        'Item1': false,
        'Item2': false,
        'Item3': false,
        'Item4': false,
        'Item5': false,
        'Item6': false,
        'Item7': false,
        'Item8': false,
        'Item9': false,
        'Item10': false,
        'Item11': false,
        'Item12': false,
    });


    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),

        },
    }));

    const VadeMecumListItem = (props) => {
        return (
            <ListItem button onClick={props.more ? () => HandleClick(props.stateId) : null}>
                {props.more ? (props.open ? <ExpandLess /> : <ExpandMore />) : (<div style={{ paddingLeft: "24px" }} />)}
                <ListItemText primary={props.label} />
                {props.link &&
                    (<IconButton edge="end" href={props.link} target='_blank'>
                        <Link fontSize="small" />
                    </IconButton>)}
            </ListItem>
        );
    }

    const HandleClick = (item) => {
        setState((state) => ({ ...state, [item]: !state[item] }));
    }

    const classes = useStyles();

    return (
        <Container>
            <Card>
                <CardContent>
                    <Typography>Vade Mecum</Typography>
                    <Typography>Caderno de Legislação e Jurisprudência</Typography>
                </CardContent>
                <CardContent>
                    <List className={classes.root}>
                        <VadeMecumListItem label='Legislação em Geral' link='http://www4.planalto.gov.br/legislacao/' more={true} open={state.Item3} stateId='Item3' />
                        <Collapse in={state.Item3} timeout="auto" >
                            <List component="div" className={classes.nested}>
                                <VadeMecumListItem label='Legislação Federal' link='' more={true} open={state.Item5} stateId='Item5' />
                                <Collapse in={state.Item5} timeout="auto" >
                                    <List component="div" className={classes.nested}>
                                        <VadeMecumListItem label='Códigos' link='http://www4.planalto.gov.br/legislacao/portal-legis/legislacao-1/codigos-1' more={true} open={state.Item6} stateId='Item6' />
                                        <Collapse in={state.Item6} timeout="auto" >
                                            <List component="div" className={classes.nested}>
                                                <VadeMecumListItem label='Código Civil' link='http://www.planalto.gov.br/ccivil_03/Leis/2002/L10406.htm' />
                                                <VadeMecumListItem label='Código de Processo Civil' link='http://www.planalto.gov.br/ccivil_03/Leis/L5869.htm' />
                                                <VadeMecumListItem label='Novo Código de Processo Civil' link='http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2015/lei/L13105.htm' />
                                                <VadeMecumListItem label='Código Penal' link='http://www.planalto.gov.br/CCIVIL_03/Decreto-Lei/Del2848.htm' />
                                                <VadeMecumListItem label='Código de Processo Penal' link='http://www.planalto.gov.br/ccivil_03/Decreto-Lei/Del3689.htm' />
                                                <VadeMecumListItem label='Código Tributário Nacional' link='http://www.planalto.gov.br/ccivil_03/Leis/L5172.htm' />
                                                <VadeMecumListItem label='Consolidação das Leis do Trabalho' link='http://www.planalto.gov.br/ccivil_03/Decreto-Lei/Del5452.htm' />
                                                <VadeMecumListItem label='Código de Defesa do Consumidor' link='http://www.planalto.gov.br/ccivil_03/Leis/L8078.htm' />
                                                <VadeMecumListItem label='Código Eleitoral' link='http://www.planalto.gov.br/ccivil_03/Leis/L4737.htm' />
                                                <VadeMecumListItem label='Código Comercial' link='http://www.planalto.gov.br/ccivil_03/Leis/LIM/LIM556.htm' />
                                            </List>
                                        </Collapse>
                                        <VadeMecumListItem label='Constituição Federal' link='http://www.planalto.gov.br/ccivil_03/constituicao/constituicao.htm' more={false} />
                                        <VadeMecumListItem label='Leis Ordinárias' link='http://www4.planalto.gov.br/legislacao/portal-legis/legislacao-1/leis-ordinarias' more={false} />
                                        <VadeMecumListItem label='Leis Complementares' link='http://www4.planalto.gov.br/legislacao/portal-legis/legislacao-1/leis-complementares-1/todas-as-leis-complementares-1' more={false} />
                                        <VadeMecumListItem label='Leis Delegadas' link='http://www4.planalto.gov.br/legislacao/portal-legis/legislacao-1/leis-delegadas-1' more={false} />
                                        <VadeMecumListItem label='Lei dos Juizados Especiais Cíveis e Criminais' link='http://www.planalto.gov.br/ccivil_03/leis/l9099.htm' more={false} />
                                        <VadeMecumListItem label='Diário Oficial' link='http://www.in.gov.br/web/guest/inicio' more={false} />
                                        <VadeMecumListItem label='Tratados Internacionais equivalentes a Emendas Constitucionais' link='http://www4.planalto.gov.br/legislacao/portal-legis/internacional/tratados-equivalentes-a-emendas-constitucionais-1' more={false} />
                                        <VadeMecumListItem label='Medidas Provisórias' link='http://www4.planalto.gov.br/legislacao/portal-legis/legislacao-1/medidas-provisorias' more={false} />
                                        <VadeMecumListItem label='Decretos' link='http://www4.planalto.gov.br/legislacao/portal-legis/legislacao-1/decretos1/decretos-1' more={false} />
                                        <VadeMecumListItem label='Propostas de Emenda à Constituição' link='http://www4.planalto.gov.br/legislacao/portal-legis/legislacao-1/propostas-de-emenda-a-constituicao-m' more={false} />
                                    </List>
                                </Collapse>
                                <VadeMecumListItem label='Legislação Estadual' link='http://www4.planalto.gov.br/legislacao/portal-legis/legislacao-estadual/legislacoes-estaduais' more={false} />
                                <VadeMecumListItem label='Constituições Estaduais' link='http://www4.planalto.gov.br/legislacao/portal-legis/legislacao-estadual/constituicoes-estaduais' more={false} />
                            </List>
                        </Collapse>
                        <VadeMecumListItem label='Tribunais' more={true} open={state.Item4} stateId='Item4' />
                        <Collapse in={state.Item4} timeout="auto" >
                            <List component="div" className={classes.nested}>
                                <VadeMecumListItem label='Justiça comum' more={true} open={state.Item7} stateId='Item7' />
                                <Collapse in={state.Item7} timeout="auto" >
                                    <List component="div" className={classes.nested}>
                                        <VadeMecumListItem label='Tribunais Superiores' link='http://portal.stf.jus.br/' more={true} open={state.Item9} stateId='Item9' />
                                        <Collapse in={state.Item9} timeout="auto" >
                                            <List component="div" className={classes.nested}>
                                                <VadeMecumListItem label='Supremo Tribunal Federal (STF)' link='http://portal.stf.jus.br/' more={true} open={state.Item11} stateId="Item11" />
                                                <Collapse in={state.Item11} timeout="auto" >
                                                    <List component="div" >
                                                        <VadeMecumListItem label='Jurisprudência' link='http://www.stf.jus.br/portal/jurisprudencia/pesquisarJurisprudencia.asp' />
                                                        <VadeMecumListItem label='Súmulas Vinculantes' link='http://www.stf.jus.br/portal/cms/verTexto.asp?servico=jurisprudenciaSumulaVinculante' />
                                                        <VadeMecumListItem label='Súmulas' link='http://portal.stf.jus.br/textos/verTexto.asp?servico=jurisprudenciaSumula' />
                                                        <VadeMecumListItem label='Acórdãos' link='http://portal.stf.jus.br/jurisprudencia/pesquisarInteiroTeor.asp' />
                                                    </List>
                                                </Collapse>
                                                <VadeMecumListItem label='Superior Tribunal de Justiça (STJ)' link='http://www.stj.jus.br/sites/portalp/Inicio' more={true} open={state.Item12} stateId="Item12" />
                                                <Collapse in={state.Item12} timeout="auto" >
                                                    <List component="div" className={classes.nested}  >
                                                        <VadeMecumListItem label='Jurisprudência' link='https://scon.stj.jus.br/SCON/' />
                                                        <VadeMecumListItem label='Súmulas' link='http://www.stj.jus.br/sites/portalp/Jurisprudencia/Sumulas' />
                                                        <VadeMecumListItem label='Acórdãos e Decisões' link='http://www.stj.jus.br/sites/portalp/Jurisprudencia/Acordaos-e-Decisoes' />
                                                    </List>
                                                </Collapse>
                                            </List>
                                        </Collapse>
                                        <VadeMecumListItem label='Justiça Federal' link='https://www.jfrj.jus.br/conteudo/paginas-do-poder-judiciario/tribunais-regionais-federais' more={true} open={state.Item10} stateId='Item10' />
                                        <Collapse in={state.Item10} timeout="auto" >
                                            <List component="div" className={classes.nested}>
                                                <VadeMecumListItem label='Jurisprudência' link='https://www2.cjf.jus.br/jurisprudencia/unificada/' more={true} open={state.Item12} stateId='Item12' />
                                                <Collapse in={state.Item12} timeout="auto" >
                                                    <List component="div" className={classes.nested} >
                                                        <VadeMecumListItem label='Tribunal Regional Federal da 1ª Região (TRF1)' link='https://portal.trf1.jus.br/portaltrf1/pagina-inicial.htm' />
                                                        <VadeMecumListItem label='Tribunal Regional Federal da 2ª Região (TRF2)' link='https://www10.trf2.jus.br/portal/' />
                                                        <VadeMecumListItem label='Tribunal Regional Federal da 3ª Região (TRF3)' link='https://www.trf3.jus.br/' />
                                                        <VadeMecumListItem label='Tribunal Regional Federal da 4ª Região (TRF4)' link='https://www.trf4.jus.br/trf4/' />
                                                        <VadeMecumListItem label='Tribunal Regional Federal da 5ª Região (TRF5)' link='http://www.trf5.jus.br/' />
                                                    </List>
                                                </Collapse>
                                            </List>
                                        </Collapse>
                                        <VadeMecumListItem label='Tribunais de Justiça Estaduais' link='https://www2.camara.leg.br/atividade-legislativa/comissoes/comissoes-permanentes/cspcco/tjs.html' />
                                    </List>
                                </Collapse>
                                <VadeMecumListItem label='Justiça especializada' more={true} open={state.Item8} stateId='Item8' />
                                <Collapse in={state.Item8} timeout="auto" >
                                    <List component="div" className={classes.nested}>
                                        <VadeMecumListItem label='Justiça Eleitoral' link='http://www.tse.jus.br/justica-eleitoral' more={false} />
                                        <VadeMecumListItem label='Justiça do Trabalho' link='http://www.tst.jus.br/justica-do-trabalho' more={false} />
                                        <VadeMecumListItem label='Justiça Militar' link='https://www.stm.jus.br/' more={false} />
                                    </List>
                                </Collapse>
                            </List>
                        </Collapse>
                        <VadeMecumListItem label='Poder Legislativo Federal - Congresso Nacional' link='' more={true} open={state.Item1} stateId='Item1' />
                        <Collapse in={state.Item1} timeout="auto" >
                            <List component="div" className={classes.nested}>
                                <VadeMecumListItem label='Câmara dos deputados' link='https://www.camara.leg.br/' more={false} />
                                <VadeMecumListItem label='Senado Federal' link='https://www12.senado.leg.br/hpsenado' more={false} />
                            </List>
                        </Collapse>
                        <VadeMecumListItem label='Poder Executivo Federal - Palácio do Planalto' link='https://www.gov.br/pt-br' more={false} />
                        <VadeMecumListItem label='Poder Judiciário - Conselho Nacional de Justiça' link='https://www.cnj.jus.br/' more={false} />
                    </List>
                </CardContent>
            </Card>
        </Container>
    )
}

export default VadeMecum