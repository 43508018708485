import { CardContent, CardMedia, Chip, Divider, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { AttachFile, Edit } from '@material-ui/icons'
import Vimeo from '@u-wave/react-vimeo'
import YouTube from '@u-wave/react-youtube'
import 'moment/locale/pt-br'
import React from 'react'
import { useRedirect } from 'react-admin'
import Carousel from 'react-material-ui-carousel'
import { DangerousHTMLDiv } from '../../components/dangerousHTMLDiv'
import ExpandableCard from '../../components/expandable-card'
import SchoolActivityInfoToolbar from './school-activity-info-toolbar'
import ViewOrientationsDialog from './view-orientations-dialog'

const EditSchoolActivityButton = ({ school_activity, ...props }) => {

    const redirect = useRedirect();
    const handleClick = () => {
        redirect(`/school-activities/${school_activity.id}`)
    }

    return (
        <Tooltip title={'Editar a atividade prática'}>
            <IconButton color={'text'} onClick={handleClick}>
                <Edit />
            </IconButton>
        </Tooltip>
    )
}

export const SchoolActivityInfo = ({ school_activity, isStudent, student, team, ...props }) => {

    const redirect = useRedirect()

    const content = <DangerousHTMLDiv html={school_activity.details} />

    const actions = (
        <>
            {!isStudent && <>
                <EditSchoolActivityButton school_activity={school_activity} />
                <Tooltip title={'Anexar'}>
                    <IconButton
                        onClick={
                            () => {
                                redirect('create', '/attachments', {}, {}, { redirectTo: `/school-activity-dashboard/${school_activity.id}`, resource: 'school-activities', id: school_activity.id, fileSize: 25000000 })
                            }}>
                        <AttachFile />
                    </IconButton>
                </Tooltip>
            </>
            }
            <SchoolActivityInfoToolbar preRequisites={school_activity.prerequisites} purpose={school_activity.proposal} />
            {!isStudent && <ViewOrientationsDialog small={true} school_activity={school_activity} />}
        </>
    );

    return (
        <ExpandableCard
            realce={true}
            title={'Informações'}
            content={content}
            action={actions}
            actionTextShow={"Detalhes"}
            actionTextHide={"Ocultar"}
        >
            <CardContent>
                <Grid container>
                    <Grid item container alignItems='center'>
                        <Typography style={{ fontSize: '1.2em', marginBottom: '4px', fontWeight: 'bold' }} > {school_activity.name} </Typography>
                    </Grid>
                    <Grid item container alignItems='center'>
                        <Typography style={{ fontSize: '1em', marginRight: '16px', fontWeight: 'bold' }}> Professor: </Typography>
                        <Typography style={{ fontSize: '1em' }} color='textSecondary'>  {school_activity.teacher?.name} </Typography>
                    </Grid>
                    <Grid item container alignItems='center'>
                        <Typography style={{ fontSize: '1em', marginRight: '16px', fontWeight: 'bold' }}> Ramos do direito:</Typography>
                        <Typography style={{ fontSize: '1em', marginRight: '16px' }}> {school_activity.themes?.map(theme => <Chip style={{ fontSize: '0.75em', marginRight: '4px' }} size='small' clickable={false} label={theme.name} />)} </Typography>
                    </Grid>
                </Grid>
                <Divider style={{ marginBlock: '16px' }} />
                {isStudent && (<Grid container>
                    <Grid item container alignItems='center'>
                        <Typography style={{ fontSize: '1em', marginRight: '16px', fontWeight: 'bold' }}> Alunos: </Typography>
                        {
                            school_activity?.studentTeam?.sort().map(el => <Chip style={{ fontSize: '0.75em', fontWeight: el === student?.name ? 'bold' : 'normal', marginRight: '4px' }} size='small' color='textSecondary' label={el} />)
                        }
                    </Grid>
                    <Grid item container alignItems='center'>
                        <Typography style={{ fontSize: '1em', marginRight: '16px', fontWeight: 'bold' }}> Turma: </Typography>
                        <Typography style={{ fontSize: '1em' }} color='textSecondary'>  {school_activity.class?.name} </Typography>
                    </Grid>
                    <Grid item container alignItems='center'>
                        <Typography style={{ fontSize: '1em', marginRight: '16px', fontWeight: 'bold' }}> Processo: </Typography>
                        <Typography style={{ fontSize: '1em' }} color='textSecondary'>  {team?.name} </Typography>
                    </Grid>
                    <Grid item container alignItems='center'>
                        <Typography style={{ fontSize: '1em', marginRight: '16px', fontWeight: 'bold' }}> Sujeito neste processo: </Typography>
                        <Typography style={{ fontSize: '1em' }} color='textSecondary'>  <Chip style={{ fontSize: '0.75em', marginRight: '4px' }} size='small' clickable={false} label={team?.team_memberships[0]?.role?.name} /> </Typography>
                    </Grid>
                </Grid>
                )}
                {isStudent && <Divider style={{ marginBlock: '16px' }} />}
            </CardContent>
            {
                school_activity.links && (
                    <CardMedia>
                        <Carousel autoPlay={false} navButtonsAlwaysVisible={school_activity.links?.length > 1}>
                            {
                                school_activity.links?.map((video, index) => {
                                    if (video.source === 'youtube') return <YouTube autoplay={false} annotations={false} modestBranding={true} showCaptions={false} showRelatedVideos={false} video={video.id} id={index} height={'457px'} width={'100%'} />
                                    if (video.source === 'vimeo') return <Vimeo video={video.id} id={index} responsive />
                                    else return null;
                                })
                            }
                        </Carousel>
                    </CardMedia>
                )
            }
        </ExpandableCard >
    )
}