import { Document, Page, Text } from "@react-pdf/renderer"
import React from "react"
import PDFTable from "../../components/react-pdf-table"

export const ClassGradesReport = ({ data, type, styles }) => {

    const headers = [
        { id: 'user_name', text: 'Aluno', width: '22%' },
        { id: 'process_alias', text: 'Protocolo', width: '15%', },
        { id: 'type_name', text: 'Movimentação', width: '25%' },
        { id: 'activity_deadline', text: 'Prazo final', format: 'date', width: '8%' },
        { id: 'type_workload_type', text: 'Tipo', format: 'workload_type', width: '15%' },
        { id: 'activity_workload', text: 'Avaliação', format: 'workload', width: '15%' },
    ]

    return (
        <Document>
            <Page orientation="landscape">
                <Text style={styles.title}>Relatório de notas por turma</Text>
                <Text style={styles.subtitle}>{data.institution.name} - Emitido em {new Date().toLocaleString()}</Text>
                <Text style={styles.subtitle}> Turma: {data.class.name} </Text>
                <PDFTable headers={headers} data={data.data} />
            </Page>
        </Document>
    )
}
