import RichTextInput from 'ra-input-rich-text';
import React from "react";
import { Create, FormTab, TabbedForm, TextInput } from "react-admin";

const ProfileCreate = (props) =>
    <Create {...props}>
        <TabbedForm redirect={'list'}>
            <FormTab label={'Dados Gerais'} >
                <TextInput source="name" label={'Nome'} fullWidth />
                <RichTextInput source="description" label={'Descrição'} fullWidth />
            </FormTab>
        </TabbedForm>
    </Create>

export default ProfileCreate;