import { PermIdentity } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import GuidelineTypeCreate from "./guideline-type-create";
import GuidelineTypeEdit from "./guideline-type-edit";
import GuidelineTypeList from "./guideline-type-list";

export const guidelineTypeResource = {
    name: ApplicationResource.GUIDELINE_TYPE,
    icon: PermIdentity,
    options: { label: "Orientações padrão" },
    tableName: 'guideline-type',
    list: GuidelineTypeList,
    edit: GuidelineTypeEdit,
    create: GuidelineTypeCreate,
    link: '/guideline-types',
    products: ['school']
};