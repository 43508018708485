import React from "react";
import { Edit, FormTab, TabbedForm, TextInput } from "react-admin";

export const ThemeEdit = (props) => {

    const { redirect } = props.history.location.state ? props.history.location.state : { redirect: 'list' }

    return (
        <Edit
            title={'Tipos de processo'}
            mutationMode="pessimistic"
            {...props}
        >
            <TabbedForm redirect={redirect}>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label={'Nome'} fullWidth />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}