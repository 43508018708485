import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from "@material-ui/core"
import { Add, Save } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import { useEffect, useState } from "react"
import { useNotify } from "react-admin"
import { Spacer } from "../../components/spacer"
import dataProvider from '../../providers/data-provider'

const ReplicateModelActivities = (props) => {

    const notify = useNotify()

    const [open, setOpen] = useState(false)
    const [institutions, setInstitutions] = useState([])
    const [selected, setSelected] = useState([])
    const [allInstitutions, setAllInstitutions] = useState(false)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchInstitutions()
    }, [])

    const { selectedIds } = props;

    const fetchInstitutions = () => {
        setLoading(true)
        dataProvider.getList('institutions', { sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: 5000 } })
            .then((res) => {
                setInstitutions(res.data)
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
            })
    }

    const handleSelectorChange = (event, value) => { setSelected(value) }
    const toggleAllInstitutions = () => { setAllInstitutions(!allInstitutions); setSelected([]) }
    const handleClose = () => setOpen(false)
    const handleSubmit = () => {
        setLoading(true)

        dataProvider.post('school-model-activities', 'replicate', { model_activities: selectedIds, institutions: selected, all: allInstitutions })
            .then((res) => {
                setOpen(false)
                setLoading(false)
                notify('Atividade replicada com sucesso!')
            })
    }

    return (<>
        <Button style={{ marginInline: '8px' }} variant="contained" startIcon={<Add />} onClick={() => { setOpen(true) }}>
            Replicar
        </Button>
        <Dialog fullWidth open={open} maxWidth={"md"} onClose={handleClose}>
            <DialogTitle>Replicar atividades matriz</DialogTitle>
            <DialogContent dividers style={{ minHeight: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={institutions}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    value={selected}
                    onChange={handleSelectorChange}
                    disabled={allInstitutions || loading}
                    noOptionsText={'Sem opções'}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Instituições"
                            placeholder="Selecione..."
                            disabled={allInstitutions || loading}
                            fullWidth
                        />
                    )}
                />

                <FormControlLabel
                    control={
                        <Checkbox checked={allInstitutions} onChange={toggleAllInstitutions} name="AllInstitutions" color="primary" />
                    }
                    disabled={loading}
                    label="Replicar para todas as instituições"
                />
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={handleClose} disabled={loading}>Cancelar</Button>
                <Spacer />
                <Button variant={'contained'} onClick={handleSubmit} startIcon={loading ? <CircularProgress size={24} /> : <Save />} disabled={loading} color="primary">Salvar</Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default ReplicateModelActivities