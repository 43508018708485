import { Divider, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BooleanField, Datagrid, DateField, ListContextProvider, Loading, Pagination, TextField, useList } from "react-admin";
import dataProvider from "../../../providers/data-provider";
import { DashboardButton } from "../../dashboard-button";

const TeacherDashboardProcesses = (props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page,] = useState(1)
    const [perPage,] = useState(25)
    const [filter, setFilter] = useState({ active: true })
    const [value, setValue] = useState('active');

    useEffect(() => {
        (async () => {
            setLoaded(false)
            setLoading(true)
            const resp = await dataProvider.getTeacherProcessDashboard()
            setData(resp.data[0])
            setLoading(false)
            setLoaded(true)
        })()
    }, [filter])

    const ids = data.map((row) => row.id)
    const sort = { field: 'id', order: 'DESC' };
    const context = useList({ data, ids, loading, loaded, page, perPage, sort, filter })

    const handleChange = (event) => {
        let value = event.target.value;
        setValue(value);
        switch (value) {

            case 'all':
                setFilter({})
                break;

            case 'active':
            default:
                setFilter({ active: true })
                break;

            case 'finished':
                setFilter({ active: false })
                break;
        }
    }

    return (
        <>
            <FormControl style={{ marginBlock: '10px', marginInline: '10px', minWidth: '150px' }}>
                <InputLabel id={'filter-select-label'}>Filtro</InputLabel>
                <Select labelId={'filter-select-label'} id={'filter-select'} value={value} disabled={loading} onChange={handleChange} variant="filled">
                    <MenuItem value={'all'}> Todos </MenuItem>
                    <MenuItem value={'active'}>Em aberto</MenuItem>
                    <MenuItem value={'finished'}>Arquivados</MenuItem>
                </Select>
            </FormControl>

            <Divider />
            {
                loading ? <Loading /> : <ListContextProvider value={context}>
                    <Datagrid basePath="/processes" resource="processes">
                        <TextField source="alias" label={'Protocolo'} />
                        <TextField source="client.name" label={'Cliente'} />
                        <DateField source="created_at" label={'Criado em'} locales={'pt-BR'} />
                        <BooleanField source="active" label={'Em aberto'} />
                        <DashboardButton />
                    </Datagrid>
                    <Pagination rowsPerPageOptions={[25, 50, 100]} />
                </ListContextProvider>
            }
        </>
    )
}




export default TeacherDashboardProcesses