import { Folder } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import ProcessCreate from "./process-create";
import ProcessEdit from "./process-edit";
import ProcessList from "./process-list";

export const processResource = {
    name: ApplicationResource.PROCESSES,
    icon: Folder,
    options: { label: "Atendimentos" },
    link: "/processes",
    tableName: 'process',
    list: ProcessList,
    edit: ProcessEdit,
    create: ProcessCreate,
    products:['office']
}