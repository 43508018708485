import React from "react";
import { Create, FormTab, ReferenceInput, SelectInput, TabbedForm, TextInput } from "react-admin";

const ClassCreate = (props) =>
    <Create {...props}>
        <TabbedForm redirect={'list'}>
            <FormTab label={'Dados Gerais'}>
                <ReferenceInput label="Período letivo" source="academic_period.id" reference="academic-periods" fullWidth>
                    <SelectInput source="name" optionText={'name'} optionValue={'id'} />
                </ReferenceInput>
                <TextInput source="name" label={'Nome'} fullWidth />
                <TextInput source="description" label={'Descrição'} fullWidth />
            </FormTab>
        </TabbedForm>
    </Create >

export default ClassCreate;