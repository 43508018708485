import { Apartment } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import InstitutionCreate from "./institution-create";
import InstitutionEdit from "./institution-edit";
import InstitutionList from "./institution-list";

export const institutionResource = {
    name: ApplicationResource.INSTITUTIONS,
    icon: Apartment,
    options: { label: "Instituições" },
    tableName: 'institution',
    list: InstitutionList,
    edit: InstitutionEdit,
    create: InstitutionCreate,
    products: ['school', 'office'],
    link: '/institutions',
};