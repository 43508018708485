
export const videoIdExtractorTransform = (data) => {
    let youTubeRegEx = /(?:https?:\/\/)?(?:(?:(?:m|www)\.)?(?:youtube(?:-nocookie)?|youtube.googleapis)\.com.*(?:v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
    let vimdeoRegEx = /(?:(?:http:|https:|)\/\/)?(?:player.|www.)?vimeo\.com\/(?:groups\/(?:\w)+\/|channels\/(?:\w)+\/|video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/i;

    const newLinks = data.links
    if (newLinks) {
        for (const video of newLinks) {
            const url = video.url
            let id = null;
            if (url.toLowerCase().includes('youtu')) {
                //regex id extract youtube
                id = url.match(youTubeRegEx)[1];
                video.source = 'youtube';
            } else if (url.toLowerCase().includes('vimeo')) {
                // regex id extract vimeo
                id = url.match(vimdeoRegEx)[1];
                video.source = 'vimeo';
            }
            video.id = id;
        }
        data.links = newLinks;
    }
    return { ...data };
}