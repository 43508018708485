import {
    AppBar, Button, CircularProgress, CssBaseline, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid, IconButton, makeStyles, Paper, Snackbar, TextField, ThemeProvider, Toolbar, Typography
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import axios from "axios";
import React, { useState } from "react";
import { getApiUrl } from "../../utils/urls";
import { ExternalProcessMailTheme } from "./theme";
import { Spacer } from "../../components/spacer";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    centered: {
        textAlign: 'center',
    },
    logo: {
        maxHeight: '48px',
        padding: '4px'
    },
    form: {
        marginTop: theme.spacing(1),
        justifyContent: "center",
    },
    formField: {
        marginTop: theme.spacing(2),
    },
    input: {
        display: 'none',
    },
    fileList: {
        alignContent: 'center',
        flexGrow: 1,
        marginLeft: theme.spacing(2),
    }
}))

export const ExtertnalProcessMailForm = ({ setStep, process, ...props }) => {

    const apiUrl = getApiUrl()
    const classes = useStyles();

    const [fields, setFields] = useState({ name: '', phone: '', email: '', description: '' })
    const [fieldErrors, setFieldErrors] = useState({ name: false, phone: false, email: false, description: false })
    const [fieldsHelperText, setFieldsHelperText] = useState({ name: '', phone: '', email: '', description: '' })
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleOpenConfirmationDialog = () => {

        const emptyFields = Object.keys(fields).map(key => key !== 'email' && fields[key].length < 1).reduce((x, y) => x && y)
        const errors = Object.keys(fields).map(key => fieldErrors[key]).reduce((x, y) => x || y)

        setDialogOpen(!(emptyFields || errors))
    }

    const handleCloseConfirmationDialog = () => {
        setDialogOpen(false)
    }

    const handleChange = (event) => {
        const id = event.target.id
        const value = event.target.value
        if (value.length < 1) {
            setFieldErrors({ ...fieldErrors, [event.target.id]: true })
            setFieldsHelperText({ ...fieldsHelperText, [event.target.id]: 'Favor preencher o campo' })
        } else if (id === "name" && value.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)) {
            setFieldErrors({ ...fieldErrors, [event.target.id]: true })
            setFieldsHelperText({ ...fieldsHelperText, [event.target.id]: 'Campo inválido' })
        } else if (id === "email" && !value.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            setFieldErrors({ ...fieldErrors, [event.target.id]: true })
            setFieldsHelperText({ ...fieldsHelperText, [event.target.id]: 'Campo inválido' })
        } else if (id === "phone" && !value.replace(/\D/g, '').match(/(\d{2})(\d{1})(\d{4})(\d{4})/)) {
            setFieldErrors({ ...fieldErrors, [event.target.id]: true })
            setFieldsHelperText({ ...fieldsHelperText, [event.target.id]: 'Campo inválido' })
        } else {
            setFields({ ...fields, [event.target.id]: value })
        }
    }

    const handleDeleteFile = (file) => {
        let newFiles = fields.files.filter((f) => f !== file)
        setFields({ ...fields, files: newFiles })
    }

    const handleInput = (event) => {
        if (fieldErrors[event.target.id]) {
            setFieldErrors({ ...fieldErrors, [event.target.id]: false })
            setFieldsHelperText({ ...fieldsHelperText, [event.target.id]: '' })
        }
    }

    const handleMaskedInput = (event) => {
        if (fieldErrors[event.target.id]) {
            setFieldErrors({ ...fieldErrors, [event.target.id]: false })
            setFieldsHelperText({ ...fieldsHelperText, [event.target.id]: '' })
        }
        var mask = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})/)
        event.target.value = `${!mask[2] ? mask[1] : '(' + mask[1] + ') '}${mask[2]}${mask[3]}${mask[4] ? '-' + mask[4] : ''}`
    }

    const handleFileChange = (event) => {
        const files = fields.files || []
        let fail = false;
        for (const file of event.target.files) {
            if (file.size > 5000000) {
                setAlertMessage(`Arquivo ${file.name} excedeu o tamanho máximo de 5MB`)
                fail = true;
                break;
            } else {
                files.push(file)
            }
        }
        if (fail) {
            setAlertOpen(true)
        } else {
            setFields({ ...fields, files: files })
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') { return }
        setAlertOpen(false)
    }

    const handleSubmit = async () => {
        setLoading(true)
        const data = { ...fields };
        const resource = 'service-requests'
        let uploadedFiles = []

        if (data.files && data.files.length > 0) {
            let fd = new FormData()
            fd.append("resource", resource);
            for (const file of data.files) {
                fd.append('files', file)
            }
            const res = await axios.post(`${apiUrl}/attachments/uploadExternal`, fd);
            if (res.status === 201 && res.data) {
                uploadedFiles = { files: res.data, resource: resource, }
            }
            data.files = uploadedFiles.files
        }

        await axios.post(`${apiUrl}/process-mail/external/${process.id}`, data).then(
            (res) => {
                if (res.status === 201 && res.data) {
                    setStep(2)
                    setLoading(false)
                    setDialogOpen(false)
                }
            }
        )
    }

    return (
        <ThemeProvider theme={ExternalProcessMailTheme}>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar variant="dense">
                    <img alt="Juristta Office" src="https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristtaoffice1.png" className={classes.logo} />
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <Grid container direction="row" spacing={3} className={classes.form}>
                    <Grid item xs={6}>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography className={classes.centered}>Favor preencher o formulário a seguir com os dados pedidos</Typography>
                            <TextField className={classes.formField} onBlur={handleChange} onInput={handleInput} error={fieldErrors.name} helperText={fieldsHelperText.name || "Ex.: João da Silva"} id={'name'} label={"Nome completo"} variant={'filled'} size={'small'} fullWidth></TextField>
                            <TextField className={classes.formField} onBlur={handleChange} onInput={handleInput} error={fieldErrors.email} helperText={fieldsHelperText.email || "Ex.: joaosilva@gmail.com"} id={'email'} type={"email"} label={"Email"} variant={'filled'} size={'small'} fullWidth></TextField>
                            <TextField className={classes.formField} onBlur={handleChange} onInput={handleMaskedInput} error={fieldErrors.phone} helperText={fieldsHelperText.phone || "Ex.: (31) 9 9999-9999 (somente números)"} id={'phone'} label={"Telefone"} variant={'filled'} size={'small'} fullWidth></TextField>
                            <TextField className={classes.formField} onBlur={handleChange} onInput={handleInput} error={fieldErrors.description} helperText={fieldsHelperText.description || "Breve resumo da situação a ser tratada"} id={'description'} label={"Relato"} variant={'filled'} multiline={true} minRows={8} size={'small'} fullWidth></TextField>
                            <Typography className={classes.formField}>Anexos</Typography>
                            <Typography style={{ fontSize: '0.8em', marginBottom: ExternalProcessMailTheme.spacing(1) }}> Aceita imagens e arquivos de formato PDF com tamanho de até 5MB</Typography>
                            <Grid item>
                                <label htmlFor="files">
                                    <Button size={'small'} variant="contained" color="primary" component="span">
                                        Anexar arquivos
                                    </Button>
                                </label>
                                <input onChange={handleFileChange} className={classes.input} accept="image/*,.pdf" id="files" multiple type="file" />
                                <Snackbar open={alertOpen} onClose={handleAlertClose} message={alertMessage} autoHideDuration={6000} />
                            </Grid>
                            <Grid item>
                                <div>
                                    {
                                        fields.files && fields.files.length > 0 && fields.files.map(file => <Typography> <a target={'_blank'} rel={'noreferrer'} href={URL.createObjectURL(file)}> {file.name}</a> [{Math.round(file.size / 1000)} KB] <IconButton size="small" onClick={() => { handleDeleteFile(file) }}><Delete /></IconButton> </Typography>)
                                    }
                                </div>
                            </Grid>
                            <Grid item container>
                                <Spacer />
                                <Button color={"primary"} onClick={handleOpenConfirmationDialog}>Enviar</Button>
                                <Dialog open={dialogOpen} onClose={handleCloseConfirmationDialog}>
                                    <DialogTitle>
                                        {loading ? 'Aguarde! Sua solicitação está sendo enviada' : 'Você confirma os dados informados neste formulário?'}
                                    </DialogTitle>
                                    <DialogContent dividers>
                                        <DialogContentText component="div">
                                            <Typography color='primary'>Nome:  <Typography color="textSecondary" component="span">{fields.name}</Typography></Typography>
                                            <Typography color='primary'>Email:  <Typography color="textSecondary" component="span">{fields.email}</Typography></Typography>
                                            <Typography color='primary'>Telefone:  <Typography color="textSecondary" component="span">{fields.phone}</Typography></Typography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseConfirmationDialog}>Cancelar</Button>
                                        <Button onClick={handleSubmit} disabled={loading}>Confirmar</Button>
                                        {loading && <CircularProgress size={24} color="primary" />}
                                    </DialogActions>
                                </Dialog>
                            </Grid >
                        </Paper >
                    </Grid >
                </Grid >
            </div >
        </ThemeProvider >
    )

}