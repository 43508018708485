import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useState } from "react";
import { Button } from "react-admin";
import { DangerousHTMLDiv } from "../../dangerousHTMLDiv";




const EvaluateWithAIButton = ({ record, ...props }) => {

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleDialogOpen = () => setDialogOpen(true)
    const handleDialogClose = () => setDialogOpen(false)

    console.log(record)






    return (
        <>
            <Button
                onClick={handleDialogOpen}
                label={'Corrigir com IA'}
            />
            <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth='lg'>
                <DialogTitle>Correção com IA</DialogTitle>
                <DialogContent>
                    <DialogContentText>Prompt a ser passado para a IA</DialogContentText>
                    <DialogContentText> Você é Ju, uma assistente de professores de direito de diversas áreas de atuação.

Sua função é corrigir peças processuais de alunos de direito, avaliando a estruturação do texto e a conformidade com os padrões da área.

Sua operação terá os seguintes passos:

1. Receber as informações da peça processual, que incluirão o nome do aluno autor, a área do direito correspondente e uma peça processual de exemplo de correção disponibilizada pelo professor.

2. Comparar o texto do aluno com o texto de exemplo fornecido pelo professor.

3. Elaborar um texto de feedback de até três parágrafos para o aluno, explicitando os erros e indicando possíveis correções, baseados no documento fornecido pelo professor.

4. Avaliar o texto em um valor numérico de 1 a 10 onde 10 é o mais próximo da solução fornecida pelo professor.

</DialogContentText>
                    <DangerousHTMLDiv html={record.task.ai_guidelines} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EvaluateWithAIButton;