import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { CustomList } from './custom-list.component';
import { useStyles } from './styles';
import { intersection, not } from './utils';
import { deburr } from 'lodash';
const sortUsers = (a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
    } else {
        return 0
    }
};

export default function UserTransferList({ data, selected, setSelected, ...props }) {

    const styles = useStyles();
    const [checked, setChecked] = useState([]);
    const [users, setUsers] = useState(data.sort(sortUsers));
    const [value, setValue] = useState('')

    const usersChecked = intersection(checked, users);
    const selectedUsersChecked = intersection(checked, selected);

    const handleCheckedSelected = () => {
        setSelected(selected.concat(usersChecked).sort(sortUsers));
        setUsers(not(users, usersChecked).sort(sortUsers));
        setChecked(not(checked, usersChecked).sort(sortUsers));
    };

    const handleCheckedUsers = () => {
        setUsers(users.concat(selectedUsersChecked).sort(sortUsers));
        setSelected(not(selected, selectedUsersChecked).sort(sortUsers));
        setChecked(not(checked, selectedUsersChecked).sort(sortUsers));
    };
    const textFilter = (text) => {
        setValue(text)
        setUsers(data.filter(item => deburr(item.name.trim().toLowerCase()).includes(deburr(text.trim().toLowerCase()))))
    }

    return (
        <>
            <Grid
                container
                className={styles.root}
            >
                <TextField
                    label={'Nome do usuário'}
                    placeholder={'Insira um nome'}
                    variant={'filled'}
                    value={value}
                    style={{ width: '560px', marginLeft: '16px' }}
                    onInput={(event) => textFilter(event.target.value)}
                />
            </Grid>
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={styles.root}
            >
                <Grid item>
                    <CustomList
                        title={'Usuários'}
                        items={users}
                        styles={styles}
                        checked={checked}
                        setChecked={setChecked}
                    />
                </Grid>

                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="contained"
                            size="small"
                            className={styles.button}
                            onClick={handleCheckedSelected}
                            disabled={usersChecked.length === 0}
                            aria-label="move selectedUsers selectedUsers"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            className={styles.button}
                            onClick={handleCheckedUsers}
                            disabled={selectedUsersChecked.length === 0}
                            aria-label="move selectedUsers users"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <CustomList
                        title={'Selecionados'}
                        items={selected}
                        styles={styles}
                        checked={checked}
                        setChecked={setChecked}
                    />
                </Grid>
            </Grid>
        </>
    );
}