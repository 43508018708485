import { Document, Page, Text } from "@react-pdf/renderer"
import React from "react"
import PDFTable from "../../components/react-pdf-table"

export const SchoolClassGradesReport = ({ data, type, styles }) => {

    const headers = [
        { id: 'user_name', text: 'Aluno', width: '25%' },
        { id: 'school_activity_name', text: 'Atividade', width: '25%' },
        { id: 'task_name', text: 'Movimentação', width: '20%' },
        { id: 'team_name', text: 'Equipe', width: '10%' },
        { id: 'role_name', text: 'Sujeito processual', width: '15%' },
        { id: 'task_completion_grade', text: 'Nota', width: '5%' },
    ]


    return (
        <Document>
            <Page orientation="landscape">
                <Text style={styles.title}>Relatório de notas por turma</Text>
                <Text style={styles.subtitle}>{data.institution.name} - Emitido em {new Date().toLocaleString()}</Text>
                <Text style={styles.subtitle}> Turma: {data.class.name} </Text>
                <PDFTable headers={headers} data={data.data} />
            </Page>
        </Document>
    )
}
