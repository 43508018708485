import { createHashHistory } from "history";
import { useContext } from "react";
import { AdminContext } from 'react-admin';
import { DefaultDashboard } from './components/dashboards/default-dashboard';
import CustomLayout from './layout';
import { LoginPage } from "./pages/login";
import { AppContext } from "./providers/app-context-provider/app.context";
import authProvider from './providers/auth-provider';
import dataProvider from './providers/data-provider';
import i18nProvider from './providers/i18n-provider';
import { ResourceProvider } from "./providers/resource-provider/resource-provider.component";
import routes from './routes';

const App = () => {

  const appContext = useContext(AppContext)
  const history = createHashHistory()

  return (
    <AdminContext
      key={'app'}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      history={history}
    >
      <ResourceProvider
        layout={CustomLayout}
        appContext={appContext}
        theme={appContext.theme}
        dashboard={DefaultDashboard}
        loginPage={LoginPage}
        title={`Juristta`}
        customRoutes={routes}
      />
    </AdminContext>
  )
}

export default App;
