import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Step, StepButton, Stepper } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import React, { useState } from 'react'
import { useNotify, useRedirect } from 'react-admin'
import dataProvider from '../../providers/data-provider'
import { SchoolActivityDateSelector } from './school-activity-date-selector'
import { Spacer } from '../../components/spacer'

export const SchoolActivityStepper = ({ school_activity, setSchoolActivity, refresh, toggleRefresh, ...props }) => {
    const notify = useNotify()
    const redirect = useRedirect()
    const id = school_activity.id

    const tasks = school_activity.tasks
    tasks.sort((a, b) => a.order - b.order)

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const toggleDialogOpen = () => setDialogOpen(!dialogOpen)

    const publishSchoolActivity = (publish) => {
        setLoading(true)
        dataProvider.update(`school-activities`, { data: { has_started: publish }, id: id })
            .then(() => setSchoolActivity({ ...school_activity, has_started: publish }))
            .then(handleClose)
            .then(() => setLoading(false))
            .then(notify('Atividade prática iniciada', 'success'))

    }

    const ConfirmationDialog = () => (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle> Deseja iniciar a atividade prática? </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>Iniciar a atividade prática fará com que ela visível e disponível para os alunos.</DialogContentText>
                <DialogContentText>Certifique-se que todas as movimentações estão devidamente configuradas antes de prosseguir.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose}>Cancelar</Button>
                <Spacer />
                <Button variant='contained' onClick={() => publishSchoolActivity(true)} color='primary' startIcon={loading ? <CircularProgress size={'24px'} /> : <Save />}>Iniciar prática</Button>
            </DialogActions >
        </Dialog >
    )


    const non_defined_dates = school_activity.tasks.filter(task => !(task.start_at && task.student_end_at && task.teacher_end_at))
    const skip = () => 0;
    const steps = [
        {
            idx: 0, title: 'Criar processos', content: 'Criar processos',
            isComplete: () => school_activity.teams.length > 0,
            redirect: () => school_activity.teams.length === 0 ? redirect(`/manage-activity-teams/${school_activity.id}/create`) : skip(),
        },
        {
            idx: 1, title: 'Definir datas das movimentações', content: 'Definir datas das movimentações',
            isComplete: () => non_defined_dates.length === 0,
            redirect: () => toggleDialogOpen()
        },
    ]

    const completed = steps.map((step) => { return step.isComplete() });

    return (
        <div style={{ marginTop: '8px', flexGrow: '1', paddingRight: '40px', }}>
            <Stepper alternativeLabel nonLinear activeStep={-1} style={{ borderRadius: '4px' }}>
                {
                    steps.map((step, idx) => (
                        <Step key={`step${idx}`}>
                            <StepButton onClick={() => { steps[idx].redirect() }} completed={completed[idx]}>
                                {step.content}
                            </StepButton>
                        </Step>
                    ))
                }
                <Button
                    onClick={handleOpen}
                    variant='contained'
                    disabled={school_activity.has_started || completed.filter(el => el !== true).length > 0}
                    style={{ alignSelf: 'center' }}
                > {school_activity.has_started ? 'Em andamento' : 'Iniciar prática'} </Button>
            </Stepper>
            <ConfirmationDialog />
            <SchoolActivityDateSelector schoolActivity={school_activity} setSchoolActivity={setSchoolActivity} refresh={refresh} toggleRefresh={toggleRefresh} open={dialogOpen} setDialogOpen={setDialogOpen} tasks={tasks} />
        </div>
    )
}