const currentLocation = window.location.href;

export const getApiUrl = () => {
    if (currentLocation.includes("teste.juristta.com")) return "https://api.teste.juristta.com";
    if (currentLocation.includes("app.juristta.com")) return "https://api.app.juristta.com";
    else return "http://localhost:3000";
};

export const getUrl = () => {
    if (currentLocation.includes("teste.juristta.com")) return "https://teste.juristta.com";
    if (currentLocation.includes("app.juristta.com")) return "https://app.juristta.com";
    else return "http://localhost:5000";
}