import { List } from "@material-ui/icons";
import React from "react";
import { BooleanInput, Button, Edit, FormTab, ReferenceInput, required, SelectInput, TabbedForm, TextInput, useRedirect } from "react-admin";
import MaskedInput from "../../components/masked-input";
import { storageProvider } from "../../providers/storage-provider";

const PartyEdit = (props) => {
    const { origin } = props.history?.location?.state || { origin: undefined };

    const redirect = useRedirect()
    const superAdmin = storageProvider.getCurrentUser()?.superAdmin

    return (
        <>
            {
                origin && <Button label="Retornar" variant='contained' startIcon={<List />} style={{ alignSelf: 'flex-start' }} onClick={() => { redirect(origin) }} />
            }
            <Edit
                mutationMode="pessimistic"
                {...props}
            >
                <TabbedForm redirect={origin || 'list'}>
                    <FormTab label={'Dados Gerais'}>
                        <TextInput source="name" label={'Nome'} fullWidth validate={[required()]} />
                        <ReferenceInput source="type.id" label="Tipo" validate={[required()]} reference="party-types" fullWidth >
                            <SelectInput optionText={superAdmin ? choice => `${choice.name} - ${choice.institution?.name}` : 'name'} optionValue='id' />
                        </ReferenceInput>
                        <BooleanInput source="isRepresented" label={'Representado pelo NPJ'} />
                        <MaskedInput source="cpf" label={'CPF'} onlyNumbers={true} mask={'999.999.999-99'} fullWidth />
                        {/*<MaskedInput source="rg" label={'RG'} onlyNumbers={true} mask={'99.999.999'} fullWidth />*/}
                        <TextInput source="rg" label={'RG'} fullWidth />
                        <TextInput source="email" label={'Email'} type='email' fullWidth />
                        <MaskedInput source="phone" onlyNumbers={true} mask={'(99) 99999-9999'} label={'Telefone'} fullWidth />
                    </FormTab>
                    <FormTab label={'Endereço'} path={'address'}>
                        <MaskedInput source="address.cep" onlyNumbers={true} mask={'99.999-999'} label={'CEP'} fullWidth />
                        <TextInput source="address.logradouro" label={'Logradouro'} fullWidth />
                        <TextInput source="number" label={'Nº'} fullWidth />
                        <TextInput source="complement" label={'Complemento'} fullWidth />
                        <TextInput source="address.bairro" label={'Bairro'} fullWidth />
                        <TextInput source="address.localidade" label={'Cidade'} fullWidth />
                        <TextInput source="address.uf" label={'UF'} fullWidth />
                    </FormTab>
                </TabbedForm>
            </Edit>
        </>
    )
}

export default PartyEdit;