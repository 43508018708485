import React from "react";
import { Datagrid, List, TextField } from "react-admin";

const ProfileList = (props) => {

    return (
        <List exporter={false} bulkActionButtons={false}
            title={'Perfis'}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label={'Nome'} />
            </Datagrid>
        </List>
    )
}

export default ProfileList;