import { PersonAdd } from "@material-ui/icons";
import React from "react";
import { Button, useRedirect } from "react-admin";

const AddNewPartyButton = ({ record }) => {

    const redirect = useRedirect()

    return (
        <Button
            style={{ marginTop: '2em' }}

            onClick={() => {
                redirect('create', '/parties', {}, {}, { redirectTo: `/processes/${record.id}/parties`, process: record.id })
            }}
            label="Nova parte"
            variant="contained"
        >
            <PersonAdd />
        </Button >
    );
}

export default AddNewPartyButton;