import { CardContent, CardMedia, Chip, Divider, IconButton, Tooltip, Typography } from '@material-ui/core'
import { AttachFile, Edit } from '@material-ui/icons'
import Vimeo from '@u-wave/react-vimeo'
import YouTube from '@u-wave/react-youtube'
import 'moment/locale/pt-br'
import React from 'react'
import { useRedirect } from 'react-admin'
import Carousel from 'react-material-ui-carousel'
import ExpandableCard from '../../components/expandable-card'
import ModelActivityInfoToolbar from './model-activity-info-toolbar'
import ViewOrientationsDialog from './view-orientations-dialog'
import { DangerousHTMLDiv } from '../../components/dangerousHTMLDiv'

const EditModelActivityButton = ({ model_activity, ...props }) => {

    const redirect = useRedirect();
    const handleClick = () => {
        redirect(`/school-model-activities/${model_activity.id}`)
    }

    return (
        <Tooltip title={'Editar a atividade matriz'}>
            <IconButton color={'text'} onClick={handleClick}>
                <Edit />
            </IconButton>
        </Tooltip>
    )
}

export const ModelActivityInfo = ({ model_activity, isStudent, ...props }) => {

    const redirect = useRedirect()

    const content = <DangerousHTMLDiv html={model_activity.details} />


    const actions = (
        <>
            {!isStudent && <EditModelActivityButton model_activity={model_activity} />}
            <Tooltip title={'Anexar'}>
                <IconButton
                    onClick={
                        () => {
                            redirect('create', '/attachments', {}, {}, { redirectTo: `/model-activity-dashboard/${model_activity.id}`, resource: 'school-model-activities', id: model_activity.id, fileSize: 25000000 })
                        }}>
                    <AttachFile />
                </IconButton>
            </Tooltip>
            <ModelActivityInfoToolbar preRequisites={model_activity.prerequisites} purpose={model_activity.proposal} />
            <ViewOrientationsDialog small={true} model_activity={model_activity} />
        </>
    );

    return (
        <ExpandableCard
            realce={true}
            title={'Informações'}
            content={content}
            action={actions}
            actionTextShow={"Orientações gerais"}
            actionTextHide={"Ocultar"}
        >
            <CardContent>
                <Typography color='textSecondary' style={{ fontSize: '3ch', fontWeight: 'bold', marginBottom: '8px' }} > {model_activity.name} </Typography>
                <Divider style={{ marginBlock: '16px' }} />
                <Typography component={'div'}> Ramos do direito:  {model_activity.themes?.map(theme => <Chip size='small' style={{ marginInline: '2px' }} clickable={false} label={theme.name} />)} </Typography>
            </CardContent>
            {model_activity.links && (
                <CardMedia>
                    <Carousel autoPlay={false} navButtonsAlwaysVisible={model_activity.links?.length > 1}>
                        {
                            model_activity.links?.map((video, index) => {
                                if (video.source === 'youtube') return <YouTube modestBranding={true} showCaptions={false} showRelatedVideos={false} annotations={false} autoplay={false} video={video.id} id={index} height={'457px'} width={'100%'} />
                                if (video.source === 'vimeo') return <Vimeo video={video.id} id={index} responsive />
                                else return null;
                            })
                        }
                    </Carousel>
                </CardMedia>
            )}
        </ExpandableCard >
    )
}