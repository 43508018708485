import { makeStyles } from "@material-ui/core/styles";

import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    //marginLeft: "auto",
    width: '100%',
    color: theme.palette.primary.dark
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  realce: {
    backgroundColor: theme.palette.primary.main
  },
  minimized: {
    backdropFilter: '#373737'
  },
  fullWidth: {
    width: '100%',
    marginRight: '44px',
    marginLeft: '4px'
  },
  cardActions: {
    padding: '0px',
    backgroundColor: theme.palette.primary.light,
    justifyContent: 'center'
  }

}));

export default useStyles;
