import { Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from "@material-ui/core"
import { AccessTime, Check, Clear, Delete, Edit, Grade, Link, Save } from "@material-ui/icons"
import axios from "axios"
import moment from 'moment'
import 'moment/dist/locale/pt-br'
import React, { useContext, useState } from 'react'
import { useNotify } from "react-admin"
import { DangerousHTMLDiv } from "../../components/dangerousHTMLDiv"
import { ListAttachments } from "../../components/list-attachments/list-attachments.component"
import { Spacer } from "../../components/spacer"
import { AppContext } from "../../providers/app-context-provider/app.context"
import cookiesProvider from "../../providers/cookies-provider"
import dataProvider from "../../providers/data-provider"
import { getApiUrl } from "../../utils/urls"

moment.locale('pt-br');

export const TaskCompletion = ({ task, team, role, ...props }) => {

    const context = useContext(AppContext)
    const notify = useNotify()
    const [open, setOpen] = useState(false)
    const [taskComp, setTaskComp] = useState(task.task_completions.find(tc => tc.team.id === team.id && tc.role.id === role.id))
    const [fields, setFields] = useState({})
    const [loading, setLoading] = useState(false)
    const [attachments, setAttachments] = useState(task.task_completions.find(tc => tc.team.id === team.id && tc.role.id === role.id)?.attachments)
    const formattedStartAt = moment(task.start_at).set('hour', 0).set('minutes', 0)
    const formattedDeadline = moment(task.student_end_at).set('hour', 23).set('minutes', 59)

    const handleSubmit = async () => {

        setLoading(true)
        const resource = 'school-activity-task-completions'
        let uploadedFiles = []

        const token = cookiesProvider.getToken();
        const cfg = {
            headers: { Authorization: `Bearer ${token}` },
            "Content-Type": "multipart/form-data",
            'Access-Control-Allow-Origin': 'https://app.juristta.com'
        }

        if (fields.files && fields.files?.length > 0) {
            let fd = new FormData()
            fd.append("resource", resource);
            fd.append("slug", context.institution.slug);
            for (const file of fields.files) {
                fd.append('files', file)
            }
            const res = await axios.post(`${getApiUrl()}/attachments/upload`, fd, cfg);
            if (res.status === 201 && res.data) {
                uploadedFiles = { files: res.data, resource: resource }
            }
        }

        if (taskComp?.id) {
            await dataProvider.update(`school-activity-task-completions`,
                { id: taskComp.id, data: { files: uploadedFiles.files, team, task, tpu: fields.tpu, text: fields.text } })
                .then((res) => {
                    setTaskComp(res.data)
                    notify('Tarefa editada!', 'success')
                    setLoading(false)
                    setOpen(false)
                })

        } else {
            await dataProvider.post(`school-activity-task-completions`, '',
                { files: uploadedFiles.files, team, task, tpu: fields.tpu, text: fields.text, role })
                .then((res) => {
                    setTaskComp(res.data)
                    notify('Tarefa completa!', "success")
                    setLoading(false)
                    setOpen(false)
                })
        }
    }

    const handleFileChange = (event) => {
        let newFiles = fields.files
        for (const file of event.target.files) {
            if (file.size > 5000000) {
                notify(`Arquivo ${file.name} excedeu o tamanho máximo de 5MB`, 'error')
                break;
            } else {
                if (fields.files) {

                    newFiles.push(file)
                } else {
                    newFiles = [file]
                }
            }
        }
        setFields({ ...fields, files: newFiles })
    }

    const handleDeleteFile = (file) => {
        const newFiles = fields.files?.filter((f) => f !== file)
        setFields({ ...fields, files: newFiles })
    }

    const handleChange = (event) => {
        setFields({ ...fields, [event.target.id]: event.target.value })
    }

    return (
        <>

            {taskComp?.evaluated && moment().isAfter(moment(task.student_end_at)) &&
                <Grid container spacing={2}>
                    <Grid container item>
                        <Chip
                            icon={<Grade />}
                            label="Atividade corrigida pelo professor"
                            clickable={false}
                            color="primary"
                        />
                    </Grid>
                    <Grid container item>
                        <Grid item style={{ flexBasis: '15%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Nota:</Typography>
                        </Grid>
                        <Grid item style={{ textAlign: "justify", flexBasis: '80%', marginLeft: '5%' }}>
                            <Typography>{taskComp?.grade}</Typography>
                        </Grid>
                    </Grid>
                    {
                        taskComp?.feedback &&
                        <Grid item container>
                            <Grid item style={{ flexBasis: '15%' }}>
                                <Typography style={{ fontWeight: 'bold' }}>Observações do professor:</Typography>
                            </Grid>
                            <Grid item style={{ textAlign: "justify", flexBasis: '80%', marginLeft: '5%' }}>
                                <DangerousHTMLDiv style={{ marginTop: '-1em' }} html={taskComp.feedback} />
                            </Grid>
                        </Grid>
                    }
                    {
                        taskComp.checklist?.length > 0 && (
                            <Grid item container>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" color="primary">Checklist de correção</FormLabel>
                                    <FormGroup>
                                        {
                                            task.checklist.map((el, idx) => <FormControlLabel
                                                control={<Checkbox checked={taskComp?.checklist.includes(idx)} disabled />}
                                                label={el.description}
                                            />)
                                        }
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        )
                    }
                </Grid>}

            <Button
                variant='outlined'
                style={{ width: '100%', height: '50px', marginTop: '8px' }}
                onClick={() => { setOpen(true) }}
                startIcon={moment().isBefore(formattedStartAt) ? <AccessTime /> :
                    moment().isAfter(formattedDeadline) ? <Clear /> :
                        taskComp ? <Check /> : <Edit />}
                disabled={moment().isBefore(formattedStartAt) || moment().isAfter(formattedDeadline)}
            >
                {moment().isBefore(formattedStartAt) ? 'Não iniciada' :
                    moment().isAfter(formattedDeadline) ? 'Prazo encerrado' :
                        taskComp ? 'Editar envio' : 'Entregar Atividade'}
            </Button>




            <Dialog open={open} maxWidth='md'>
                <DialogTitle> {`${taskComp ? 'Editar envio' : 'Entregar Atividade'} (${role.name})`} </DialogTitle>
                <DialogContent dividers>
                    {
                        task.requires_tpu && <>
                            <Typography>
                                Classificação segundo as tabelas processuais unificadas do Conselho Nacional de Justiça.
                            </Typography>
                            <Typography>
                                Acesse o link <a href='https://cnj.jus.br/sgt/consulta_publica_classes.php' target='_blank' rel='noreferrer'>consulta pública</a> para consultar os códigos.
                            </Typography>
                            <TextField id={'tpu'} label={"Código TPU"} variant={'filled'} size={'small'} fullWidth />
                        </>
                    }
                    <TextField id={'text'} label={"Observações"} variant={'filled'} style={{ marginBlock: '8px' }} defaultValue={taskComp?.text || ""} multiline={true} minRows={3} size={'small'} onChange={handleChange} fullWidth />
                    <Grid item>
                        <List component="nav" dense={true}>
                            <ListAttachments attachments={attachments} setAttachments={setAttachments} enableDelete={true} relatedResource={'school-activity-task-completions'} relatedId={taskComp?.id} />
                        </List>
                        <label htmlFor="files">
                            <Button size={'small'} variant="contained" fullWidth color="primary" component="span">
                                Anexar arquivos
                            </Button>
                        </label>
                        <Typography variant="body1">
                            Atenção! Só serão aceitos arquivos no formato PDF ou PDF/A e com tamanho máximo de 5MB.
                        </Typography>
                        <input onChange={handleFileChange} style={{ display: 'none' }} accept="application/pdf" id="files" maxSize={5000000} multiple type="file" />
                    </Grid>
                    <Grid item>
                        <List>
                            {
                                fields.files?.map(file => (
                                    <ListItem>
                                        <ListItemText primary={file.name} secondary={`${Math.round(file.size / 1000)} KB`} />
                                        <ListItemSecondaryAction>
                                            <IconButton target={'_blank'} rel={'noreferrer'} href={URL.createObjectURL(file)}>
                                                <Link />
                                            </IconButton>
                                            <IconButton size="small" onClick={() => handleDeleteFile(file)}>
                                                <Delete />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={() => setOpen(false)} disabled={loading}>Cancelar</Button>
                    <Spacer />
                    <Button variant={'contained'} color="primary" onClick={handleSubmit} disabled={loading} startIcon={loading ? <CircularProgress size={'1rem'} /> : <Save />}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}