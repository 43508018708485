export const common_settings = {
    typography: {
        fontFamily: 'Roboto',
    },
    shape: {
        borderRadius: 4,
    },
    props: {
        MuiTooltip: {
            arrow: true,
        },
    },
    spacing: 8,
    zIndex: {
        appBar: 1200,
        drawer: 1100,
    },
    topBar: {
        height: "56px",
    },
}