import React from "react";
import { BooleanField, Datagrid, List, TextField } from "react-admin";

const InstitutionList = ({ permissions, ...props }) =>
  <List
    exporter={false} bulkActionButtons={false}
    title={'Instituições'}
    {...props}
  >
    <Datagrid rowClick={'edit'}>
      <TextField source="name" label={'Nome'} />
      <TextField source="cnpj" label={'CNPJ'} />
      <TextField source="slug" label={'slug'} />
      <BooleanField source="active" label={'Ativa'} />
    </Datagrid>
  </List>

export default InstitutionList;