import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography, makeStyles } from "@material-ui/core"
import { Info, Send } from "@material-ui/icons"
import { MessageList } from "./message-list"
import { useCallback, useState } from "react"
import dataProvider from "../../providers/data-provider"

const useStyles = makeStyles(theme => ({
    main: {
        height: '100%',
    },
    chatArea: {
        paddingTop: '24px',
        flexGrow: 1,
        // border: '1px solid red',
        height: '0px',
        overflowY: 'scroll',
        scrollbarColor: `${theme.palette.background.paper} ${theme.palette.background.default}`,
    },
    controls: {
        // border: '1px solid green',
        justifyContent: 'center',
        alignContent: "center",
    },
    textField: {
        width: '50%',
    },
    iconButton: {
        alignSelf: 'center',
    }
}))

export const JuristtaChatGPTPage = (props) => {

    const styles = useStyles()

    const [waiting, setWaiting] = useState(false);
    const [messages, setMessages] = useState([]);
    const [prompt, setPrompt] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    const onDialogOpen = () => {
        setDialogOpen(true)
    }

    const onDialogClose = () => {
        setDialogOpen(false)
    }

    const handlePromptChange = useCallback(event => {
        setPrompt(event.target.value)
    }, [])

    const handleKeyDown = (event) => {
        if (!waiting && event.keyCode === 13 && prompt.length > 0) {
            sendPrompt()
        }
    }

    const sendPrompt = () => {
        setMessages(m => [...m, { role: 'user', content: prompt, timestamp: new Date().toLocaleString('pt-BR') }])
        setPrompt('')
        setWaiting(true)
        setMessages(m => [...m, { role: 'system', content: 'waiting' }])
        getReponse()
        setWaiting(false)
    }

    const getReponse = async () => {
        const response = await dataProvider.sendPromptToAI(prompt, messages)
        setMessages(m => [...m.slice(0, m.length - 1), { ...response, timestamp: new Date().toLocaleString('pt-BR') }])
        setWaiting(false)
    }

    const SendButton = ({ ...props }) => {
        return (
            <IconButton onClick={sendPrompt} disabled={prompt.trim() === ''} {...props}>
                <Send />
            </IconButton>
        )
    }


    const InfoButton = ({ ...props }) => {
        return (
            <>
                <IconButton onClick={onDialogOpen} {...props}>
                    <Info />
                </IconButton>
                <Dialog open={dialogOpen} onClose={onDialogClose}>
                    <DialogTitle>
                        Informações
                    </DialogTitle>
                    <DialogContent>
                        <Typography> A integração ChatGPT/Juristta está em fase inicial e depende de recursos externos da OpenAI. </Typography>
                        <Typography> Por favor, tenha em mente que, embora a ferramenta de IA possa ajudá-lo a gerar textos, o conteúdo gerado por ela não reflete necessariamente as visões ou opiniões da empresa. </Typography>
                        <Typography>É importante usar o material com cautela e sempre revisá-lo para garantir que esteja correto e completo antes de usá-lo.  </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color='primary' onClick={onDialogClose}>OK</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    return (
        <Grid container className={styles.main} direction="column">
            <Grid container item className={styles.chatArea}>
                <MessageList messages={messages} />
            </Grid>
            <Grid container row item className={styles.controls}>
                <InfoButton className={styles.iconButton} />
                <TextField
                    placeholder="Insira sua mensagem"
                    variant="outlined"
                    className={styles.textField}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><SendButton /></InputAdornment>
                    }}
                    value={prompt}
                    disabled={waiting}
                    onChange={handlePromptChange}
                    onKeyDown={handleKeyDown}
                />
            </Grid>
        </Grid>
    )
}