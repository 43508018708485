import { Delete, Menu } from "@material-ui/icons";
import React from "react";
import { Button, Datagrid, Edit, EmailField, FormDataConsumer, FormTab, ReferenceInput, ReferenceManyField, SelectInput, TabbedForm, TextField, TextInput, useRedirect, useRefresh } from "react-admin";
import UserImportButton from "../../components/user-import-button/user-import-button";
import dataProvider from "../../providers/data-provider";
import ClassEditAddUser from "./class-edit-add-user";
import { Spacer } from "../../components/spacer";

const ClassEdit = (props) => {

    const refresh = useRefresh()
    const redirect = useRedirect()

    const handleRemoveStudent = (classId, userId) => {
        dataProvider.delete(`classes/${classId}/student`, { id: userId })
            .then(() => { refresh(); setTimeout(() => { }, 2000) })
    }

    return (
        <Edit
            mutationMode="pessimistic"
            actions={
                <div style={{ display: 'flex', marginBottom: '16px' }}>
                    <Button variant="contained" label="Retornar" startIcon={<Menu />} onClick={() => redirect('/classes')} />
                    <Spacer />
                    <UserImportButton label={'Importar alunos para turma'} importToClass={props.id} /></div>
            }
            {...props}
        >
            <TabbedForm>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label={'Nome'} fullWidth />
                    <TextInput source="description" label={'Descrição'} fullWidth />
                    <ReferenceInput label="Período letivo" source="academic_period.id" reference="academic-periods" fullWidth>
                        <SelectInput optionText={'name'} optionValue={'id'} />
                    </ReferenceInput>
                </FormTab>
                <FormTab label={'Alunos'}>
                    <ReferenceManyField fullWidth reference="users" target="class.id" sort={{ field: 'name', order: 'ASC' }} label={'Alunos'}>
                        <Datagrid>
                            <TextField source='name' label={'Nome'} fullWidth />
                            <EmailField source='email' label={'Email'} fullWidth />
                            <FormDataConsumer>
                                {({ formData, record, ...rest }) => (
                                    <>
                                        <Button label="Remover" onClick={() => handleRemoveStudent(formData.id, record.id)} startIcon={<Delete />}></Button>
                                    </>
                                )}
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {({ formData, record, ...rest }) => (
                            <ClassEditAddUser
                                record={record}
                                resource={'classes'}
                                path={'user'}
                                title={`Adicionar alunos à turma ${record.name}`}
                                refresh={refresh}
                            />
                        )}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit >
    )
}

export default ClassEdit;