import * as React from "react";
import { useAuthenticated } from "react-admin";
import { AppContext } from "../../../providers/app-context-provider/app.context";
import { storageProvider } from "../../../providers/storage-provider";
import SchoolStudentDashboard from "../school-student-dashboard";
import SchoolTeacherDashboard from "../school-teacher-dashboard";
import StudentDashboard from "../student-dashboard";
import TeacherDashboard from "../teacher-dashboard";


export const DefaultDashboard = () => {

    useAuthenticated()

    const context = React.useContext(AppContext)
    const profiles = storageProvider.getCurrentUser()?.profiles
    const product = context.product.id === 1 ? 'school' : 'office';

    if (profiles) {
        const isStudent = profiles[0]?.name === 'Aluno'

        const tutorialCompletion = isStudent ?
            (product === 'school' ? context.schoolStudentTutorialCompletion : context.officeStudentTutorialCompletion) :
            (product === 'school' ? context.schoolManagerTutorialCompletion : context.officeManagerTutorialCompletion)

        const tutorialComplete = tutorialCompletion ? tutorialCompletion?.findIndex(el => el === false) === -1 : false
        
        if (isStudent) return product === 'school' ? <SchoolStudentDashboard tutorialComplete={tutorialComplete} /> : <StudentDashboard tutorialComplete={tutorialComplete} />
        else return product === 'school' ? <SchoolTeacherDashboard tutorialComplete={tutorialComplete} /> : <TeacherDashboard tutorialComplete={tutorialComplete} />
    }

    return <></>

}