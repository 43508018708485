import React from "react";
import { Edit, FormTab, TabbedForm, TextInput } from "react-admin";
import MaskedInput from "../../components/masked-input";

const ClientEdit = (props) =>
    <Edit
        mutationMode="pessimistic"
        {...props}
    >
        <TabbedForm>
            <FormTab label={'Dados Gerais'}>
                <TextInput source="name" label={'Nome'} fullWidth />
                <TextInput source="email" type={'email'} label={'Email'} fullWidth />
                <MaskedInput source="phone" onlyNumbers={true} mask={'(99) 99999-9999'} label={'Telefone'} fullWidth />
            </FormTab>
        </TabbedForm>
    </Edit>

export default ClientEdit;