import React from "react";
import { TextInput } from "react-admin";
import formatStringByPattern from "format-string-by-pattern";
import PropTypes from "prop-types";

const MaskedInput = ({ mask, onlyNumbers, ...props }) => {
  const parse = (input) => {
    if (onlyNumbers) { input = input.replace(/[^\d]/g, ""); }
    return formatStringByPattern(mask, input);
  };
  return <TextInput parse={parse} {...props} />;
};

MaskedInput.propTypes = {
  mask: PropTypes.string.isRequired,
  onlyNumbers: PropTypes.bool,
};

export default MaskedInput;
