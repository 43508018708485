import axios from "axios";
import React, { useState } from 'react';
import { Button, FileField, FileInput, FormWithRedirect, required, SaveButton, useNotify, useRefresh } from 'react-admin';
import { getApiUrl } from '../../utils/urls';

import { CircularProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { GetApp, Save } from "@material-ui/icons";
import IconContentAdd from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconCancel from '@material-ui/icons/Cancel';
import { read, utils } from 'xlsx';
import cookiesProvider from '../../providers/cookies-provider';
import { storageProvider } from '../../providers/storage-provider';

const UserImportButton = ({ label, importToClass, ...props }) => {

    const apiUrl = getApiUrl();
    const [showDialog, setShowDialog] = useState(false);
    const [showLog, setShowLog] = useState(false);
    const [logData, setLogData] = useState({});
    const [loading, setLoading] = useState(false);

    const institution = storageProvider.getCurrentUser()?.institution

    const urlSpreadsheet = importToClass ? 'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/Planilha_Importa%C3%A7%C3%A3o_Aluno.xlsx'
        : 'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/Planilha_Importa%C3%A7%C3%A3o_Usuario.xlsx'
    const notify = useNotify();
    const refresh = useRefresh();

    const HandleClick = () => {
        setShowDialog(true);
    }

    const handleCloseClick = () => {
        if (!loading) setShowDialog(false);
    };

    const handleReturn = () => {
        setShowLog(false);
    }

    const handleSubmit = async values => {

        setLoading(true);
        let reader = new FileReader();
        reader.readAsArrayBuffer(values.files.rawFile)

        reader.onload = async (e) => {

            const token = await cookiesProvider.getToken()

            let data = new Uint8Array(reader.result);
            let workbook = read(data, { type: 'array' });
            let sheet = workbook.Sheets[workbook.SheetNames[0]];
            const ref = sheet['!ref'].split(':');
            let new_ref = ['A4', ref[1]].join(':');
            sheet['!ref'] = new_ref
            let data_json = utils.sheet_to_json(sheet);
            let req = { institution: institution, data: data_json };

            const url = importToClass ? `${apiUrl}/users/importFromExcel/class/${importToClass}` : `${apiUrl}/users/importFromExcel`

            await axios.post(url, req, { headers: { "Authorization": token } })
                .then(
                    ({ data }) => {
                        setLoading(false);
                        setShowDialog(false);
                        setLogData(data);
                        refresh();
                        setShowLog(true);
                    }
                )
                .catch(
                    (error) => {
                        const message = error.response.data.error
                        notify(message, 'error');
                        setLoading(false);
                    }
                );
        }
    }

    return (
        <>
            <Button
                color="primary"
                startIcon={<IconContentAdd />}
                onClick={HandleClick}
                label={label}
                variant="contained"
            />
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Importar usuários">
                <DialogTitle>Importar usuários</DialogTitle>
                <FormWithRedirect
                    resource="user"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                            <DialogContent>
                                {loading ? <Typography> Operação em andamento, aguarde</Typography>
                                    : <>
                                        <Typography>
                                            Favor providenciar a planilha modelo preenchida para realizar a importação de múltiplos usuários.
                                        </Typography>

                                        <Button style={{ width: '100%', margin: 'auto', marginBlock: '8px' }} variant="contained" label='Baixar planilha modelo' startIcon={<GetApp />} href={urlSpreadsheet} target="_blank" />

                                        <FileInput source="files" label="Arquivo" accept=".xlsx" validate={[required()]}>
                                            <FileField source="src" title="title" />
                                        </FileInput>
                                    </>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={16} /> : <IconCancel />}
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                    icon={loading ? <CircularProgress size={16} /> : <Save />}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
            <Dialog
                fullWidth
                open={showLog}
                onClose={handleReturn}
                aria-label="Log de Operações">
                <DialogTitle>Log de Operações</DialogTitle>
                <DialogContent>
                    {logData.error ? <Typography> {logData.error}</Typography> : <Typography>Registros inseridos com sucesso: {logData.length}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button
                        label="Retornar"
                        onClick={handleReturn}
                    >
                        <ArrowBackIcon />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UserImportButton; 