

import * as React from "react";
import PropTypes from 'prop-types';
import { useNotify, useRecordContext } from 'react-admin';
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { handleClipboard } from "../../utils/functions";
import { FileCopy } from "@material-ui/icons";

const TextFieldWithClipboardButton = (props) => {
    const { source, url, sourceless, urlField } = props;
    const notify = useNotify()
    const record = useRecordContext(props);

    return (
        <span>
            <Tooltip title={'Copiar endereço'}>
                <IconButton onClick={() => { handleClipboard(sourceless ? url : urlField ? record[urlField] : record.url, notify) }}>
                    <FileCopy />
                </IconButton>
            </Tooltip>
            <Typography component={'span'} style={{ width: '90%' }}>{sourceless ? url : record[source]}</Typography>
        </span>
    );
}

TextFieldWithClipboardButton.defaultProps = {
    addLabel: true
}

TextFieldWithClipboardButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    sourceless: PropTypes.bool,
    url: PropTypes.string,
    urlField: PropTypes.string,
};

export default TextFieldWithClipboardButton;