import { PermIdentity } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import PartyTypeCreate from "./party-type-create";
import PartyTypeEdit from "./party-type-edit";
import PartyTypeList from "./party-type-list";

export const partyTypeResource = {
    name: ApplicationResource.PARTY_TYPE,
    icon: PermIdentity,
    options: { label: "Sujeitos processuais" },
    tableName: 'party-type',
    list: PartyTypeList,
    edit: PartyTypeEdit,
    create: PartyTypeCreate,
    link: '/party-types',
    products:['school', 'office']
};