import { createMuiTheme } from "react-admin";
import { overrides } from "./overrides";
import { common_settings } from "./common_settings";

const officeDarkTheme = createMuiTheme({

  palette: {
    type: 'dark',
    primary: {
      main: '#d49435',
    },
    secondary: {
      main: '#d49435',
    },
    error: {
      main: '#fb291b',
    },
    background: {
      default: '#1f1f1f',
      paper: '#373737',
    },

    tonalOffset: 0.2,
  },

  ...common_settings,

  overrides: overrides,

});

const officeLightTheme = createMuiTheme({

  palette: {
    type: 'light',
    primary: {
      main: '#d49435',
    },
    secondary: {
      main: '#d49435',
    },
    error: {
      main: '#fb291b',
    },
    background: {
      paper: '#D7D9CE'
    },
    tonalOffset: 0.2,
  },

  ...common_settings,

  overrides: overrides,

})

const schoolDarkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#2D69A4',
    },
    secondary: {
      main: '#C64134',
    },
    background: {
      default: '#1f1f1f',
      paper: '#373737',
    },
    error: {
      main: '#fb291b',
    },
    tonalOffset: 0.5,
  },

  ...common_settings,

  overrides: overrides,
})

const schoolLightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2D69A4',
    },
    secondary: {
      main: '#2D69A4'
    },
    background: {
      paper: '#D7D9CE'
    },
    tonalOffset: 0.5,
  },

  ...common_settings,

  overrides: overrides,
})


export {
  officeLightTheme,
  officeDarkTheme,
  schoolLightTheme,
  schoolDarkTheme
};