import {
    Avatar, List,
    ListItem, ListItemAvatar, ListItemText
} from '@material-ui/core'
import { Person } from '@material-ui/icons'
import 'moment/locale/pt-br'
import React from 'react'
import ExpandableCard from '../../components/expandable-card'

export const ProcessDoneBy = ({ process, ...props }) => {

    return (
        <ExpandableCard
            realce={true}
            title={`Gestores`}
            mini={process?.done_by?.length < 1}
        >
            <List component="nav" dense={true}>
                {process.done_by.map((user) => {
                    return (
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Person />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={user.name} secondary={user.email} />
                        </ListItem>
                    )
                })}
            </List>
        </ExpandableCard>
    )
}
