import React from "react";
import { Button, useRedirect } from "react-admin";

const AddNewActivityNoteButton = ({ record, origin, disabled, ...props }) => {

    const redirect = useRedirect()

    const redirectTo = origin === 'activities' ? `/activities/${record.id}/notes` : `/student-activity-notes/${record.id}`

    return (
        <Button
            onClick={() => {
                redirect('create', '/activity-notes', {}, {}, { redirectTo: redirectTo, origin: redirectTo, activity: record.id })
            }}
            label="Adicionar"
            variant="contained"
            disabled={disabled || false}
        >
        </Button>
    );
}

export default AddNewActivityNoteButton;