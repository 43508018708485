import React from "react";
import { BooleanField, Datagrid, List, TextField } from "react-admin";

export const ProcessTypeList = (props) =>
    <List exporter={false} bulkActionButtons={false}
        title={'Tipos de processo'}
        {...props}>
        <Datagrid rowClick={'edit'}>
            <TextField source="type" label={'Tipo'} />
            <TextField source="name" label={'Complemento'} />
            <BooleanField source='pje' label={'PJE?'} />
        </Datagrid>
    </List>