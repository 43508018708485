import {
    Timeline
} from '@material-ui/lab'
import 'moment/locale/pt-br'
import React from 'react'
import { SchoolActivityTaskPanel } from './school-activity-task-panel'
//import { NewTaskSkeletonCard } from '../../components/new-task-card'


export const SchoolActivityTasks = ({ school_activity, isStudent, team, setSchoolActivity, refresh, toggleRefresh, ...props }) => {

    const tasks = school_activity?.tasks
    tasks?.sort((a, b) => a.order - b.order)

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Timeline align='left'>
                {
                    tasks?.map(
                        (task) => <SchoolActivityTaskPanel key={task.id} school_activity={school_activity} task={task} team={team} isStudent={isStudent}
                            refresh={refresh} toggleRefresh={toggleRefresh} setSchoolActivity={setSchoolActivity} />
                    )
                }
            </Timeline>
        </div>
    )
}