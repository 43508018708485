import { Card, CardContent, CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Title } from "react-admin"
import Jitsi from "react-jitsi"
import { useParams } from 'react-router-dom'
import { jitsiAdminConfig, jitsiConfig } from "../../config/jitsi-config"
import authProvider from "../../providers/auth-provider"

const Videoconference = () => {

    const [user, setUser] = useState(undefined)
    const { id } = useParams()
    const admin = true;

    useEffect(() => {
        const getUser = async () => {
            const user = await authProvider.getIdentity()
            console.clear()
            setUser(user.data)
        }
        getUser()
    }, [])

    return (
        <Card>
            <Title title={'Juristta Office'} />
            <CardContent>
                {user &&
                    (
                        <Jitsi
                            containerStyle={{ width: "100%", height: "85vh" }}
                            roomName={id}
                            config={admin ? jitsiAdminConfig : jitsiConfig}
                            displayName={user.name}
                            loadingComponent={CircularProgress}
                            userInfo={{
                                email: user.email,
                            }}
                        />
                    )
                }
            </CardContent>
        </Card>
    )
}

export default Videoconference;