import { DateRange } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import ProcessNoteCreate from "./process-note-create";
import ProcessNoteEdit from "./process-note-edit";

export const processNoteResource = {
    name: ApplicationResource.PROCESS_NOTES,
    icon: DateRange,
    options: { label: "Notas de atendimento" },
    tableName: 'process-note',
    edit: ProcessNoteEdit,
    create: ProcessNoteCreate,
    products:['office']
};