import axios from "axios";
import { getApiUrl } from "../../utils/urls";
import cookiesProvider from "../cookies-provider";
import { storageProvider } from "../storage-provider";

const apiUrl = getApiUrl();

const publicRoutes = ['esr', 'password-reset', 'lti']

const authProvider = {

  getInstitutionsAndProducts: async ({ username, password }) => {
    return await axios.post(`${apiUrl}/auth/getInstitutionsAndProducts`, { email: username, password })
  },

  login: async ({ username, password, institutionId, productId }) => {
    return await axios.post(`${apiUrl}/auth/signin`, { email: username, password, institutionId, productId })
      .then(
        (response) => {
          const { id, token } = response.data;
          cookiesProvider.setUser(id);
          cookiesProvider.setToken(token);
          storageProvider.setCurrentUser(response.data)
          return Promise.resolve(response.data);
        }
      ).catch(
        (error) => {
          return Promise.reject(error)
        }
      );
  },

  ltiLogin: async ({ username }) => {
    return await axios.post(`${apiUrl}/auth/ltiSignin`, { email: username })
      .then(
        (response) => {
          const { id, token } = response.data;
          cookiesProvider.setUser(id);
          cookiesProvider.setToken(token)
          return Promise.resolve(response.data);
        }
      ).catch(
        (error) => {
          return Promise.reject(error)
        }
      );
  },

  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      cookiesProvider.removeUser();
      cookiesProvider.removeToken();
      storageProvider.setCurrentUser(undefined)
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () => {

    const url = window.location.href.split('/')
    if (publicRoutes.includes(url[4])) {
      return Promise.resolve();
    }

    return cookiesProvider.getUser() ? Promise.resolve() : Promise.reject();
  },

  logout: () => {
    cookiesProvider.removeToken();
    cookiesProvider.removeUser();
    storageProvider.removeCurrentUser()
    return Promise.resolve();
  },

  getIdentity: () => {

    const url = window.location.href.split('/')
    if (publicRoutes.includes(url[4])) {
      return Promise.resolve(
        { data: { id: 0, fullName: 'guest' } }
      );
    }

    const token = cookiesProvider.getToken();
    return axios.get(`${apiUrl}/auth/me`, { headers: { Authorization: `Bearer ${token}` } })
  },

  // authorization
  getPermissions: (params) => {
    const url = window.location.href.split('/')
    if (publicRoutes.includes(url[4])) {
      return Promise.resolve('guest');
    }

    return Promise.resolve(storageProvider.getCurrentUser()?.permissions)
  },
}

export default authProvider;