import { DateRange } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import AcademicPeriodCreate from "./academic-period-create";
import AcademicPeriodEdit from "./academic-period-edit";
import AcademicPeriodList from "./adacemic-period-list";

export const academicPeriodResource = {
    name: ApplicationResource.ACADEMIC_PERIODS,
    icon: DateRange,
    options: { label: "Períodos letivos" },
    tableName: 'academic-period',
    list: AcademicPeriodList,
    edit: AcademicPeriodEdit,
    create: AcademicPeriodCreate,
    link: '/academic-periods',
    products: ['school', 'office']
};