import { CircularProgress, IconButton, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core"
import { Delete, Link } from "@material-ui/icons"
import dataProvider from "../../providers/data-provider"
import { useState } from "react"

export const ListAttachments = ({ attachments, setAttachments, origin, relatedResource, relatedId, enableDelete, ...props }) => {

    const [loading, setLoading] = useState(false)

    const deleteAttachment = (id) => {
        setLoading(true)
        dataProvider.delete(`attachments/${relatedResource}/${relatedId}`, { id: id })
            .then((res) => { setAttachments(attachments.filter((at) => at.id !== res.data.id)) })
            .finally(() => setLoading(false))
    }
    if (!attachments) return <></>
    return attachments.map((attachment, index) => {
        return attachment && (
            <ListItem key={index}>
                <ListItemText
                    primary={attachment.name}
                    secondary={origin ? origin : null}
                />
                <ListItemSecondaryAction>
                    {
                        enableDelete && (
                            loading ? <CircularProgress size={24} color="error" />
                                : <IconButton onClick={() => deleteAttachment(attachment.id)} disabled={loading}>
                                    <Delete color="error" />
                                </IconButton>
                        )
                    }
                    <IconButton href={attachment.path} target={'_blank'}>
                        <Link />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        )
    })
}