


import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@material-ui/core';
import YouTube from "@u-wave/react-youtube";
import React, { useState } from 'react';
import cookiesProvider from '../../providers/cookies-provider';
import { Spacer } from '../spacer';

export const TutorialDialog = ({ tutorialComplete, ...props }) => {

    const notShowAgain = cookiesProvider.getTutorialModal()

    const [open, setOpen] = useState(notShowAgain ? !tutorialComplete : false)

    const handleClose = () => { cookiesProvider.setTutorialModal(true); setOpen(false); }
    const handleNotShowAgain = () => { cookiesProvider.setTutorialModal(false); setOpen(false); }

    return (
        <Dialog fullWidth maxWidth={'md'} open={open} onClose={handleClose}>
            <DialogTitle>Complete o tutorial do Juristta!</DialogTitle>
            <DialogContent>
                <YouTube video={'v15dsE4ZHlw'} modestBranding={true} showCaptions={false} showRelatedVideos={false} annotations={false} autoplay={false} id={'videoTutorial'} height={'457px'} width={'100%'} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNotShowAgain}>Fechar e não mostrar esta mensagem novamente</Button>
                <Spacer />
                <Button onClick={handleClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}