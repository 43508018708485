import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Snackbar, TextField, Typography } from "@material-ui/core"
import { Delete, Save } from "@material-ui/icons"
import { useState } from "react"
import { storageProvider } from "../../providers/storage-provider"

export const MessageDialog = ({ author, alias, open, onClose, onSubmit, ...props }) => {

    const [body, setBody] = useState('')
    const [attachments, setAttachments] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const userProfile = storageProvider.getCurrentUser()?.profiles
    const student = userProfile.find((profile) => profile.name === 'Aluno')

    const handleSubmit = () => {
        setDisabled(true)
        setLoading(true)
        onSubmit({ body, attachments, author });
        setTimeout(() => {
            setLoading(false)
            setDisabled(false)
        }, 2000)
    }

    const handleDeleteFile = (file) => {
        let newFiles = attachments.filter((f) => f !== file)
        setAttachments(newFiles)
    }

    const handleFileChange = (event) => {
        let files = [...attachments];
        let fail = false;
        for (const file of event.target.files) {
            if (file.size > 5000000) {
                setAlertMessage(`Arquivo ${file.name} excedeu o tamanho máximo de 5MB`)
                fail = true;
                break;
            } else {
                files.push(file)
            }
        }
        if (fail) {
            setAlertOpen(true)
        } else {
            setAttachments(files)
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') { return }
        setAlertOpen(false)
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle style={{ paddingTop: '8px', paddingBottom: '8px' }}>Nova mensagem</DialogTitle>
            <Divider />
            <DialogContent>
                {student && <DialogContentText>Obs.: As mensagens do correio do atendimento não serão utilizadas para avaliação das movimentações do aluno.</DialogContentText>}
                <div style={{ display: 'flex' }}>
                    <TextField
                        margin="dense"
                        id="author"
                        label={'Autor'}
                        disabled={true}
                        variant={'filled'}
                        value={author?.name}
                        style={{ width: '47.5%' }}
                    />
                    <span style={{ flexGrow: 1 }} />
                    <TextField
                        margin="dense"
                        id="process"
                        label={'Atendimento'}
                        disabled={true}
                        variant={'filled'}
                        value={alias}
                        style={{ width: '47.5%' }}
                    />
                </div>

                <TextField
                    autoFocus
                    margin="dense"
                    id="body"
                    type="text"
                    fullWidth
                    label={'Mensagem'}
                    disabled={disabled}
                    multiline={true}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    variant={'filled'}
                    minRows={5}
                />
                <label htmlFor="files">
                    <Button size={'small'} variant="contained" color="primary" component="span">
                        Anexar arquivos
                    </Button>
                </label>
                <input onChange={handleFileChange} accept="image/*,.pdf" id="files" multiple type="file" style={{ display: 'none' }} />
                <Snackbar open={alertOpen} onClose={handleAlertClose} message={alertMessage} autoHideDuration={3500} />

                <div>
                    {
                        attachments && attachments.length > 0 && attachments.map(
                            file => (
                                <Typography>
                                    <a target={'_blank'} style={{ color: 'inherit' }} rel={'noreferrer'} href={URL.createObjectURL(file)}> {file.name}</a> [{Math.round(file.size / 1000)} KB]
                                    <IconButton size="small" onClick={() => { handleDeleteFile(file) }}>
                                        <Delete />
                                    </IconButton>
                                </Typography>
                            )
                        )
                    }
                </div>

            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={onClose}>Fechar</Button>
                <span style={{ flexGrow: 1 }} />
                <Button disabled={disabled || body === ''} onClick={handleSubmit} startIcon={loading ? <CircularProgress size={20} /> : <Save />}>Enviar</Button>
            </DialogActions>
        </Dialog>
    )
}