import { Document, Page, Text } from "@react-pdf/renderer"
import React from "react"
import PDFTable from "../../components/react-pdf-table"

export const IndividualGradesReport = ({ data, type, styles }) => {

    const headers = [
        { id: 'process.alias', text: 'Protocolo', width: '20%' },
        { id: 'type.name', text: 'Movimentação', large: true },
        { id: 'deadline', text: 'Prazo final', format: 'date', width: '11%' },
        { id: 'type.workload_type', text: 'Tipo', format: 'workload_type', width: '22%' },
        { id: 'workload', text: 'Avaliação', format: 'workload', width: '20%' },
    ]

    return (
        <Document>
            <Page orientation="landscape">
                <Text style={styles.title}>Relatório de notas individual</Text>
                <Text style={styles.subtitle}>{data.institution.name} - Emitido em {new Date().toLocaleString()}</Text>
                <Text style={styles.subtitle}> Aluno: {data.student.name} </Text>
                <PDFTable headers={headers} data={data.data} />
            </Page>
        </Document>
    )
}
