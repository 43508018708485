import RichTextInput from "ra-input-rich-text";
import React from "react";
import { DateTimeInput, Edit, FormTab, SelectInput, TabbedForm, useNotify, useRedirect, useRefresh } from "react-admin";

const HearingEdit = (props) => {
    const { redirectTo } = props.history.location.state ? props.history.location.state : { redirectTo: "/" }

    const typeChoices = [];
    const originChoices = [];

    const redirect = useRedirect()
    const notify = useNotify()
    const refresh = useRefresh()

    const onSuccess = ({ data }) => {
        notify('Audiência criada com sucesso')
        redirect(redirectTo, undefined, {}, {}, { data })
        refresh()
    }

    return (
        <Edit {...props} onSuccess={onSuccess}>
            <TabbedForm>
                <FormTab label={'Dados Gerais'}>
                    <SelectInput source="type" choices={typeChoices} label={'Tipo'} fullWidth />
                    <SelectInput source="origin" choices={originChoices} label={'Local'} fullWidth />
                    <DateTimeInput source="date" label={'Data'} fullWidth />
                    <RichTextInput source="observations" label={'Anotações'} fullWidth />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default HearingEdit;