import { Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Button, useRedirect } from "react-admin"
import authProvider from "../../providers/auth-provider"

export const LtiLogin = (props) => {

    let hash = props.location.hash.slice(1).split(';')
    let user = hash[0].split('=')[1]
    //let inst = hash[1].split('=')[1]

    const redirect = useRedirect()

    const [message, setMessage] = useState('Autenticando usuário de ferramenta externa...')
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const login = async () => {
            await authProvider.ltiLogin({ username: user })
                .then((res) => { setSuccess(true) })
                .catch((err) => { setMessage('Não foi possivel autenticar seu usuário. Favor entrar em contato com sua instituição de ensino.'); setSuccess(false) })
        }
        login();
    }, [user])

    return <>
        <Typography>Juristta - Autenticação LTI</Typography>
        <Typography> {message} </Typography>
        {success && <Button label="Prosseguir para o Juristta" variant="contained" color="primary" onClick={() => redirect('/')} />}
    </>

}