import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';
import React, { useState } from 'react';
import { DangerousHTMLDiv } from '../../components/dangerousHTMLDiv';
import { ListAttachments } from '../../components/list-attachments/list-attachments.component';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        minWidth: '500px'
    },
}));

const ViewOrientationsDialog = ({ model_activity, small, ...props }) => {

    const [open, setOpen] = useState(false)
    const classes = useStyles();
    const isSmall = (small === undefined) ? true : small;

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Tooltip title={'Espelho de correção'}>
                {isSmall ?
                    <IconButton color={'text'} onClick={handleOpen}>
                        <ListAlt />
                    </IconButton>
                    :
                    <Button color={'text'} startIcon={<ListAlt />} onClick={handleOpen}>
                        Espelho de correção
                    </Button>
                }
            </Tooltip>

            <Dialog open={open} onClose={handleClose} scroll={"paper"} fullWidth maxWidth={'sm'}>
                <DialogTitle className={classes.dialogTitle}>
                    Espelho de correção
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText>
                        <DangerousHTMLDiv html={model_activity.guidelines} />
                    </DialogContentText>
                    <List subheader={<Typography>Anexos</Typography>}>
                        <ListAttachments attachments={model_activity.guideline_files} />
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ViewOrientationsDialog;