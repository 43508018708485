import { AssignmentInd } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";

import SchoolModelActivityTaskCreate from "./school-model-activity-task-create";
import SchoolModelActivityTaskEdit from "./school-model-activity-task-edit";

export const schoolModelActivityTaskResource = {
    name: ApplicationResource.SCHOOL_MODEL_ACTIVITY_TASKS,
    icon: AssignmentInd,
    tableName: 'school-model-activity-task',
    edit: SchoolModelActivityTaskEdit,
    create: SchoolModelActivityTaskCreate,
    products:['school']
};