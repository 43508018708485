import {
    Grid
} from '@material-ui/core'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { useEffect, useState } from 'react'
import cookiesProvider from '../../providers/cookies-provider'
import dataProvider from '../../providers/data-provider'
import { storageProvider } from '../../providers/storage-provider'
import { getApiUrl } from '../../utils/urls'
import { ProcessActivities } from './process-activities'
import { ProcessAttachments } from './process-attachments'
import { ProcessClient } from './process-client'
import { ProcessDoneBy } from './process-done-by'
import { ProcessHearings } from './process-hearings'
import { ProcessInfo } from './process-info'
import { ProcessParties } from './process-parties'
import { ProcessRecords } from './process-records'
import { ProcessRelatedProcesses } from './process-related'
import ProcessAddActivity from '../../components/process-add-activity'
import { useAuthenticated } from "react-admin";

moment.locale('pt-br');

const ProcessDashboard = (props) => {

    useAuthenticated()

    const id = props.match.params.id;

    const profiles = storageProvider.getCurrentUser()?.profiles
    const isStudent = profiles[0]?.name === 'Aluno'

    const [process, setProcess] = useState(undefined)
    const [attachments, setAttachments] = useState(undefined)

    useEffect(() => {
        (async () => {
            await dataProvider.getDashboard('processes', { id: id })
                .then(res => {
                    setProcess(res.data)
                })
        })()
    }, [id])

    useEffect(() => {
        (async () => {
            const token = cookiesProvider.getToken();
            const url = `${getApiUrl()}/processes/${id}/attachments`
            await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => setAttachments(res.data))
        })()
    }, [id])
    return (
        <Grid container spacing={2}>
            <Grid container item spacing={1} direction="row" key={'external'}>
                <Grid container item>
                    <Grid container item xs={5} lg={5} direction='column'>
                    </Grid>
                    <Grid container item spacing={1} xs={7} lg={7} direction='column' key={'controls'}>
                        <Grid container item style={{ paddingInline: '60px 45px' }}>
                            {!isStudent && process && process.active && <ProcessAddActivity style={{ flexGrow: 1 }} record={process} />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid container item spacing={1} xs={5} lg={5} direction='column' key={'left-column'}>
                        <Grid item key={'process-info'} style={{ marginTop: '28px' }}>
                            {process && <ProcessInfo process={process} isStudent={isStudent} />}
                        </Grid>
                        <Grid item key={'related-processes'}>
                            {process && <ProcessRelatedProcesses process={process} isStudent={isStudent} />}
                        </Grid>
                        <Grid item key={'process-records'}>
                            {process && <ProcessRecords process={process} attachments={attachments} isStudent={isStudent} />}
                        </Grid>
                        <Grid item key={'process-hearings'}>
                            {process && <ProcessHearings process={process} />}
                        </Grid>
                        < Grid item key={'process-users'}>
                            {process && <ProcessDoneBy process={process} />}
                        </Grid>
                        <Grid item key={'process-client'}>
                            {process && <ProcessClient process={process} />}
                        </Grid>
                        <Grid item key={'process-parties'}>
                            {process && <ProcessParties process={process} isStudent={isStudent} />}
                        </Grid>
                        <Grid item key={'process-attachments'}>
                            {attachments && <ProcessAttachments attachments={attachments} />}
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={7} lg={7} direction='column' key={'right-column'}>
                        <Grid item key={'process-activities'}>
                            {process && <ProcessActivities process={process} isStudent={isStudent} />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default ProcessDashboard;