import React from "react";
import { Create, FormTab, TabbedForm, TextInput } from "react-admin";

const PartyTypeCreate = (props) =>
    <Create {...props}>
        <TabbedForm redirect={'list'}>
            <FormTab label={'Dados Gerais'} >
                <TextInput source="name" label={'Nome'} fullWidth />
            </FormTab>
        </TabbedForm>
    </Create >

export default PartyTypeCreate;