import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 560,
        height: 500,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    list_item: {
        padding: theme.spacing(0, 1),

    },
    list_item_text: {

    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));