import React from "react";
import { List, Datagrid, TextField, BooleanField, DateField } from "react-admin";

const ExternalServiceRequestList = ({ permissions, ...props }) =>
    <List exporter={false} bulkActionButtons={false}
        title={'Pedidos de atendimento externos'}
        {...props}
    >
        <Datagrid rowClick={'edit'}>
            <TextField source="name" label={'Nome'} />
            <TextField source="url" label={'URL'} />
            <DateField source="start_at" label={'Data Início'} />
            <DateField source="end_at" label={'Data Fim'} />
            <BooleanField source="available" label={'Disponível'} />
        </Datagrid>
    </List>
    
export default ExternalServiceRequestList;