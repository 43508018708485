import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";

const PartyTypeList = (props) => {
    return (
        <List
            exporter={false}
            bulkActionButtons={false}
            sort={{ field: 'name', order: "ASC" }}
            title={'Sujeitos processuais'}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label={'Nome'} />
                <DateField source="created_at" label={'Criado em'} />
            </Datagrid>
        </List>
    )
}


export default PartyTypeList;