import { Divider, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { Title, useAuthenticated } from 'react-admin';
import SchoolStudentDashboardActivities from './school-student-dashboard-activities.component';
import SchoolStudentDashboardTasks from './school-student-dashboard-tasks.component';
import { TutorialDialog } from '../../tutorial-dialog';

const Panel = (props) => {

    const { children, value, index, ...rest } = props;

    return (
        <div hidden={value !== index} {...rest} >
            {children}
        </div>
    )
}

const SchoolStudentDashboard = ({ tutorialComplete, ...props }) => {

    useAuthenticated()

    const [val, setVal] = useState(0)

    const handleTabChange = (event, value) => {
        setVal(value);
    }

    return (
        <Grid container spacing={1} style={{ marginTop: '16px' }}>
            <Paper style={{ width: '100%' }}>
                <Title title={'Dashboard do aluno'} />
                <Grid item xs={12}>
                    <Tabs value={val} onChange={handleTabChange} centered>
                        <Tab label={'Minhas atividades'} key={'dashboard-activities'} />
                        <Tab label={'Minhas movimentações'} key={'dashboard-tasks'} />
                    </Tabs>
                </Grid>
                <Divider />
                <Panel value={val} index={0}>
                    <SchoolStudentDashboardActivities />
                </Panel>
                <Panel value={val} index={1}>
                    <SchoolStudentDashboardTasks />
                </Panel>
            </Paper>
            <TutorialDialog tutorialComplete={tutorialComplete} />
        </Grid >
    )
}

export default SchoolStudentDashboard;