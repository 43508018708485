import { Card, CardContent, Grid, makeStyles, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { Loading, Title } from "react-admin";
import { AppContext } from "../../providers/app-context-provider/app.context";
import { ProfileChangePassword } from "./change-password.component";

const UserPage = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            marginTop: '24px'
        }
    }))

    const appContext = useContext(AppContext);
    const classes = useStyles();

    const user = appContext.currentUser;
    return (
        <Grid className={classes.root}>
            <Title title={'Meu perfil'} />
            {user ?
                <MyProfile user={user} /> : <Loading />
            }
        </Grid>
    )
}

const MyProfile = ({ user }) => {

    const useStyles = makeStyles((theme) => ({
        field: {
            marginTop: '8px',
            marginBottom: '8px'
        }
    }))

    const styles = useStyles()

    return (
        <Card>
            <CardContent>
                <TextField
                    id="name"
                    name="name"
                    label="Nome"
                    value={user.name}
                    variant="standard"
                    className={styles.field}
                    fullWidth
                    disabled
                />
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={user.email}
                    variant="standard"
                    className={styles.field}
                    fullWidth
                    disabled
                />
                <TextField
                    id="phone"
                    name="phone"
                    label="Telefone"
                    value={user.phone}
                    variant="standard"
                    className={styles.field}
                    fullWidth
                    disabled
                />
                <ProfileChangePassword user={user} />
            </CardContent>
        </Card>
    )
}

export default UserPage;