import React, { useState } from "react";
import { ExternalProcessMailCheck } from "./external-process-mail-check";
import { ExternalProcessMailConfirmation } from "./external-process-mail-confirmation";
import { ExtertnalProcessMailForm } from "./external-process-mail-form";

export const ExternalProcessMailPage = (props) => {

    const [step, setStep] = useState(0)
    const [process, setProcess] = useState(undefined)
    switch (step) {
        case 0:
        default:
            return <ExternalProcessMailCheck setStep={setStep} setProcess={setProcess} />
        case 1:
            return <ExtertnalProcessMailForm setStep={setStep} process={process} />
        case 2:
            return <ExternalProcessMailConfirmation process={process} />
    }
}
