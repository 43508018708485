import { List } from "@material-ui/icons";
import RichTextInput from 'ra-input-rich-text';
import React from "react";
import { Button, Create, FormTab, ReferenceInput, SelectInput, TabbedForm, useRedirect } from "react-admin";
import cookiesProvider from "../../providers/cookies-provider";

const ProcessNoteCreate = (props) => {

    const r = useRedirect()
    const { process, redirectTo, origin } = props.history.location.state
    const user = cookiesProvider.getUser();

    return (
        <>
            <Button
                onClick={() => {
                    r(redirectTo, undefined, undefined, undefined, { origin })
                }}
                label={'Retornar'}
                style={{ alignSelf: 'flex-start' }}
                startIcon={<List />}
            />
            <Create {...props} onSuccess={() => {
                r(redirectTo, undefined, undefined, undefined, { origin })
            }}>
                <TabbedForm initialValues={{ process: process, author: user }}>
                    <FormTab label={'Dados Gerais'} >
                        <ReferenceInput source="author" label={"Autor"} reference="users" fullWidth disabled>
                            <SelectInput source="id" optionText={"name"} />
                        </ReferenceInput>
                        <ReferenceInput source="process" label={"Atendimento"} reference="processes" fullWidth disabled>
                            <SelectInput source="id" optionText={"alias"} />
                        </ReferenceInput>
                        <RichTextInput source="text" label={'Conteúdo'} fullWidth />
                    </FormTab>
                </TabbedForm>
            </Create >
        </>
    )
}

export default ProcessNoteCreate;