import { Visibility } from "@material-ui/icons";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import { ArrayInput, AutocompleteInput, BooleanInput, Button, CheckboxGroupInput, Datagrid, Edit, FormDataConsumer, FormTab, ReferenceInput, ReferenceManyField, SimpleFormIterator, TabbedForm, TextField, TextInput, TopToolbar, useNotify, useRedirect } from "react-admin";
import AddNewAttachmentButton from "../../components/add-new-attachment-button";
import AttachmentDeleteButton from "../../components/attachment-delete-button";
import { VideoUrlInput } from "../../components/video-url-input";
import { ViewFileButton } from '../../components/view-file-button';
import { videoIdExtractorTransform } from "../../utils/video-id-extractor-transform";


const SchoolModelActivityTaskEdit = (props) => {

    const redirect = useRedirect();
    const notify = useNotify()

    const ToolbarEdit = ({ basePath, data }) => (
        <TopToolbar>
            <Button label={'Dashboard'} onClick={() => redirect(`/model-activity-dashboard/${data.model_activity.id}`)}>
                <Visibility />
            </Button>
        </TopToolbar>
    )

    const onSuccess = ({ data }) => {
        notify(`Item atualizado com sucesso`);
        redirect(`/model-activity-dashboard/${data.model_activity.id}`)
    }


    const CustomTitle = ({ record }) => {
        return <span> {`Etapa matriz - ${record.name}`} </span>
    }

    return (
        <Edit
            mutationMode="pessimistic"
            actions={<ToolbarEdit />}
            title={<CustomTitle />}
            transform={videoIdExtractorTransform}
            onSuccess={onSuccess}
            {...props}
        >
            <TabbedForm warnWhenUnsavedChanges>
                <FormTab label="Dados Principais">
                    <TextInput source="name" label="Título" variant="standard" fullWidth />
                    <FormDataConsumer>
                        {({ formData, record, ...rest }) => {
                            return <CheckboxGroupInput
                                source="roles"
                                label="Partes que podem ver esta etapa"
                                variant="filled"
                                choices={record?.model_activity?.party_types?.map(item => ({ ...item, id: item.id.toString() }))}
                                optionText="name"
                                optionValue={'id'}
                                fullWidth
                                parse={value =>
                                    value && value.map(v => ({ id: v }))
                                }
                                format={value => value && value.map(v => v.id)}
                            />
                        }}
                    </FormDataConsumer>
                    <ReferenceInput allowEmpty={true} source="guideline_type.id" reference="guideline-types" label="Orientação padrão" fullWidth>
                        <AutocompleteInput source="name" optionText={"name"} emptyText={'Não selecionado'} emptyValue={null} clearAlwaysVisible
                            options={{
                                suggestionsContainerProps: {
                                    modifiers: {
                                        computeStyle: {
                                            enabled: true,
                                            gpuAcceleration: false,
                                        },
                                        applyStyle: {
                                            enabled: true,
                                        },
                                    },
                                }
                            }} fullWidth />
                    </ReferenceInput>
                    <BooleanInput source="video" label="Audiência de vídeo" />
                    <BooleanInput source="metaverse" label="Audiência no metaverso" />
                    <BooleanInput source="requires_tpu" label="Requer código TPU" />
                    <BooleanInput defaultValue={true} source="requires_grade" label="Requer correção" />
                    <ArrayInput
                        source="checklist"
                        label="Checklist de correção"
                        variant="standard"
                    >
                        <SimpleFormIterator>
                            <TextInput source="description" label="Descrição" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Instruções" path="details">
                    <RichTextInput source="details" label="Instruções" />
                    <ArrayInput source="links" label="Vídeos" variant="standard">
                        <SimpleFormIterator>
                            <TextInput source="title" label="Título" fullWidth />
                            <VideoUrlInput source="url" label="URL" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Anexos" path="attachments">
                    <ReferenceManyField fullWidth reference='attachments' target='schoolModelActivityTaskId' label={'Anexos'}>
                        <Datagrid>
                            <TextField source={'name'} label={'Nome'} />
                            <ViewFileButton />
                            <FormDataConsumer>
                                {({ formData, record }) =>
                                    <AttachmentDeleteButton path='school-model-activity-tasks' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <AddNewAttachmentButton multiple={true} />
                </FormTab>
                <FormTab label="Espelho de correção" path="guidelines">
                    <RichTextInput source="ai_guidelines" label="Espelho de correção da movimentação" />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default SchoolModelActivityTaskEdit 