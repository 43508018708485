import { PeopleOutline } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import PartyCreate from "./party-create";
import PartyEdit from "./party-edit";
import PartyList from "./party-list";

export const partyResource = {
    name: ApplicationResource.PARTIES,
    icon: PeopleOutline,
    options: { label: "Partes" },
    link: "/parties",
    tableName: 'party',
    list: PartyList,
    edit: PartyEdit,
    create: PartyCreate,
    products: ['office']
}