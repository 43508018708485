import React from "react";
import { Create, DateInput, FormTab, TabbedForm, TextInput } from "react-admin";

const AcademicPeriodCreate = (props) =>
    <Create {...props}>
        <TabbedForm redirect={'list'}>
            <FormTab label={'Dados Gerais'} >
                <TextInput source="name" label={'Nome'} fullWidth />
                <TextInput source="description" label={'Descrição'} fullWidth />
                <DateInput source="start_at" label={'Data de início'} fullWidth />
                <DateInput source="end_at" label={'Data de término'} fullWidth />
            </FormTab>
        </TabbedForm>
    </Create >

export default AcademicPeriodCreate;