import { Create } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import ActivityTypeCreate from "./activity-type-create";
import ActivityTypeEdit from "./activity-type-edit";
import ActivityTypeList from "./activity-type-list";

export const activityTypeResource = {
    name: ApplicationResource.ACTIVITY_TYPES,
    link: '/activity-types',
    icon: Create,
    options: { label: "Tipos de movimentação" },
    tableName: 'activity-type',
    list: ActivityTypeList,
    edit: ActivityTypeEdit,
    create: ActivityTypeCreate,
    products:['office']
};