import React from "react";
import { BooleanField, Datagrid, List, ReferenceField, TextField } from "react-admin";
import { storageProvider } from "../../providers/storage-provider";

const PartyList = (props) => {

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin

    return (
        <List exporter={false} bulkActionButtons={false}
            title={'Partes'}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label={'Nome'} fullWidth />
                {superAdmin && <TextField source="institution.name" label={'Instituição'} />}
                <ReferenceField source="type.id" label={'Tipo'} reference="party-types">
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source="isRepresented" label={'Representado pelo NPJ'} />
            </Datagrid>
        </List>

    )
}

export default PartyList;