import { Assignment } from "@material-ui/icons";
import ActivityCreate from "./activity-create";
import ActivityEdit from "./activity-edit";
import ApplicationResource from "../../utils/resources.enum";

export const activityResource = {
    name: ApplicationResource.ACTIVITIES,
    icon: Assignment,
    options: { label: "Movimentações" },
    link: "/activities",
    tableName: 'activity',
    edit: ActivityEdit,
    create: ActivityCreate,
    products:['office']
}