import { Button, Dialog, DialogContent, DialogTitle, styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AutocompleteArrayInput, SaveButton, SimpleForm, Toolbar } from "react-admin";
import dataProvider from "../../providers/data-provider";

const CancelButton = styled(Button)({
    marginLeft: 10,
});

const SchoolModelActivityEditAddPartyType = ({ record, refresh, ...props }) => {

    const [open, setOpen] = useState(false);
    const [partyTypes, setPartyTypes] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const response = await dataProvider.getList('party-types', { pagination: { page: 1, perPage: 999 }, sort: { field: 'party-type.name', order: 'ASC' }, filter: '' })
            setPartyTypes(response.data)
        }
        getData();
    }, []);

    const handleInsert = (values) => {
        values = { ...values };
        dataProvider.create(`school-model-activities/${record.id}/partyType`, { data: { data: values.party_type?.map(val => ({ id: val })) } })
            .then(() => { setOpen(false); refresh() })
    };

    const filterPartyTypes = (data) => {
        return data.filter(item => !record.party_types?.find(i => i.id === item.id)).sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return -1
            } else {
                return 0
            }
        });
    };

    const ToolbarPartyTypeForm = (props) => (
        <Toolbar {...props}>
            <SaveButton
                label="Salvar"
                redirect="show"
                submitOnEnter={true}
                onSave={(values) => handleInsert(values)}
            />
            <CancelButton
                variant={'contained'}
                onClick={() => setOpen(false)}>
                Cancelar
            </CancelButton>
        </Toolbar>
    );

    return (
        <div style={{ width: '100%', textAlign: 'left' }}>
            <Dialog open={open}>
                <DialogTitle>Adicionar sujeito processual</DialogTitle>
                <DialogContent style={{ width: 550, padding: 10 }} dividers>
                    <SimpleForm toolbar={<ToolbarPartyTypeForm />}>
                        <AutocompleteArrayInput
                            source="party_type"
                            label={'Sujeitos processuais'}
                            optionText={'name'}
                            opttionValue={'id'}
                            choices={filterPartyTypes(partyTypes)}
                            fullWidth />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Button onClick={() => setOpen(true)} size={'small'} variant={'contained'} color={'primary'}>Adicionar sujeito processual</Button>
        </div>
    )
};

export default SchoolModelActivityEditAddPartyType;
