import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    testContainer: {
        marginTop: '8px'
    },
    testItem: {
        marginRight: '8px'
    },
    testButton: {
        marginRight: '8px',
        alignSelf: 'center'
    },
    pdf: {
        marginTop: '8px',
    }
}))