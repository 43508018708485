
import { AppBar, CssBaseline, Grid, makeStyles, Paper, ThemeProvider, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { ExternalProcessMailTheme } from "./theme";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    instructions: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    },
    logo: {
        maxHeight: '48px',
        padding: '4px'
    },
    form: {
        marginTop: theme.spacing(1),
        justifyContent: "center",
    }
}))

export const ExternalProcessMailConfirmation = ({ process, ...props }) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={ExternalProcessMailTheme}>
            <CssBaseline />
            {
                < div className={classes.root}>
                    <AppBar position="static">
                        <Toolbar variant="dense">
                            <img alt="Juristta Office" src="https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristtaoffice1.png" className={classes.logo} />
                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={3} className={classes.form} >
                        <Grid item xs={8}>
                            <Paper elevation={3} style={{ marginTop: '5em' }} className={classes.paper}>
                                <Typography style={{ fontSize: '1.4rem', fontWeight: "bold", marginBottom: '8px' }}> Solicitação feita com sucesso! </Typography>
                                <Typography> Sua mensagem para o atendimento {process.alias} foi encaminhada à instituição de ensino e será analisada pelos responsáveis. </Typography>
                                <Typography> Por gentileza aguarde o contato da instituição de ensino. </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>

            }
        </ThemeProvider >
    )

}