import { AppBar, Button, createTheme, CssBaseline, Grid, IconButton, InputAdornment, makeStyles, Paper, TextField, ThemeProvider, Toolbar, Typography } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import React, { useState } from 'react'
import { useRedirect } from "react-admin"
import { useParams } from 'react-router-dom'
import dataProvider from "../../providers/data-provider"
import LogoJuristta from './JURISTTA_logpreta.png'

const theme = createTheme({
    palette: {
        type: 'dark',
        primary: { main: '#d49435', },
        secondary: { main: '#d49435', },
        error: { main: '#fb291b', },
    },
    typography: { fontFamily: 'Roboto', },
    shape: { borderRadius: 4, },
    props: { MuiTooltip: { arrow: true, }, },
    spacing: 8,
    zIndex: { appBar: 1200, drawer: 1100, },
    topBar: { height: "56px", }
})

export const PasswordReset = () => {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
            display: 'flex',
            flexDirection: 'column',
        },
        centered: {
            textAlign: 'center',
        },
        logo: {
            maxHeight: '48px',
            padding: '4px'
        },
        form: {
            marginTop: theme.spacing(1),
            justifyContent: "center",
        },
        formField: {
            marginTop: theme.spacing(2),
        },
        button: {
            marginTop: theme.spacing(2),
        }
    }))

    const { token } = useParams()

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)

    const [fields, setFields] = useState({
        email: '',
        password: '',
        password_conf: '',
        showPassword: false,
        showPasswordConf: false
    })


    const classes = useStyles()
    const redirect = useRedirect()

    const handleSumbit = () => {
        const params = { data: { email: fields.email } }
        setLoading(true)
        dataProvider.requestPasswordReset(params).then(
            res => {
                setError(false)
                setLoading(false);
                setMessage('Email enviado. Verifique sua caixa de entrada para mais instruções.')
            }
        ).catch((error) => {
            setError(true)
            setLoading(false);
            setMessage(error.response.data.error)
        })
    }

    const handleChangePassword = () => {

        if (fields.password.length < 1) {
            setError(true)
            setMessage('Insira a nova senha')

        } else if (fields.password.length < 8) {
            setError(true)
            setMessage('Senha muito curta. Favor criar uma senha de 8 ou mais caracteres.')

        } else if (fields.password_conf.length < 1) {
            setError(true)
            setMessage('Confirme a senha.')

        } else if (fields.password !== fields.password_conf) {
            setError(true)
            setMessage('As senhas não coincidem')

        } else {
            const params = { data: { password: fields.password, password_conf: fields.password_conf, token: token } }
            setLoading(true)
            dataProvider.passwordReset(params).then(
                res => {
                    setError(false)
                    setLoading(false);
                    setMessage('Senha redefinida com sucesso! Retornando à tela de login.')
                    setTimeout(() => redirect('/login'), 3000)
                }
            ).catch((error) => {
                setError(true)
                setLoading(false);
                setMessage(error.response.data.error)
            })
        }
    }

    const handleChange = (event) => {
        setError(false)
        setMessage('')
        setFields({ ...fields, [event.target.id]: event.target.value })
    }

    const handleClickShowPassword = () => {
        setFields({ ...fields, showPassword: !fields.showPassword });
    };
    const handleClickShowPasswordConf = () => {
        setFields({ ...fields, showPasswordConf: !fields.showPasswordConf });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleReturn = () => {
        redirect('/login')
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar variant="dense">
                        <img alt={'Juristta'} src={LogoJuristta} className={classes.logo} />
                    </Toolbar>
                </AppBar>
                {
                    token ?
                        <Grid container spacing={3} className={classes.form} >
                            <Grid item xs={8}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.centered}>  Redefinição de senha </Typography>
                                    <TextField
                                        className={classes.formField}
                                        variant="standard"
                                        placeholder="Senha"
                                        id="password"
                                        type={fields.showPassword ? 'text' : 'password'}
                                        value={fields.password}
                                        onInput={handleChange}
                                        fullWidth
                                        error={error}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {fields.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }} />
                                    <TextField
                                        className={classes.formField}
                                        variant="standard"
                                        placeholder="Confirme a senha"
                                        id="password_conf"
                                        type={fields.showPasswordConf ? 'text' : 'password'}
                                        value={fields.password_conf}
                                        onInput={handleChange}
                                        fullWidth
                                        error={error}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPasswordConf}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {fields.showPasswordConf ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                    />
                                    <Typography className={classes.centered} color={error ? 'error' : 'textPrimary'}>{!loading && message}</Typography>
                                    <Grid container>
                                        <span style={{ flexGrow: 1 }} />
                                        <Button className={classes.button} variant="contained" color="primary" onClick={handleChangePassword} disabled={false}> Redefinir senha </Button>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={3} className={classes.form} >
                            <Grid item xs={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.centered}>  Informe seu endereço de email abaixo.
                                        Se ele estiver vinculado a algum usuário, será enviado um email com as instruções para a
                                        troca de senha em breve. </Typography>
                                    <TextField className={classes.formField} variant="standard" placeholder={'Email'} value={fields.email} id={'email'} onChange={handleChange} fullWidth />
                                    <Typography className={classes.centered} color={error ? 'error' : 'primary'}>{!loading && message}</Typography>
                                    <Grid container>
                                        <Button className={classes.button} variant="contained" color="#fafafa" onClick={handleReturn}>Retornar</Button>
                                        <span style={{ flexGrow: 1 }} />
                                        <Button className={classes.button} variant="contained" color="primary" onClick={handleSumbit} disabled={fields.email.length < 1}> Solicitar alteração de senha </Button>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                }
            </div>
        </ThemeProvider >
    )
}