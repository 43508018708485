import React from "react";
import { Create, FormTab, TabbedForm, TextInput } from "react-admin";

export const ThemeCreate = (props) => {

    return (
        <Create
            title={'Tipos de processo'}
            mutationMode="pessimistic"
            {...props}
        >
            <TabbedForm redirect={'list'}>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label={'Nome'} fullWidth />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}