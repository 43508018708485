import React, { useState } from "react";
import { BooleanInput, Create, FormTab, ReferenceInput, SelectInput, TabbedForm, TextInput } from "react-admin";
import MaskedInput from "../../components/masked-input";

const InstitutionCreate = (props) => {
  const [isMatrix, setIsMatrix] = useState(true);
  return (
    <Create {...props} title="Nova Instituição">
      <TabbedForm redirect={'list'}>
        <FormTab label={'Dados Gerais'} >
          <TextInput source="name" label={'Nome'} fullWidth />
          <MaskedInput source="cnpj" onlyNumbers={true} mask={'99.999.999/9999-99'} label={'CNPJ'} fullWidth />
          <TextInput source="slug" label={'slug'} fullWidth />
          <BooleanInput
            onChange={setIsMatrix}
            source="isMatrix"
            label={'Matriz'}
            defaultChecked={true}
            defaultValue={true}
            fullWidth
          />
          {
            !isMatrix && (
              <ReferenceInput source="matrixId" label={"Matriz"} reference="institutions" fullWidth>
                <SelectInput source="id" optionText={"name"} />
              </ReferenceInput>
            )
          }
          <BooleanInput source="active" label={'Ativa'} defaultChecked={true} defaultValue={true} fullWidth />
        </FormTab>
      </TabbedForm>
    </Create >
  )
}
export default InstitutionCreate;