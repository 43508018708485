import { Create, DateTimeInput, FormTab, TabbedForm } from "react-admin"


export const SchedulingCreate = (props) => {

    return (
        <Create
            {...props}

        >
            <TabbedForm>
                <FormTab path="general" label="Agendamento">
                    <DateTimeInput label="Data do atendimento" source="date" fullWidth />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}