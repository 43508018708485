import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Step, StepButton, Stepper } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import React, { useState } from 'react'
import { useNotify, useRedirect } from 'react-admin'
import dataProvider from '../../providers/data-provider'
import { Spacer } from '../../components/spacer'

export const ModelActivityStepper = ({ model_activity, setModelActivity, ...props }) => {
    const notify = useNotify()
    const redirect = useRedirect()
    const id = model_activity.id

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const publishModelActivity = (publish) => {
        setLoading(true)
        dataProvider.update(`school-model-activities`, { data: { published: publish }, id: id })
            .then(() => setModelActivity({ ...model_activity, published: publish }))
            .then(handleClose)
            .then(() => setLoading(false))
            .then(notify('Atividade matriz publicada', 'success'))

    }

    const ConfirmationDialog = () => (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle> Deseja publicar a atividade matriz? </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>Publicar a atividade matriz fará com que ela fique como modelo disponível para criação de atividades práticas.</DialogContentText>
                <DialogContentText>Certifique-se que todas as movimentações estão devidamente criadas e configuradas antes de prosseguir.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose}>Cancelar</Button>
                <Spacer />
                <Button variant='contained' onClick={() => publishModelActivity(true)} color='primary' startIcon={loading ? <CircularProgress size={'24px'} /> : <Save />}>Publicar</Button>
            </DialogActions >
        </Dialog >
    )


    const steps = [
        {
            idx: 0, title: 'Proposta de ensino', content: 'Preencher proposta de ensino',
            isComplete: () => model_activity.proposal && model_activity.proposal?.trim() !== '',
            redirect: () => redirect(`/school-model-activities/${id}/proposal`, undefined, id, undefined, { tab: 'proposal', redirectTo: `/model-activity-dashboard/${id}` }),
        },
        {
            idx: 1, title: 'Pré-requisitos', content: 'Preencher pré-requisitos',
            isComplete: () => model_activity.prerequisites && model_activity.prerequisites?.trim() !== '',
            redirect: () => redirect(`/school-model-activities/${id}/prerequisites`, undefined, id, undefined, { tab: 'prerequisites', redirectTo: `/model-activity-dashboard/${id}` }),
        },
        {
            idx: 2, title: 'Detalhes', content: 'Preencher orientações gerais',
            isComplete: () => model_activity.details && model_activity.details?.trim() !== '',
            redirect: () => redirect(`/school-model-activities/${id}/details`, undefined, id, undefined, { tab: 'details', redirectTo: `/model-activity-dashboard/${id}` }),
        },
        {
            idx: 3, title: 'Sujeitos processuais', content: 'Adicionar sujeitos processuais',
            isComplete: () => model_activity.party_types?.length > 0,
            redirect: () => redirect(`/school-model-activities/${id}/parties`, undefined, id, undefined, { tab: 'parties', redirectTo: `/model-activity-dashboard/${id}` }),
        },
        {
            idx: 4, title: 'Movimentações', content: 'Adicionar movimentações',
            isComplete: () => model_activity.tasks.length > 0,
            redirect: () => redirect('create', '/school-model-activity-tasks', {}, {}, { redirectTo: `/model-activity-dashboard/${id}`, data: model_activity }),
        },
    ]

    const completed = steps.map((step) => { return step.isComplete() });

    return (
        <div style={{ marginTop: '8px', flexGrow: '1', paddingRight: '40px', }}>
            <Stepper alternativeLabel nonLinear activeStep={-1} style={{ borderRadius: '4px' }}>
                {
                    steps.map((step, idx) => (
                        <Step key={`step${idx}`}>
                            <StepButton onClick={() => { steps[idx].redirect() }} completed={completed[idx]}>
                                {step.content}
                            </StepButton>
                        </Step>
                    ))
                }
                <Button
                    onClick={handleOpen}
                    variant='contained'
                    disabled={model_activity.published || completed.filter(el => el !== true).length > 0}
                    style={{ alignSelf: 'center' }}
                > {model_activity.published ? 'Publicado' : 'Publicar'} </Button>
            </Stepper>
            <ConfirmationDialog />
        </div>
    )
}