import { Document } from "@react-pdf/renderer"
import React from "react"
import { ActivitiesWithoutGradeReport } from "./activities-without-grade.report"
import { ClassGradesReport } from "./class-grades-report"
import { IndividualGradesReport } from "./individual-grades.report"
import { ManagerReport } from "./manager.report"
import { ProcessWithoutManagerReport } from "./process-without-manager.report"
import { reportStyles } from "./report.styles"
import { SchedulingAuditReport } from "./scheduling-audit.report"
import { SchedulingClassReport } from "./scheduling-class.report"
import { SchedulingReport } from "./scheduling.report"
import { SchoolClassGradesReport } from "./school-class-grades-report"

export const PDFReport = ({ type, data, params }) => {

    const styles = reportStyles;
    switch (type.id) {
        case 'academic-report':
            return <ManagerReport data={data} type={type} styles={styles} />
        case 'manager-report':
            return <ManagerReport data={data} type={type} styles={styles} />
        case 'analytic-academic-report':
            return <ManagerReport data={data} type={type} analytic={true} styles={styles} />
        case 'analytic-manager-report':
            return <ManagerReport data={data} type={type} analytic={true} styles={styles} />
        case 'individual-grades-report':
            return <IndividualGradesReport data={data} styles={styles} />
        case 'class-grades-report':
            return <ClassGradesReport data={data} styles={styles} />
        case 'process-without-manager':
            return <ProcessWithoutManagerReport data={data} type={type} styles={styles} />
        case 'activities-without-grade':
            return <ActivitiesWithoutGradeReport data={data} type={type} styles={styles} />
        case 'scheduling-report':
            return <SchedulingReport data={data} styles={styles} params={params} />
        case 'scheduling-class-report':
            return <SchedulingClassReport data={data} styles={styles} />
        case 'scheduling-audit-report':
            return <SchedulingAuditReport data={data} styles={styles} />
        case 'school-class-grades-report':
            return <SchoolClassGradesReport data={data} styles={styles} />
        default:
            return <Document></Document>
    }
}
