import {
    Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
    Paper, Step, StepButton, StepLabel, Stepper, Typography
} from "@material-ui/core";
import { Close, PlayArrow } from "@material-ui/icons";
import YouTube from "@u-wave/react-youtube";
import { useContext, useState } from "react";
import { Spacer } from "../../components/spacer";
import { VerticalLinearProgress } from "../../components/vertical-linear-progress";
import { AppContext } from "../../providers/app-context-provider/app.context";
import dataProvider from "../../providers/data-provider";
import { OfficeManagerTutorialSteps, OfficeStudentTutorialSteps, SchoolManagerTutorialSteps, SchoolStudentTutorialSteps } from "./tutorial-steps";

const TutorialPage = ({ variant, ...props }) => {

    const appContext = useContext(AppContext)

    const getSteps = () => {
        if (variant === 'school-student') return SchoolStudentTutorialSteps
        else if (variant === 'school-manager') return SchoolManagerTutorialSteps
        else if (variant === 'office-student') return OfficeStudentTutorialSteps
        else if (variant === 'office-manager') return OfficeManagerTutorialSteps
        else return []
    }
    const steps = getSteps();

    const getUserCompletedSteps = () => {
        let completedSteps = []
        if (variant === 'school-student') completedSteps = appContext.schoolStudentTutorialCompletion
        else if (variant === 'school-manager') completedSteps = appContext.schoolManagerTutorialCompletion
        else if (variant === 'office-student') completedSteps = appContext.officeStudentTutorialCompletion
        else if (variant === 'office-manager') completedSteps = appContext.officeManagerTutorialCompletion
        console.log(completedSteps)
        if (!completedSteps) {
            completedSteps = new Array(getSteps().length)
            completedSteps = completedSteps.fill(false)
            return completedSteps
        } else {
            return completedSteps
        }
    }

    const userCompletedSteps = getUserCompletedSteps()

    const getUserTutorialComplete = () => {
        return userCompletedSteps.findIndex(el => el === false) === -1
    }

    const [completedSteps, setCompletedSteps] = useState(userCompletedSteps)
    const [tutorialComplete, setTutorialComplete] = useState(getUserTutorialComplete)
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const getNextUncompletedStep = () => {
        const nextIndex = completedSteps.findIndex((el) => el === false)
        return nextIndex === -1 ? steps.length : nextIndex
    }
    const nextStep = getNextUncompletedStep()

    const [activeStep, setActiveStep] = useState(nextStep === steps.length ? 0 : nextStep)

    const handleShowConfirmationDialog = (activeStep) => {
        setConfirmationDialogOpen(true);
        handleCompleteWatchVideo(activeStep)
    }

    const sendCompletedToServer = async () => {
        await dataProvider.post('users', `${appContext.currentUser.id}/completeTutorial`, { variant, steps: completedSteps })
            .then(() => {
                if (variant === 'school-student') appContext.setSchoolStudentTutorialCompletion(completedSteps)
                else if (variant === 'school-manager') appContext.setSchoolManagerTutorialCompletion(completedSteps)
                else if (variant === 'office-student') appContext.setOfficeStudentTutorialCompletion(completedSteps)
                else if (variant === 'office-manager') appContext.setOfficeManagerTutorialCompletion(completedSteps)
            })
    }

    const handleCloseConfirmationDialog = async () => {
        setLoading(false)
        setConfirmationDialogOpen(false)
    }

    const handleCloseAndWatchNext = async () => {
        setLoading(false)
        const nextStep = getNextUncompletedStep()
        setActiveStep(nextStep)
        setConfirmationDialogOpen(false)
    }

    const getProgressBarValue = () => {
        const completed = completedSteps.filter(el => el === true).length
        const percentage = (completed / steps.length) * 100
        return (100 - percentage)
    }

    const isStepCompleted = (index) => {
        return completedSteps[index]
    }

    const handleCompleteWatchVideo = async (index) => {
        const completed = completedSteps
        completed[index] = true;
        setCompletedSteps(completed)

        if (completed.findIndex(el => el === false) === -1) {
            setTutorialComplete(true)
        }
        
        await sendCompletedToServer()
            .then(() => {
                setLoading(false)

            })
    }

    return (
        <Grid container direction="column" spacing={4}>
            {/*Cabeçalho*/}
            <Grid container item alignContent="center">
                <Paper style={{ padding: '16px', width: '100%' }} elevation={3}>
                    <Typography variant={'h3'} style={{ fontWeight: '300', marginBottom: '16px' }} > Tutoriais </Typography>
                    <Typography variant={'body2'}>
                        Nossa equipe preparou uma série de tutoriais para ajudar na compreensão do sistema
                        e sanar as dúvidas mais comuns quanto à utilização do Juristta.
                    </Typography>
                </Paper>
            </Grid>
            {/* Trilha + Conteudo */}
            <Grid container item spacing={2} alignItems="flex-start">
                <Grid container item direction="column" spacing={3} xs={3}>
                    {/* Trilha */}
                    <Grid container item spacing={1}>
                        <VerticalLinearProgress style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', height: 'auto' }} value={getProgressBarValue()} variant="determinate" />
                        <Stepper connector={<div style={{ height: '26px', borderLeft: '1px solid gray', marginLeft: '9px', marginBlock: '-1px' }} />} style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }} activeStep={activeStep} nonLinear orientation="vertical">
                            {
                                steps.map((step) => {
                                    return (
                                        <Step key={`step-${step.id}`}>
                                            <StepButton
                                                onClick={() => { setActiveStep(step.id) }}
                                                completed={isStepCompleted(step.id)}
                                            >
                                                <StepLabel>
                                                    {step.title}
                                                </StepLabel>
                                            </StepButton>
                                        </Step>
                                    )
                                })
                            }
                        </Stepper>
                    </Grid>

                    {/* Controles video */}
                    <Grid container item>
                        <Paper style={{ width: '100%', padding: '16px' }}>

                            {tutorialComplete && <Typography align="left">Parabéns! Você já cumpriu todas as etapas do tutorial da plataforma Juristta!  </Typography>}

                            {
                                !tutorialComplete && (
                                    completedSteps[activeStep] ?
                                        <Typography align="left">Você já cumpriu esta etapa do tutorial! </Typography>
                                        : <Typography align="left">Para completar esta etapa do tutorial, basta assistir o vídeo até o final</Typography>
                                )
                            }
                        </Paper>
                    </Grid>
                </Grid>
                {/* Conteúdo */}
                <Grid container item spacing={1} xs>
                    <Paper style={{ width: '100%', height: '720px' }} elevation={3}>
                        <YouTube
                            showCaptions={false}
                            annotations={false}
                            modestBranding={true}
                            showRelatedVideos={false}
                            autoplay={false}
                            video={steps[activeStep].url}
                            id={`video-${activeStep}`}
                            width={'100%'}
                            height={'100%'}
                            onEnd={() => { handleShowConfirmationDialog(activeStep) }}
                        />
                    </Paper>
                    <Dialog
                        open={confirmationDialogOpen}
                        onClose={handleCloseConfirmationDialog}
                        fullWidth
                    >
                        <DialogTitle>Parabéns!</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {tutorialComplete ? 'Você concluiu o tutorial da plataforma Juristta!' : 'Você completou uma etapa do tutorial da plataforma Juristta!'}
                            </DialogContentText>
                            <DialogContentText>
                                {tutorialComplete ? 'Clique no botão abaixo para fechar esta janela.' : 'Clique no botão abaixo para confirmar o seu progresso e ir para o próximo vídeo!'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button startIcon={loading ? <CircularProgress size={24} /> : <Close />} onClick={handleCloseConfirmationDialog}>Fechar</Button>
                            <Spacer />
                            {!tutorialComplete && <Button startIcon={loading ? <CircularProgress size={24} /> : <PlayArrow />} disabled={loading} onClick={handleCloseAndWatchNext}>Assistir próximo vídeo</Button>}
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </Grid>
    )

}


export default TutorialPage;