export const overrides = {
    MuiTab: {
        root: {
            display: 'flex',
            minWidth: 0,
            justifyContent: 'space-around',
            '@media (min-width: 0px)': {
                minWidth: 0
            }
        },
    },
    MuiFormLabel: {
        MuiFocused: {
            color: '#688cae'
        }
    },
    MuiTabs: {
        flexContainer: {
            justifyContent: 'space-around'
        }
    },
    MuiSvgIcon: {
        root: {
            fontSize: '1.2rem'
        }
    },
    RaMenuItemLink: {
        active: {//
            color: 'black',
            //  marginBlock: '4px',
            backgroundColor: 'white',
            //marginInline: '16px',
            //paddingBlock: '8px',
            borderRadius: '8px',
            '& svg': {
                color: 'black',
                // marginLeft: '-10px',
            }
        },
    },
    RaSidebar: {
        root: {
            marginRight: '16px',
        },
        fixed: {
            height: 'inherit',
        }
    },
    RaLayout: {
        root: {
            overflowY: 'scroll',
        },
        content: {
            paddingTop: '64px'
        },
        appFrame: {
            marginTop: '0px',
            '@media(min-width: 0px)': {
                marginTop: '0px',
            }
        }
    },
    RaMenu: {
        main: {
            paddingTop: '24px',
            marginBottom: '0px',
        }
    },
}