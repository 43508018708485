import { Tooltip } from "@material-ui/core";
import { green, purple, red, yellow } from "@material-ui/core/colors";
import { WatchLater } from "@material-ui/icons";
import moment from "moment/moment";
import { useRecordContext } from "react-admin";

export const TimeIconField = (props) => {

    const { source } = props;
    const record = useRecordContext(props);
    const time = moment(record[source])
    const difference = time.diff(moment(), "days")
    let color;
    let title;

    if (difference >= 5) {
        color = green;
        title = '5 dias ou mais até o prazo'
    } else if (difference >= 2 && difference <= 4) {
        color = yellow;
        title = '2 a 4 dias de prazo'
    } else if (difference >= 0 && difference <= 1) {
        color = red;
        title = 'Menos de 1 dia para o prazo'
    } else {
        color = purple;
        title = 'Prazo vencido'
    }

    return <>
        <Tooltip title={title}>
            <WatchLater htmlColor={color[500]} />
        </Tooltip>
    </>


}