import React from "react";
import { Create, FormTab, PasswordInput, required, TabbedForm, TextInput, useNotify, useRedirect } from "react-admin";
import MaskedInput from "../../components/masked-input";

const UserCreate = (props) => {

    const redirect = useRedirect()

    const notify = useNotify();

    const onError = (error) => {
        notify('Já existe um usuário com o email informado', 'error')
    }

    const validatePassword = (value, allValues, props) => {
        return value === allValues.password ? undefined : 'As senhas devem coincidir'
    }

    const emailHelperText = 'Para melhor utilização do sistema de notificações via email, não recomendamos a utilização de endereços de email @outlook, @hotmail e @live.';

    const onSuccess = ({ data }) => {
        redirect(`/users/${data.id}/profiles`)
    }
    return (
        <Create {...props} title={'Novo usuário'} onFailure={onError} onSuccess={onSuccess}>
            <TabbedForm>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label={'Nome'} fullWidth validate={[required()]} isRequired />
                    <TextInput source="email" label={'Email'} type={'email'} validate={[required()]} isRequired fullWidth helperText={emailHelperText} />
                    <MaskedInput source="phone" onlyNumbers={true} mask={'(99) 99999-9999'} label={'Telefone'} fullWidth />
                    <PasswordInput source="password" label={'Senha'} fullWidth />
                    <PasswordInput source="passwordConfirmation" label={'Confirme a senha'} validate={validatePassword} fullWidth />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

export default UserCreate;