import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { DashboardMenuItem, Menu, MenuItemLink } from 'react-admin';
import { useSelector } from "react-redux";
import { AppContext } from '../../providers/app-context-provider/app.context';
import { menuLayoutProvider } from '../../providers/menu-layout-provider';
import CollapsableMenuItem from './collapsable-menu-item.component';

import { Spacer } from '../../components/spacer';

const useStyles = makeStyles((theme) => ({
    menuSchool: {
        backgroundColor: theme.palette.primary.dark,
        backgroundImage: `url(${'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/background_menu_transparente.png'})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        minHeight: '96vh'
    },
    menuOffice: {
        backgroundColor: theme.palette.primary.dark,
        backgroundImage: `url(${'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/fundo_juristta_office.png'})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        minHeight: '96vh'
    },

    menuItem: {
        paddingBlock: '12px',
    }
}))

export const MenuJuristta = (props) => {

    const appContext = React.useContext(AppContext)


    const open = useSelector((state) => state.admin.ui.sidebarOpen);

    const styles = useStyles();

    const [state, setState] = React.useState({

    });

    const menus = menuLayoutProvider.getAvailableMenus(appContext)

    const handleToggle = (menu) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Menu className={appContext.product.id === 1 ? styles.menuSchool : styles.menuOffice} {...props}>
            {
                appContext.product.id === 1 ?
                    <img src={'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristtaschool.png'} alt='Logo Juristta School' />
                    : <img src={'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/Logo_Juristta_Office_Branca.png'} alt='Logo Juristta Office' style={{ paddingBlock: '40px' }} />
            }
            <DashboardMenuItem />
            {
                menus.map(menu => {

                    return (
                        menu.children?.length > 0 ? <CollapsableMenuItem
                            key={menu.id}
                            name={menu.label}
                            icon={<menu.icon />}
                            handleToggle={() => handleToggle(menu.id)}
                            isOpen={state[menu.id] || false}
                            sidebarIsOpen={open}
                            className={styles.menuItem}
                            dense
                            children={
                                menu.children && menu.children.map(child => (
                                    <MenuItemLink
                                        key={child.id}
                                        to={child.path}
                                        primaryText={child.label}
                                        leftIcon={<child.icon />}
                                        sidebarIsOpen={open}
                                        //className={styles.menuItem}
                                        dense
                                    />
                                ))
                            }
                        />
                            :
                            <MenuItemLink
                                key={menu.id}
                                to={menu.path}
                                primaryText={menu.label}
                                leftIcon={<menu.icon />}
                                sidebarIsOpen={open}
                                className={styles.menuItem}
                                dense
                            />
                    )
                })
            }
            <Spacer />
            <img alt='juristta' src="https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristta_60x50.png" style={{ maxWidth: '55px', alignSelf: 'center' }} />
        </Menu>
    );
};