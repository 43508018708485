import { InputAdornment } from "@material-ui/core";
import { TextInput } from "react-admin";

export const VideoUrlInput = (props) => (
    <TextInput
        InputProps={{
            startAdornment: (
                <InputAdornment position='start'>
                    <img alt="youtube" src='https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_youtube.png' width='24px' height='24px' />
                    <span style={{ marginInline: '2px', fontWeight: 'bolder', fontSize: '24' }}>/</span>
                    <img alt='vimeo' src='https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_vimeo.png' width='24px' height='24px' />
                </InputAdornment>
            )
        }}
        {...props}
    />
)