import { List } from "@material-ui/core"
import ExpandableCard from "../../components/expandable-card"
import { ListAttachments } from "../../components/list-attachments/list-attachments.component"

export const SchoolTeamAttachments = ({ team }) => {
    return (
        <ExpandableCard realce={true} title={`Autos do processo`} mini={!team.attachments || team.attachments.length === 0}>
            <List component="nav" dense={true}>
                <ListAttachments attachments={team.attachments} enableDelete={false} />
            </List>
        </ExpandableCard>
    )
}