import { Typography } from "@material-ui/core";
import {
    Datagrid, DateField, DateInput,
    ExportButton,
    FilterButton, List,
    SelectInput,
    TextField, TextInput, TopToolbar,
    sanitizeListRestProps,
    useRecordContext
} from "react-admin";
import * as XLSX from "xlsx";
import { DateFieldIntl } from "../../components/date-field-intl";
import { storageProvider } from "../../providers/storage-provider";
import SchedulingImportButton from "./scheduling-import-button";

export const SchedulingList = (props) => {

    const user = storageProvider.getCurrentUser()
    const profiles = user?.profiles
    const isStudent = profiles[0]?.name === 'Aluno'

    const SchedulingListActions = (props) => {
        const {
            className,
            exporter,
            filters,
            maxResults,
            ...rest
        } = props;

        return (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                <FilterButton />
                <SchedulingImportButton />
                <ExportButton />
            </TopToolbar>
        );
    }

    const StudentField = (props) => {
        const record = useRecordContext(props);

        return (
            <>
                {record?.memberships?.map((membership, idx) => <div>
                    <Typography variant="body2">{`${idx + 1}. ${truncateName(membership?.student?.name)} - ${membership?.class?.name}`}</Typography>
                </div>)}
            </>
        )
    }

    const isLowerCase = (str) => {
        return str === str?.toLowerCase() && str !== str?.toUpperCase();
    }

    const truncateName = (name) => {
        if (name) {
            const [firstName, ...lastName] = name.split(' ')
            const ln = lastName[0] ? isLowerCase(lastName[0]) ? lastName[1][0] + '.' : lastName[0][0] + '.' : ''
            return `${firstName} ${ln}`
        }
    }

    const ManagerField = (props) => {
        const record = useRecordContext(props);

        return (
            <>
                {record?.managers?.map((manager, idx) => <div><Typography variant="body2">{`${idx + 1}. ${truncateName(manager.name)}`}</Typography></div>)}
            </>
        )
    }

    const filters = [
        <DateInput label='Data' source='date' alwaysOn />,
        <TextInput label='Cliente' source='client' />,
        <TextInput label='Aluno' source='student' />,
        <SelectInput label="Atendimento" source="available" emptyText={"Todos"} emptyValue={"all"} choices={[{ name: "Sem atendimento", id: "sa" }, { name: "Com atendimento", id: "ca" }]} alwaysOn />,
    ]

    const filter = isStudent ? {} : {}

    const exporter = (schedulings) => {

        const isChrome = navigator.userAgent.toLowerCase().indexOf("chrome") >= 0 ? true : false;

        const exportData = schedulings.map((scheduling) => {
            const dateWithJSTZOffset = new Date(scheduling.date)
            if (isChrome) dateWithJSTZOffset.setSeconds(dateWithJSTZOffset.getSeconds() + 28) //Descubra
            return {
                'Data': dateWithJSTZOffset,
                'Cliente': scheduling.client?.name,
                'Telefone': scheduling.client?.phone,
                'Gestor': scheduling.managers.map(manager => manager.name).join(', '),
                'Tipo': scheduling.type?.name,
                'Período Letivo': scheduling.academic_period?.name,
                'Início candidatura': new Date(scheduling.min_apply_date),
                'Final candidatura': new Date(scheduling.max_apply_date),
                'Alunos': scheduling.memberships.map(m => m.student.name).join(', ')
            }
        })
        let ws = XLSX.utils.json_to_sheet(exportData, { cellDates: true, dateNF: 'dd/mm/yyyy hh:mm:ss' })
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Agendamentos')
        XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }); // generate a binary string in web browser
        XLSX.writeFile(wb, `agendamentos.xlsx`);

    }

    const customRowStyle = (record, index) => ({
        backgroundColor: record.process ? '#595959' : '#424242'
    })

    return (

        <List
            exporter={exporter}
            bulkActionButtons={false}
            actions={!isStudent && <SchedulingListActions />}
            filters={!isStudent && filters}
            filter={filter}
            filterDefaultValues={{ 'available': "sa" }}
            empty={isStudent && <><Typography>Não existem agendamentos disponíveis no momento</Typography></>}
            sort={{ field: "date", order: "ASC" }}
            {...props}
        >
            <Datagrid rowClick={!isStudent && 'edit'} rowStyle={customRowStyle}>
                <DateFieldIntl label="Data/Hora" source="date" />
                <TextField label="Cliente" source="client.name" />
                <ManagerField label="Gestores" />
                <TextField label="Tipo" source="type.name" />
                <TextField label="Período Letivo" source="academic_period.name" />
                <DateField label="Início candidatura" source="min_apply_date" />
                <DateField label="Final candidatura" source="max_apply_date" />
                <StudentField label="Alunos" />
            </Datagrid>
        </List>
    )
}