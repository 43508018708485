import { Grid } from "@material-ui/core";

const TabPanel = ({ children, value, index, ...props }) => {

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...props}
        >
            {value === index && (
                <Grid spacing={2} container>
                    {children}
                </Grid>
            )}
        </div>
    );
}

/*TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};*/

export default TabPanel;