import React from "react";
import { Create, FormTab, ReferenceInput, SelectInput, TabbedForm, TextInput } from "react-admin";

const ProcessCreate = (props) => {

    const { alias, institution, client } = props.location.state ? props.location.state : { alias: null, institution: null }
    return (
        <Create {...props}>
            <TabbedForm initialValues={{ alias: alias }} redirect={'list'}>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="alias" label={'Protocolo'} fullWidth disabled />
                    <ReferenceInput label={'Instituição'} initialValue={institution} source="institution.id" reference="institutions" fullWidth disabled>
                        <SelectInput optionText={"name"} fullWidth />
                    </ReferenceInput>
                </FormTab>

                <FormTab label={'Cliente'}>
                    <ReferenceInput label={'Cliente'} initialValue={client} source="client.id" reference="clients" fullWidth disabled>
                        <SelectInput optionText={"name"} fullWidth />
                    </ReferenceInput>
                </FormTab>
            </TabbedForm>
        </Create >
    )
}

export default ProcessCreate;