import {
    Avatar, IconButton, List,
    ListItem, ListItemAvatar, ListItemSecondaryAction,
    ListItemText, Tooltip
} from '@material-ui/core'
import { AccountCircle, Edit, GroupAdd } from '@material-ui/icons'
import 'moment/locale/pt-br'
import React from 'react'
import { useRedirect } from 'react-admin'
import ExpandableCard from '../../components/expandable-card'

export const ProcessParties = ({ process, isStudent, ...props }) => {

    const sorted = process.parties.sort((a, b) => b.isRepresented - a.isRepresented)
    const redirect = useRedirect()
    return (
        <ExpandableCard
            realce={true}
            title={`Partes`}
            action={
                <>
                    <Tooltip title={'Adicionar parte'}>
                        <IconButton onClick={() => { redirect('create', '/parties', {}, {}, { redirectTo: `/process-dashboard/${process.id}`, process: process.id }) }}>
                            <GroupAdd />
                        </IconButton>
                    </Tooltip>
                </>
            }
            mini={process?.parties?.length < 1}
        >
            <List component="nav" dense={true}>
                {sorted.map((user) => {
                    return (
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={user.isRepresented ? { backgroundColor: '#44bb33' } : {}}>
                                    <AccountCircle />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${user.type.name} - ${user.name}`}
                                secondary={user.phone}
                                primaryTypographyProps={{ style: { color: user.isRepresented ? '#44bb33' : 'inherit' } }}
                                secondaryTypographyProps={{ style: { color: user.isRepresented ? '#44bb33' : 'inherit' } }}
                            />
                            <ListItemSecondaryAction>
                                <Tooltip title={'Editar parte'}>
                                    <IconButton onClick={() => { redirect('edit', '/parties', user.id, undefined, { origin: `/process-dashboard/${process.id}` }) }}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip >
                            </ListItemSecondaryAction >
                        </ListItem >
                    )
                })}
            </List >
        </ExpandableCard >
    )
}