import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@material-ui/core";
import { AccountCircle, GroupAdd } from "@material-ui/icons";
import React from "react";
import { useRedirect } from "react-admin";
import ExpandableCard from "../../components/expandable-card";

export const ModelActivityParties = ({ model_activity, refresh }) => {


    const redirect = useRedirect();
    const id = model_activity.id
    return (
        <>
            <ExpandableCard
                realce={true}
                title={`Sujeitos processuais`}
                action={
                    <>
                        <Tooltip title={'Adicionar parte'}>
                            <IconButton onClick={() => redirect(`/school-model-activities/${id}/parties`, undefined, id, undefined, { tab: 'parties', redirectTo: `/model-activity-dashboard/${id}` })}>
                                <GroupAdd />
                            </IconButton>
                        </Tooltip>
                    </>
                }
                mini={model_activity?.party_types?.length < 1}
            >
                <List component="nav" dense={true}>
                    {model_activity.party_types.map((party) => {
                        return (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AccountCircle />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText> {party.name} </ListItemText>
                            </ListItem >
                        )
                    })}
                </List >
            </ExpandableCard>
        </>
    )
}