import { Button, Card, CardContent, List, ListItem, makeStyles, Typography } from "@material-ui/core";
import { Mail, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Loading, useRedirect } from "react-admin";
import dataProvider from "../../providers/data-provider";
import { Spacer } from "../../components/spacer";

const SearchResults = (props) => {

    const params = new URLSearchParams(props.location.search)
    const filter = params.get('filter')
    const value = params.get('value')

    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getData = async () => {
            if (value.length > 0) {
                setLoading(true)
                const results = await dataProvider.globalSearch(filter, value)
                setResults(results.data.processes)
                setLoading(false)
            }
        }
        getData()
    }, [value, filter])

    return (
        loading ? <Loading /> :
            <div>
                <Typography variant={'h5'} component={'h1'}>Resultados da busca:</Typography>
                <List>
                    {
                        results.length === 0 ? <Typography> Não foram encontrados resultados para os parâmetros informados. </Typography> : results.map((item) => {
                            return <ProcessCard process={item} />
                        })
                    }
                </List>
            </div>
    )
}

const ProcessCard = ({ process }) => {

    const useStyles = makeStyles({
        root: {
            width: '100%',
            margin: 'auto',
            display: 'flex'
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    });

    const classes = useStyles();
    const redirect = useRedirect()

    return (
        <ListItem key={process.id}>
            <Card className={classes.root}>
                <CardContent>
                    <Typography color='textSecondary' className={classes.title}>Protocolo</Typography>
                    <Typography className={classes.pos}>
                        {process.alias}
                    </Typography>
                    {process.legal_process_code && <>
                        <Typography color='textSecondary' className={classes.title}>Número do processo</Typography>
                        <Typography className={classes.pos}>
                            {process.legal_process_code}
                        </Typography>
                    </>
                    }
                </CardContent>
                <CardContent>
                    <Typography component={'span'} color='primary'> Cliente: </Typography>
                    <Typography component={'span'}> {process.client.name} </Typography>
                    <br />
                    {process.parties.map((party) => {
                        return (
                            <>
                                <Typography component={'span'} color='primary'> Parte: </Typography>
                                <Typography component={'span'}> {party.name} </Typography>
                                <br />
                            </>
                        )
                    })}
                    {process.done_by.map((manager) => {
                        return (
                            <>
                                <Typography component={'span'} color='primary'> Responsável: </Typography>
                                <Typography component={'span'}> {manager.name} </Typography>
                                <br />
                            </>
                        )
                    })}
                </CardContent>
                <CardContent>
                    <Typography color='textSecondary' className={classes.title}>Status</Typography>
                    <Typography className={classes.pos}>
                        {process.active ? 'Aberto' : 'Arquivado'}
                    </Typography>
                </CardContent>
                <Spacer />
                <CardContent style={{ maxWidth: '20%', paddingBottom: '16px', alignSelf: 'center', justifySelf: 'center' }}>
                    <Button startIcon={<Visibility />} onClick={() => redirect(`/process-dashboard/${process.id}`)}>Dashboard</Button>
                    <Button startIcon={<Mail />} onClick={() => redirect(`/process-mail/${process.id}`)}>Correio do atendimento</Button>
                </CardContent>
            </Card>
        </ListItem >
    )
}

export default SearchResults;