import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import dataProvider from "../../providers/data-provider";

export const AddRelatedProcessDialog = ({ process, refresh, ...props }) => {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(undefined);
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true)
            dataProvider.getList('processes', { sort: { field: 'alias', order: 'DESC' }, pagination: { page: 1, perPage: 5000 } })
                .then((res) => {
                    setData(res.data.filter(p => p.id !== process.id))
                    setLoading(false)
                })
        })()
    }, [process.id])

    const handleInsert = () => {
        dataProvider.create(`processes/${process.id}/related`, { data: { id: selected.id } })
            .finally(() => { setOpen(false); refresh(); })
    };

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <>
            <Tooltip title={'Adicionar processo conexo'}>
                <Button onClick={handleOpen} variant={'contained'} color={'primary'}> Adicionar processo conexo </Button>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Adicionar atendimento relacionado</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id='select'
                        options={loading ? [] : data}
                        getOptionLabel={process => process.alias || 'Selecione...'}
                        noOptionsText={'Sem resultados'}
                        value={selected}
                        inputValue={inputValue}
                        onInputChange={(event, value) => setInputValue(value.replace(/(\d{4})(\d{2})(\d{2})(\w{8})/, "$1.$2.$3/$4"))}
                        size={'small'}
                        style={{ width: '450px' }}
                        onChange={(event, value) => { setSelected(value) }}
                        renderInput={(params) => <TextField {...params} label={'Atendimento'} variant='filled' />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"} color={'textPrimary'}>Cancelar</Button>
                    <span style={{ flexGrow: 1 }} />
                    <Button onClick={handleInsert} variant={"contained"} color={'primary'}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}