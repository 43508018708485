import { Button, Dialog, DialogContent, DialogTitle, styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SaveButton, SelectInput, SimpleForm, Toolbar } from "react-admin";
import dataProvider from "../../providers/data-provider";

const CancelButton = styled(Button)({
    marginLeft: 10,
});

const UserEditAddInstitution = ({ record, refresh, ...props }) => {

    const [open, setOpen] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [selectedInstitution, setSelectedInstitution] = useState({
        name: ' ',
        email: ' '
    })

    useEffect(() => {
        const getData = async () => {
            const response = await dataProvider.getList('institutions', { pagination: { page: 1, perPage: 999 }, sort: { field: 'institution.name', order: 'ASC' }, filter: '' })
            setInstitutions(response.data)
        }
        getData();
    }, []);

    const handleInsert = (values) => {
        values = { ...values };
        dataProvider.create(`users/${record.id}/institution`, { data: { id: selectedInstitution.id } })
            .then(() => {  refresh(); setOpen(false); })

    };

    const filterInstitutions = (data) => {
        return data.filter(item => !record.institutions?.find(i => i.id === item.id)).sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return -1
            } else {
                return 0
            }
        });
    };

    const changeSelectedInstitution = (id) => {
        const institution = institutions.find(u => u.id === id);
        setSelectedInstitution(institution)
    }

    const ToolbarInstitutionForm = (props) => (
        <Toolbar {...props}>
            <SaveButton
                label="Salvar"
                redirect="show"
                submitOnEnter={true}
                onSave={(values) => handleInsert(values)}
            />
            <CancelButton
                variant={'contained'}
                onClick={() => setOpen(false)}>
                Cancelar
            </CancelButton>
        </Toolbar>
    );

    return (
        <div style={{ width: '100%', textAlign: 'left' }}>
            <Dialog open={open}>
                <DialogTitle>Vincular instituição ao usuário {record.name}</DialogTitle>
                <DialogContent style={{ width: 550, padding: 10 }} dividers>
                    <SimpleForm toolbar={<ToolbarInstitutionForm />}>
                        <SelectInput
                            source="institution"
                            label={'Instituições'}
                            optionText={"name"}
                            onChange={event => changeSelectedInstitution(event.target.value)}
                            choices={filterInstitutions(institutions)}
                            fullWidth />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Button onClick={() => setOpen(true)} size={'small'} variant={'contained'} color={'primary'}>Adicionar instituição</Button>
        </div>
    )
};

export default UserEditAddInstitution;
