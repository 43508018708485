import { Typography } from "@material-ui/core";
import { Code, CodeBlock, CopyBlock, dracula } from "react-code-blocks";

export const RegraInativar = () => {

    const text = `{
    "usuarios": Array [
        {
            "nome": string,
            "email": string,
            "fone": string, (formato (99) 99999-9999)
        },
    ]
}`;

    const text2 = `POST /v1/inativar
Host: api.app.juristta.com
Content-Type: application/json
Authorization: Bearer v1_a2f5i10a_abc123def456ghi789jk
{       
    "usuarios": [
        {
            "nome": "João Silva",
            "email": "joao.silva@example.com",
            "fone": "(31) 99999-9999",
        },
        {
            "nome": "Maria Oliveira",
            "email": "maria.oliveira@example.com",
        }
    ]
}`;

    const text3 = `{
    [
        {
            "id": "c40aa97c-8f88-afe7-409a-3465045e44b4",
            "name": "João Silva",
            "email": "joao.silva@example.com",
            "phone": "(31) 99999-9999"
            "active": false
        },
        {
            "id": "91d9683c-aadc-4a1f-bac6-0dcecbd40202",
            "nome": "Maria Oliveira",
            "email": "maria.oliveira@example.com",
            "phone": null,
            "active": false
        }
    ]
}`;

    return <>
        <Typography variant='h6'>Inativação de alunos</Typography>
        <div style={{ marginBottom: '16px' }} />

        <Typography variant='body1'>Este endpoint permite a inativação de alunos no sistema. Alunos inativados perdem o acesso ao sistema. </Typography>
        <Typography variant='body1'>Alunos inativados não são excluídos e seus dados continuam no sistema para consulta. </Typography>

        <div style={{ marginBlock: '16px', borderRadius: '8px', backgroundColor: '#1f1f1f', padding: '8px' }}>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>Método:</Typography> <Typography component='span'>POST</Typography>
            </div>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>URL:</Typography> <Typography component='span'>https://api.app.juristta.com/v1/inativar </Typography>
            </div>
        </div>
        <Typography variant="h6">Parâmetros do corpo da requisição </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>
        <div style={{ marginBottom: '16px' }} />

        <Typography variant="h6">Exemplo de requisição </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text2} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>

        <div style={{ marginBottom: '16px' }} />

        <Typography variant="h6">Exemplo de resposta </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CodeBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text3} language="json" theme={dracula} />
        </Typography>
        <Typography variant="body1">Requisições que não seguirem corretamente a especificação receberão uma resposta com o status <Code language='http' text="HTTP 400 Bad Request." theme={dracula} ></Code> com detalhes do problema. </Typography>


    </>

}