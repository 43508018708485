const currentUserKey = '____adaiuhr1337984u0198ajdnNDOSAJD______'

const { localStorage } = window;

export const storageProvider = {

    //Current User
    setCurrentUser: (institution) => {
        localStorage.setItem(currentUserKey, btoa(JSON.stringify(institution)));
    },
    getCurrentUser: () => {
        const content = localStorage.getItem(currentUserKey);
        try {
            return JSON.parse(atob(content));
        } catch (e) {
            return null;
        }
    },
    removeCurrentUser: () => {
        localStorage.removeItem(currentUserKey);
    },

}