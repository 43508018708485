import React from 'react';
import { Route } from "react-router-dom";
import SchoolStudentDashboard from '../components/dashboards/school-student-dashboard';
import SchoolTeacherDashboard from '../components/dashboards/school-teacher-dashboard';
import StudentDashboard from '../components/dashboards/student-dashboard';
import TeacherDashboard from '../components/dashboards/teacher-dashboard';
import UserPage from '../components/user-page';
import SchoolActivityDashboard from '../pages/activity-dashboard';
import CreateActivityTeams from '../pages/create-activity-teams';
import { ExternalProcessMailPage } from '../pages/external-process-mail';
import { ExternalServiceRequestPage } from '../pages/external-service-request';
import { InstitutionStatsPage } from '../pages/institution-stats';
import { JuristtaChatGPTPage } from '../pages/juristta-chat-gpt';
import { LtiLogin } from '../pages/lti-login';
import ManageActivityTeams from '../pages/manage-activity-teams';
import SchoolModelActivityDashboard from '../pages/model-activity-dashboard';
import { NotificationsPage } from '../pages/notifications/all-notifications-page';
import { PasswordReset } from '../pages/password-reset';
import ProcessDashboard from '../pages/process-dashboard';
import { ProcessMail } from '../pages/process-mail';
import { Reports } from '../pages/reports';
import SearchResults from '../pages/search-results';
import StudentActivityNotes from '../pages/student-activity-notes';
import StudentProcessNotes from '../pages/student-process-notes';
import TelesapiensContent from '../pages/telesapiens-content';
import VadeMecum from '../pages/vade-mecum/vade-mecum-component';
import Videoconference from '../pages/videoconference';
import { SchoolManagerTutorialPage } from '../pages/tutorial/school-manager-tutorial-page';
import { SchoolStudentTutorialPage } from '../pages/tutorial/school-student-tutorial-page';
import { OfficeManagerTutorialPage } from '../pages/tutorial/office-manager-tutorial-page';
import { OfficeStudentTutorialPage } from '../pages/tutorial/office-student-tutorial-page';
import ApiDocsPage from '../pages/api-docs';

const routes = [
    <Route exact path={'/process-dashboard/:id'} key={'process-dashboard'} component={ProcessDashboard} />,
    <Route exact path={'/video/:id'} key={'videoconference'} component={Videoconference} />,
    <Route exact path={'/esr/:slug/:uuid'} key={'external-service-request'} component={ExternalServiceRequestPage} noLayout />,
    <Route exact path={'/student-process-notes/:id'} key={'student-process-notes'} component={StudentProcessNotes} />,
    <Route exact path={'/student-activity-notes/:id'} key={'student-activity-notes'} component={StudentActivityNotes} />,
    <Route exact path={'/me'} key={'me'} component={UserPage} />,
    <Route exact path={'/teacher-dashboard'} key={'teacher-dashboard'} component={TeacherDashboard} />,
    <Route exact path={'/student-dashboard'} key={'student-dashboard'} component={StudentDashboard} />,
    <Route exact path={'/school-teacher-dashboard'} key={'school-teacher-dashboard'} component={SchoolTeacherDashboard} />,
    <Route exact path={'/school-student-dashboard'} key={'school-student-dashboard'} component={SchoolStudentDashboard} />,
    <Route exact path={'/search'} key={'search'} component={SearchResults} />,
    <Route exact path={'/reports'} key={'reports'} component={Reports} />,
    <Route exact path={'/password-reset'} key={'password-reset'} component={PasswordReset} noLayout />,
    <Route exact path={'/password-reset/:token'} key={'password-reset-token'} component={PasswordReset} noLayout />,
    <Route exact path={'/notifications'} key={'notifications'} component={NotificationsPage} />,
    <Route exact path={'/process-mail/:id'} key={'process-mail'} component={ProcessMail} />,
    <Route exact path={'/external-process-mail'} key={'external-process-mail'} component={ExternalProcessMailPage} noLayout />,
    <Route exact path={'/chat-gpt'} key={'chat-gpt'} component={JuristtaChatGPTPage} />,
    <Route exact path={'/lti'} key={'lti'} component={LtiLogin} noLayout />,
    <Route exact path={'/telesapiens-content'} key={'telesapiens-content'} component={TelesapiensContent} />,
    <Route exact path={'/model-activity-dashboard/:id'} key={'model-activity-dashboard'} component={SchoolModelActivityDashboard} />,
    <Route exact path={'/school-activity-dashboard/:id'} key={'school-activity-dashboard'} component={SchoolActivityDashboard} />,
    <Route exact path={'/stats'} key={'stats'} component={InstitutionStatsPage} />,
    <Route exact path={'/manage-activity-teams/:id'} component={ManageActivityTeams} />,
    <Route exact path={'/manage-activity-teams/:id/create'} component={CreateActivityTeams} />,
    <Route exact path={'/vade-mecum'} component={VadeMecum} />,
    <Route exact path={'/school-manager-tutorial'} key={'school-manager-tutorial'} component={SchoolManagerTutorialPage} />,
    <Route exact path={'/school-student-tutorial'} key={'school-student-tutorial'} component={SchoolStudentTutorialPage} />,
    <Route exact path={'/office-manager-tutorial'} key={'office-manager-tutorial'} component={OfficeManagerTutorialPage} />,
    <Route exact path={'/office-student-tutorial'} key={'office-student-tutorial'} component={OfficeStudentTutorialPage} />,
    <Route exact path={'/api-docs'} key={'api-docs'} component={ApiDocsPage} />,

]

export default routes;
