import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
    ArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    DateTimeInput,
    FormDataConsumer,
    FormTab,
    ReferenceInput, SelectInput, SimpleFormIterator,
    TabbedForm, TextInput, Title, required, useNotify, useRedirect, useRefresh
} from "react-admin";
import { VideoUrlInput } from "../../components/video-url-input";
import { videoIdExtractorTransform } from "../../utils/video-id-extractor-transform";

const SchoolActivityTaskCreate = (props) => {

    const state = props.history.location.state ? props.history.location.state : { data: null, redirectTo: null }

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(`Movimentação criada com sucesso`);
        redirect(state.redirectTo !== null ? state.redirectTo : `/school_activities/${data.school_activity.id}/activities`);
        refresh();
    };

    return (
        <Create title={<Title title={'Nova Etapa Matriz'} />} onSuccess={onSuccess} transform={videoIdExtractorTransform} {...props}>
            <TabbedForm warnWhenUnsavedChanges>
                <FormTab label="Dados Principais">
                    <TextInput source="school_activity.id" initialValue={state.data.id} style={{ display: 'none' }} fullWidth disabled />
                    <TextInput source="school_activity.name" initialValue={state.data.name} label={'Atividade prática'} fullWidth disabled />
                    <TextInput source="name" label="Título" variant="filled" fullWidth />
                    <CheckboxGroupInput
                        source="roles"
                        label="Partes que podem ver esta etapa"
                        variant="filled"
                        choices={state.data.party_types.map(item => ({ ...item, id: item.id.toString() }))}
                        optionText="name"
                        optionValue={'id'}
                        fullWidth
                        parse={value =>
                            value && value.map(v => ({ id: v }))
                        }
                        format={value => value && value.map(v => v.id)}
                    />

                    <ReferenceInput source="guideline_type" reference="guideline-types" label="Orientação padrão" fullWidth>
                        <SelectInput source="name" optionText={"name"} fullWidth />
                    </ReferenceInput>
                    <BooleanInput source="requires_grade" label="Requer correção" defaultValue={true} />
                    <BooleanInput source="video" label="Audiência de vídeo" />

                    <FormDataConsumer>
                        {({ formData, record, ...rest }) => {
                            return formData.video && <>
                                <TextInput source="video_url" label="Url da audiência de vídeo" variant="filled" validate={[required()]} fullWidth />
                                <DateTimeInput source="video_date" label={'Data da audiência'} validate={[required()]} fullWidth />
                            </>
                        }}
                    </FormDataConsumer>

                    <BooleanInput source="metaverse" label="Audiência no metaverso" />
                    <FormDataConsumer>
                        {({ formData, record, ...rest }) => {
                            return formData.metaverse && <>
                                <DateTimeInput source="metaverse_video_date" label={'Data da audiência'} validate={[required()]} fullWidth />
                            </>
                        }}
                    </FormDataConsumer>
                    <BooleanInput source="requires_tpu" label="Requer código TPU" />
                    <ArrayInput
                        source="checklist"
                        label="Checklist de correção"
                        variant="filled"
                    >
                        <SimpleFormIterator>
                            <TextInput source="description" label="Descrição" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Instruções" path="details">
                    <RichTextInput
                        source="details"
                        label="Descrição"
                    />
                    <ArrayInput source="links" label="Vídeos" variant="standard">
                        <SimpleFormIterator>
                            <TextInput source="title" label="Título" fullWidth />
                            <VideoUrlInput source="url" label="URL" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}

export default SchoolActivityTaskCreate 