import {
    List
} from '@material-ui/core'
import 'moment/locale/pt-br'
import React from 'react'
import ExpandableCard from '../../components/expandable-card'
import { ListAttachments } from '../../components/list-attachments/list-attachments.component'

export const ProcessAttachments = ({ attachments, ...props }) => {

    const empty = attachments.process.length + attachments.serviceRequest?.length + attachments.mail.length < 1

    return (
        <ExpandableCard realce={true} title={`Documentos do atendimento`} mini={empty}>
            <List component="nav" dense={true}>
                <ListAttachments attachments={attachments.process} origin={'Atendimento'} />
                <ListAttachments attachments={attachments.serviceRequest} origin={'Pedido de atendimento'} />
                <ListAttachments attachments={attachments.mail} origin={'Correio do atendimento'} />
            </List>
        </ExpandableCard>
    )
}