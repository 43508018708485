import { List } from "@material-ui/icons";
import RichTextInput from 'ra-input-rich-text';
import React from "react";
import { Button, Create, FormTab, ReferenceInput, SelectInput, TabbedForm, useRedirect } from "react-admin";
import cookiesProvider from "../../providers/cookies-provider";

const ActivityNoteCreate = (props) => {
    const r = useRedirect()
    const { activity, redirectTo, origin } = props.history.location.state
    const user = cookiesProvider.getUser();
    return (
        <>
            <Button
                onClick={() => {
                    r(redirectTo, undefined, undefined, undefined, { origin })
                }}
                label={'Retornar'}
                style={{ alignSelf: 'flex-start' }}
                startIcon={<List />}
            />
            <Create {...props} onSuccess={() => { r(redirectTo, undefined, undefined, undefined, { origin }) }}>
                <TabbedForm initialValues={{ activity: activity, author: user }} >
                    <FormTab label={'Dados Gerais'}>
                        <ReferenceInput source="author" label={"Autor"} reference="users" fullWidth disabled>
                            <SelectInput optionText={"name"} />
                        </ReferenceInput>
                        <ReferenceInput source="activity" label={"Movimentação"} reference="activities" fullWidth disabled>
                            <SelectInput source="id" optionText={"type.name"} />
                        </ReferenceInput>
                        <RichTextInput source="text" label={'Conteúdo'} fullWidth />
                    </FormTab>
                </TabbedForm>
            </Create >
        </>
    )
}

export default ActivityNoteCreate;