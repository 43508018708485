import {
    IconButton, List,
    ListItem, ListItemSecondaryAction,
    ListItemText
} from '@material-ui/core'
import { Link as LinkIcon } from '@material-ui/icons'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import ExpandableCard from '../../components/expandable-card'

export const ProcessRecords = ({ process, attachments, isStudent, ...props }) => {

    return (
        <ExpandableCard realce={true} title={`Íntegra do processo`} mini={attachments === undefined || attachments?.records?.length < 1}>
            {
                attachments && <List>
                    {
                        attachments.records ? attachments.records.map((attachment, index) => {
                            return attachment && (
                                <ListItem key={'records'}>
                                    <ListItemText primary={attachment?.name} secondary={`Incluído em ${moment(attachment?.created_at).format('DD/MM/YYYY')}`} />
                                    <ListItemSecondaryAction>
                                        <IconButton href={attachment?.path} target={'_blank'}>
                                            <LinkIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        }) : <ListItemText primary={'Íntegra do processo não anexada'} />
                    }
                </List>
            }
        </ExpandableCard >
    )
}