import React from "react";
import { BooleanInput, Create, FormTab, SelectInput, TabbedForm, TextInput } from "react-admin";

export const ProcessTypeCreate = (props) => {

    const choices = [{ id: 'Judicial' }, { id: 'Administrativo' }, { id: 'Outros' }]

    return (
        <Create
            title={'Tipos de processo'}
            mutationMode="pessimistic"
            {...props}
        >
            <TabbedForm redirect={'list'}>
                <FormTab label={'Dados Gerais'}>
                    <SelectInput source="type" optionText={'id'} label={'Tipo'} choices={choices} fullWidth />
                    <TextInput source="name" label={'Complemento'} fullWidth />
                    <BooleanInput source='pje' label={'PJE?'} fullWidth />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}