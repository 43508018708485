import { Document, Page, Text } from "@react-pdf/renderer"
import React from "react"
import PDFTable from "../../components/react-pdf-table"

export const SchedulingClassReport = ({ data, type, styles, params }) => {

    const headers = [
        { id: 'name', text: 'Aluno', width: '30%' },
        { id: 'scheduling_memberships', text: 'Data/Hora', format: 'datetime', width: '15%', multiple: true, multipleSubfield: 'scheduling.date' },
        { id: 'scheduling_memberships', text: 'Cliente', large: true, multiple: true, multipleSubfield: 'scheduling.client.name' },
        { id: 'scheduling_memberships', text: 'Fone', width: '20%', multiple: true, multipleSubfield: 'scheduling.client.phone' },
    ]

    return (
        <Document>
            <Page orientation="landscape">
                <Text style={styles.title}>Relatório de agendamentos por turma</Text>
                <Text style={styles.subtitle}>{data.institution.name} - Emitido em {new Date().toLocaleString('pt-BR')}</Text>
                <Text style={styles.title}> Turma: {data.data.name} </Text>
                <PDFTable headers={headers} data={data.data.users} />
            </Page>
        </Document>
    )
}
