import RichTextInput from "ra-input-rich-text";
import React from "react";
import { ArrayInput, BooleanInput, Datagrid, Edit, FormDataConsumer, FormTab, ReferenceManyField, SimpleFormIterator, TabbedForm, TextField, TextInput } from "react-admin";
import AddNewAttachmentButton from "../../components/add-new-attachment-button";
import AttachmentDeleteButton from "../../components/attachment-delete-button";
import { VideoUrlInput } from "../../components/video-url-input";
import { ViewFileButton } from "../../components/view-file-button";
import { videoIdExtractorTransform } from "../../utils/video-id-extractor-transform";

const GuidelineTypeEdit = (props) => {

    const CustomTitle = ({ record }) => {
        return <span> {`Orientação padrão - ${record.name}`} </span>
    }

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            title={<CustomTitle />}
            transform={videoIdExtractorTransform}
        >
            <TabbedForm>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label={'Nome'} fullWidth />
                    <BooleanInput source="public" label={'Pública?'} fullWidth />
                    <RichTextInput source='details' label='Orientações' />
                </FormTab>
                <FormTab label={'Vídeos'} path="videos">
                    <ArrayInput source="links" label="Vídeos" variant="standard">
                        <SimpleFormIterator>
                            <TextInput source="title" label="Título" fullWidth />
                            <VideoUrlInput source="url" label="URL" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label={'Anexos'} path="attachments">
                    <ReferenceManyField fullWidth reference='attachments' target='guidelineTypeId' label={'Anexos'}>
                        <Datagrid>
                            <TextField source={'name'} label={'Nome'} />
                            <ViewFileButton />
                            <FormDataConsumer>
                                {({ formData, record }) =>
                                    <AttachmentDeleteButton path='guideline-types' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <AddNewAttachmentButton fileSize={25000000} multiple={true} />
                </FormTab>
            </TabbedForm>
        </Edit>

    )
}

export default GuidelineTypeEdit;