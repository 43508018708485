import { AssignmentInd } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import ProfileCreate from "./profile-create";
import ProfileEdit from "./profile-edit";
import ProfileList from "./profile-list";

export const profileResource = {
    name: ApplicationResource.PROFILES,
    icon: AssignmentInd,
    options: { label: "Perfis" },
    tableName: 'profile',
    list: ProfileList,
    edit: ProfileEdit,
    create: ProfileCreate,
    link: '/profiles',
    products: ['school', 'office']
};